<template>
  <div>
  <div class="page_container">
    <div class="header_container">
      <img src="images/Aetna/Taking-Care-of-You-Logo-214px.jpg">
      <img id="aetna_logo" src="images/Aetna/Aetna-Logo-102px.jpg">
    </div>
    <div class="main_container">
      Learn more about Medicare Advantage
      <div class="content_container">
        <div class="left_container" v-if="activeSection">
          <div class="subheader" v-if="accordion">
            {{activeSection.headers[0].minimizedText}}
          </div>
          <div class="video">
            <video-player :options="activeSection.videoOptions" :key="componentKey" 
              :mediaPropertyID="activeSection.mediaPropertyID"
              :video-description="activeSection.videoDescription" v-if="accordion"></video-player>
          </div>
          <div class="title_background">
            <div class="important_links">Important Links</div>
          </div>
          <div class="important_links_container">
            <div class="important_link_1">
              <div class="link_header">
                For DoD NAF HBP info:
              </div>
              <div class="link">
                <a href="http://nafhealthplans.com/retirees" @click="logClick('CLICK_DOD_NAF_LINK')" class="links">
                  nafhealthplans.com/retirees 
                  <i class="fas fa-external-link-alt"></i>
                </a>
              </div>
            </div>   
            <div class="important_link_2">
              <div class="link_header">
                Details on Aetna’s plans:
              </div>
              <div class="link">
                <a href="http://aetnaretireeplans.com" @click="logClick('CLICK_RETIREE_LINK')" class="links external_link">
                  aetnaretireeplans.com <i class="fas fa-external-link-alt"></i></a>
              </div>
            </div>      
          </div>
        </div>
        <div class="right_container">
          <div class="title_background">
            <div class="more_videos">More videos on<br />Medicare Advantage</div>
          </div>
          <div v-if="accordion" id="sectionsContainer">
            <template v-for="(section) in accordion.sections">
              <div v-if="activeSection != section" @click="changeVideo(section)" :key="section.id" class="more_links_container">
                <img :src='section.imageOptions.src' class="mouse_over side_nav_image">
                <div class="link mouse_over">
                  {{section.headers[0].expandedText}}
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="feedback_container">
        <Feedback :key="componentKey" :questions="feedbackQuestions" :ShowPagedQuestions="false"
        thankYouMessage="Thank you for your feedback!" :ShowCommentBox="true" :RequireSubmitButton="true" 
        :AlwaysShowImageText="true"></Feedback>
      </div>
    </div>
    <PageVisitLogger activityType="LANDING_PAGE" displayType="inline"></PageVisitLogger>
  </div>
  <div class="footer_container" >
    <Footer alignment="center" :show_background="false"></Footer>
  </div>
  </div>
</template>
<script>
import Footer from "../shared/Footer";
import PageVisitLogger from "../shared/PageVisitLogger";
import Feedback from '../shared/Feedback';
import VideoPlayer from '../VideoPlayer.vue';
import { data } from '../../shared';
import video1 from '../../assets/videos/Aetna/Jellyvision-Aetna-NAF-01.mp4';
import cc1 from '../../assets/videos/Aetna/Jellyvision-Aetna-NAF-01.vtt';
import video2 from '../../assets/videos/Aetna/Jellyvision-Aetna-NAF-02.mp4';
import cc2 from '../../assets/videos/Aetna/Jellyvision-Aetna-NAF-02.vtt';
import video3 from '../../assets/videos/Aetna/Jellyvision-Aetna-NAF-03.mp4';
import cc3 from '../../assets/videos/Aetna/Jellyvision-Aetna-NAF-03.vtt';
                
export default {
  components: { Footer, PageVisitLogger, VideoPlayer, Feedback },
  name: "aetnamapd",
  computed: {
   
  },
  data() {
    return {
      accordion: null,
      activeSection: null,
//      activeFeedbackSubject: null,
      clientCode: "AETNA_DOD",
      componentKey: 0,
      feedbackQuestions: [
        {
          question: "How helpful was this page for you?",
          sequence: 1,
          subject: "",
          icons: [
              {score: 1, class: 'far fa-grimace', text:'Missed the mark'},
              {score: 2, class: 'far fa-frown', text: 'Not helpful'},
              {score: 3, class: 'far fa-meh', text: "I'm not sure"},
              {score: 4, class: 'far fa-smile', text: 'Helpful'},
              {score: 5, class: 'far fa-grin-stars', text: 'Extremely helpful'}
          ]
        }
      ]
    };
  },
  async mounted() { 
    //const storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);
    this.accordion = await data.getAccordion('AETNA_MAPD', {});
    this.accordion.sections.sort((a,b) => a.sequence - b.sequence);
    this.accordion.sections.forEach(sec => {
      sec.imageOptions = sec.details.find(det => det.mediaProperty.mediaType === 'Image').mediaProperty.configuration;
      sec.videoOptions = sec.details.find(det => det.mediaProperty.mediaType === 'Video').mediaProperty.configuration;
        sec.mediaPropertyID = sec.details.find(det => det.mediaProperty.mediaType === 'Video').mediaPropertyID;
        sec.videoDescription = sec.details.find(det => det.mediaProperty.mediaType === 'Video').mediaProperty.description;
      switch(sec.sequence) {
        case 1:
          sec.videoOptions.sources[0].src = video1;
          sec.videoOptions.tracks[0].src = cc1;
          sec.feedback_subject = 'MAPD_IN_A_NUT_SHELL';
          break;
        case 2:
          sec.videoOptions.sources[0].src = video2;
          sec.videoOptions.tracks[0].src = cc2;
          sec.feedback_subject = 'MAPD_WHAT_WILL_HAPPN';
          break;
        case 3:
          sec.videoOptions.sources[0].src = video3;
          sec.videoOptions.tracks[0].src = cc3;
          sec.feedback_subject = 'MAPD_PERKS';
          break;
      }
      // sec.videoOptions.sources.forEach(source => {
      //   source.src = storageBaseUrl + source.src;
      // });
      // sec.videoOptions.tracks.forEach(track => {
      //   track.src = storageBaseUrl + track.src;
      // });
    });

    this.changeVideo(this.accordion.sections[0]);
  },
  methods: {
    changeVideo(newSection) {
      this.activeSection = newSection;
      this.feedbackQuestions[0].subject = newSection.feedback_subject;
      this.componentKey += 1;
    },
    logClick(activity_type_cd) {
      data.postActivityLog({
        logActivityTypeCode: activity_type_cd
      });
    },
    showSideNavHr(index) {
      // Hide hr element if this section is the last in the array, or if it is the second to last in the array and the section that is last is active
      if(index >= (this.accordion.sections.length - 1) || (index === (this.accordion.sections.length - 2) && this.accordion.sections.indexOf(this.activeSection) === (this.accordion.sections.length - 1)))
        return false;
      else
        return true;
    }
  }
};
</script>
<style scoped>
.fa-external-link:before{content:"\f08e"}
</style>
<style lang="scss" scoped src="@/assets/css/client/aetna/aetnamapd.scss"></style>
<style lang="scss" scoped src="@/assets/css/shared/feedback/non_modal_standard.scss"></style>
