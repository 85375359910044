<template>
  <div style="display: flex; flex-direction: column;">
    <div class="page_container">
      <div class="header_container">
        <img src="images/TJUH/Jefferson Corporate Enterprise 4C.png" style="width:300px">
      </div>
      <div class="main_container">
        <div class="video_container">
          <video-player :options="videoConfiguration" :key="1" v-if="videoConfiguration" 
          :mediaPropertyID="mediaPropertyID"
          :videoDescription="videoDescription"></video-player>
        </div>
      </div>
    </div>
    <div class="footer_container noprint" style="padding-top: 30px;" >
      <div class="footer">
          <div>© 2002-{{year}}</div><div class="desktop_only"> | </div><div>The Jellyvision Lab, Inc.</div><div class="desktop_only"> | </div><div>All rights reserved.</div><div class="desktop_only"> | </div><div><a href="https://www.jellyvision.com/privacy-policy/" target="_blank">Privacy Policy</a></div>
      </div>
    </div>
  </div>
</template>
<script>
import VideoPlayer from '../VideoPlayer.vue';
import { data } from '../../shared';

export default {
  components: { VideoPlayer },
  name: "Video",
  data() {
    return {
      videoConfiguration: null, 
      videoConfig2: null ,
      videoDescription: null,
      mediaPropertyID: null
    }
  },
  computed: {
    year() { 
      return new Date().getFullYear();
    }
  },
  async mounted() { 
    const storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);
    var video = await data.getMediaProperty(35);
    this.videoConfig2 = video.configuration;
    this.videoConfig2.sources.forEach(sc => {
      sc.src = storageBaseUrl + sc.src;
    });

    this.videoConfig2.tracks.forEach(sc => {
      sc.src = storageBaseUrl + sc.src;
    });

    this.videoDescription = video.description;
    this.mediaPropertyID = video.id;
    this.videoConfiguration = this.videoConfig2;
  }
};
</script>
<style scoped>
.main_container {
    color:var(--ink-color); 
    font-weight: 300;
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);
    font-family: var(--museo-slab-font-family);
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 50px 0px 100px 0px;
    height: auto;
    width: 100%;
}

.header_container {
    display:flex;
    flex-direction: row;
    padding-left: 13px;
    margin-top: 50px;
    margin-bottom: 5px;
    align-items:center;
}

.page_container {
    padding: 0px 20px;
}

.content_container { 
      flex-direction: column;
      flex-wrap: wrap;
  }

.footer_container {
    background-color: #EBEBEB; 
    font-family: var(--ey-primary-font-family);
    font-weight: 500;
    font-size: var(--ey-font-size-xs);
    line-height: 18px;
    padding: 0px 140px;
    color: var(--ey-gray-color);
    justify-content: space-between;
  }

    .footer {
      display:flex; 
      flex-direction: row;
      height: 80px;
      align-items: center;

    }

      .footer div {
        padding: 0 3px;
      }


  .footer_container a {
      text-decoration: none;
      color: var(--ey-gray-color);
      font-family: var(--ey-primary-font-family);
      font-weight: 500;
      font-size: var(--ey-font-size-xs);
      line-height: 18px;
  }

 @media (max-width: 480px) {
    .video_container {
      width: 240px;
      height: 127.5px
    }
  }
  @media (max-width: 768px) {
    .video_container {
      width: 384px;
      height: 216px
    }
  }

  @media (max-width:  1024px) {
    .video_container {
      width: 614.4px;
      height: 345.6px
    }
    }

  @media (max-width: 1280px) {
    .video_container {
      width: 819.2px;
      height: 460.8px;
    }
    }

  @media (min-width: 1281px) {
        .video_container {
      width: 1024px;
      height: 576px;
    }
    }
  @media only screen and (max-width: 962px) {
  :deep(.desktop_only) {
    display: none !important;
  }

  #

   .video-js1 {
        padding: 30px 0;
        object-fit: contain;
        max-width: 640px;
        max-height: 360px;
        width: auto;
        height: auto;
        margin: auto;
      }
}

#vjs_video_3, #videoContainer 
{ width: inherit !important; height: inherit !important;}
</style>