import { defineStore } from "pinia";

export const useLoaStore = defineStore ('loa', {
    state: () => ({
        // Contains key-value pairs of Attribute Code and Answer Value
        attributes: (localStorage.getItem('attributes') || '{}'),
        replacementStrings:(localStorage.getItem('replacementStrings') || '{}')
    }),
    getters: {
        getAttributes(state) {
            return JSON.parse(state.attributes);
        },
        getReplacementStrings(state) {
            return JSON.parse(state.replacementStrings);
        }

    },
    actions: {
        setAttributes(attributeData) {
            const copyOfAttributes = JSON.stringify({...attributeData});
            localStorage.setItem('attributes', copyOfAttributes);
            this.attributes = copyOfAttributes;
        },
        setReplacementStrings(data) {
            const copy = JSON.stringify({...data});
            localStorage.setItem('replacementStrings', copy);
            this.replacementStrings = copy;
        }
    }
})