import insuranceEngine from '@/components/UofC/default';

export const routes = [
  {
    path: '/retiree-graduated-eligibility-premium',
    component: insuranceEngine,
    meta: {
        title: 'University of California - Retiree Premiums',
    }
  },
];