<template>
    <div id="videoContainer">
        <video ref="videoPlayer" class="video-js vjs-big-play-centered vjs-big-play-button"  preload="auto" crossorigin="anonymous" disablePictureInPicture oncontextmenu="return false;" >
            <track v-for="track in options_local.tracks"
                :key="track.order"
                :kind="track.kind"
                :src="track.src"
                :srclang="track.srclang"
                :label="track.label"
                :default=track.default
            >
        </video>
    </div>
</template>

<script>
import videojs from '../../node_modules/video.js/dist/video.min.js';
import { data } from "../shared";

export default {
    name: "VideoPlayer",
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        },
        onVideoFinish: {
            type: Function
        },
        videoDescription: String,
        mediaPropertyID: Number
    },
    data() {
        return {
            player: null,
            started: false,
            options_local: {}
        }
    },
    async mounted() {
        this.options_local = this.options;
        this.options_local.controlBar = {
            playToggle: {
                replay: true
            },
            pictureInPictureToggle: false,
            durationDisplay: true,
            currentTimeDisplay: true,
            timeDivider: true,
            remainingTimeDisplay: false
        }
        this.loadVideo();
    },
    beforeUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    },
    methods: {
        loadVideo: async function() {
            if(!this.options_local.public) {
                if(this.options_local.poster) {
                    this.options_local.poster = await data.getMediaFile(this.options_local.poster.split('?')[0]);
                }

                for (let i = 0; i < this.options_local.sources.length; i++) {
                    this.options_local.sources[i].src = await data.getMediaFile(this.options_local.sources[i].src.split('?')[0]);
                }

                if(this.options_local.tracks)
                {
                    for (let i = 0; i < this.options_local.tracks.length; i++) {
                        this.options_local.tracks[i].src = await data.getMediaFile(this.options_local.tracks[i].src.split('?')[0]);
                    }
                }
            }

            if(this.player) {
                let currentTime = this.player.currentTime();
                this.player.src(this.options_local.sources);
                this.player.load();
                this.player.currentTime(currentTime);
                this.player.play();
                return;
            }
            this.player = videojs(this.$refs.videoPlayer, this.options_local, function onPlayerReady() {
                this.textTrackSettings.setValues({
                    backgroundOpacity: '0.5',
                    fontPercent: 1.25
                });
            });
            this.player.on('error', (event) => {
                if(event.target.player.error_.message === "A network error caused the media download to fail part-way.") {
                    this.loadVideo();
                }
            });
            this.player.on('loadedmetadata', () => {   
                if(this.options_local.loggingActivities)
                    this.addLoggingListeners();
                if(this.options_local.autoPlay) {
                    this.player.play();
            }
            });
            let vm = this;
            this.player.on('ended', () => {
                if(vm.onVideoFinish)
                    vm.onVideoFinish();
            })
        },
        findWithAttr: function(array, attr, value) {
            if(array) {
                for(var i = 0; i < array.length; i += 1) {
                    if(array[i][attr] === value) {
                        return i;
                    }
                }
            }
            return -1;
        },
        addLoggingListeners: function() {
            let videoStartLogIndex = this.findWithAttr(this.options_local.loggingActivities, 'percentage', 0);
            var logObject = {"description": this.videoDescription, "mediaPropertyID": this.mediaPropertyID};
            const logObject_string = JSON.stringify(logObject);
            if(videoStartLogIndex >= 0) {
                let videoStartLogCode = this.options_local.loggingActivities[videoStartLogIndex].logActivityTypeCode;
                this.player.on("play", function() {
                    if(!this.started) {
                        data.postActivityLog({
                            logActivityTypeCode: videoStartLogCode,
                            data: logObject_string
                        });
                    }
                    this.started = true;
                })
            }
            let videoEndLogIndex = this.findWithAttr(this.options_local.loggingActivities, 'percentage', 100);
            if(videoEndLogIndex >= 0) {
                let videoEndLogCode = this.options_local.loggingActivities[videoEndLogIndex].logActivityTypeCode;
                this.player.on("ended", function() {
                    data.postActivityLog({
                        logActivityTypeCode: videoEndLogCode,
                        data: logObject_string
                    });
                })
            }
            if(this.options_local.loggingActivities.length > 0) {
                for (let i = 0; i < this.options_local.loggingActivities.length; i++) {
                    this.options_local.loggingActivities[i].seconds = Math.floor(this.options_local.loggingActivities[i].percentage / 100 * this.player.duration());
                }
                this.player.on("timeupdate", function() {
                    for(let i = 0; i < this.options_.loggingActivities.length; i++) {
                        if(this.options_.loggingActivities[i].percentage != 0 && this.options_.loggingActivities[i].percentage != 100) {
                            if(this.player_.currentTime() >= this.options_.loggingActivities[i].seconds && !this.options_.loggingActivities[i].timeReached) {
                                data.postActivityLog({
                                    logActivityTypeCode: this.options_.loggingActivities[i].logActivityTypeCode,
                                    data: logObject_string
                                });
                                this.options_.loggingActivities[i].timeReached = true;
                            }
                        }
                    }
                });
            }
        }
    }
}
</script>
<style>


    .video-js .vjs-big-play-button {
        height: 90px !important;
        width: 90px !important;
        background-color: transparent !important;
        border-radius: 45px !important;
        margin-top: -1.5em !important;
    }

    .video-js .vjs-big-play-button .vjs-icon-placeholder {
        line-height: 85px;
        font-size: 60px;
    }
</style>