<template>
    <div>
        <div class="summary-page">
            <h1>Your Total Rewards Summary</h1>
            <p>Ta-da! This is the approximate value of your total rewards at IKEA. Here's a bird's eye view of the base pay, incentive pay, and benefits that make up your total rewards package at IKEA.</p>
            <p style="color:#0981b0;"><strong><i class="fa-regular fa-circle-exclamation"></i> Click on a tile to see the details or continue along with me and I’ll explain as we go.</strong></p>
            <div class="tiles-container">
                <div v-for="tile in tiles" :key="tile.title" class="tile" @click="onTileClick(tile.accordionCode, true)">
                    <div class="title" :style="getTileStyle(tile)"><span v-html="tile.title"></span></div>
                    <div class="body-text">
                        <h3>{{ tile.subtitle }}</h3>
                        <p>{{ tile.text }}</p>
                        <div class="arrow"><i class="fa-solid fa-arrow-right"></i></div>
                    </div>
                </div>
            </div>
            <p>For Tack! and One IKEA Bonus, keep in mind these are estimates and the final amount you may receive is subject to change. Please see the Tack! and One IKEA Bonus rules for more information about eligibility and how these are calculated.</p>
            <Navigation :onGoToPreviousPage="backToSurvey" :onGoToNextPage="goToNext" :showAudioPlayer="true" :audioTracks="audioTracks" :playTrackId="playTrackId" 
                        :showGoToNextPage="true" :showGoToPreviousPage="true"></Navigation>
        </div>
    </div>
</template>

<script>
import Navigation from '../shared/Navigation.vue';
import { audio } from '@/shared/audio';

export default {
    name: 'SummaryPage',
    components: { Navigation },
    props: {
        tiles: Array,
        onTileClick: Function,
        backToSurvey: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            audioMediaPropertyIds: [ 245, 246, 247  ],
            storageBaseUrl: '',
            audioTracks: [],
            playTrackId: ''
        }
    },
    mounted: async function() {
        var tracks = [];
        for(var i = 0; i < this.audioMediaPropertyIds.length; i++)
        {                
            tracks.push(await audio.getAudioTrack(this.audioMediaPropertyIds[i])); 
        }

        this.audioTracks = tracks;
        this.playTrackId = '245';
    },
    methods: {
        goToNext: function() {
            this.onTileClick(this.tiles.find(ti => ti.title === 'Your pay').accordionCode, false);
        },
        getTileStyle: function(tile) {
            var style = { 'background-color': tile.backgroundColor };
            if(tile.color) style["color"] = tile.color;

            return style;
        }
    }
}
</script>
