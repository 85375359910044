import Configurator from '@/components/MMA/Configurator';
import { authGuard } from '@auth0/auth0-vue';

export const routes = [
  {
    path: '/config',
    component: Configurator,
    alias: '/',
    meta: {
        title: 'MMA Video Configurator',
    },
    beforeEnter: authGuard
  }
];