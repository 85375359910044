<template>
   <div>
   <h3>{{ counter.counter }} </h3>
   <br />
   <button @click="increment">Increment</button>
    <br />
    <button @click="reset">Reset</button>
   </div>
</template>


<script>
import { defineComponent } from "vue";
import { useCounterStore } from "@/stores/counter";

export default defineComponent({
  name: "testPinia",
  components: {},
  data: () => ({
    val: 0
  }),
  methods: {
    increment: async function() {
        this.counter.counter++;
    },
    reset: async function() { 
        this.counter.counter = 0;
    },
  },
  setup() {
    const counter = useCounterStore();

    return {
      counter
    };
  }
});
</script>
