<template>
  <div>
  <div class="page_container">
    <div class="header_container">
      <a :href="logoNavigateUrl"><img src="/images/KForce/kforce-logo.png" style="width:200px"></a>
    </div>
    <div class="main_container">
      Learn more about your benefits and how to enroll.
      <div class="content_container">
        <div class="left_container" v-if="activeSection">
          <div class="subheader" v-if="accordion">
            {{activeSection.headers[0].minimizedText}}
          </div>
          <div class="video">
            <video-player :options="activeSection.videoOptions" :key="componentKey" 
              :mediaPropertyID="activeSection.mediaPropertyID"
              :video-description="activeSection.videoDescription" v-if="accordion"></video-player>
          </div>
          <div class="feedback_container">
            <Feedback :key="componentKey" :questions="feedbackQuestions" :ShowPagedQuestions="false"
            thankYouMessage="Thank you for your feedback!" :ShowCommentBox="true" :RequireSubmitButton="true" 
            :AlwaysShowImageText="true"></Feedback>
          </div>
        </div>
        <div class="right_container">
          <div class="title_background">
            <div class="more_videos">More Videos</div>
          </div>
          <div v-if="accordion && activeSection" class="sectionsContainerWrapper">
            <div  id="sectionsContainer">
              <template v-for="(section) in accordion.sections">
                <div v-if="activeSection != section" :key="section.id" class="more_links_container">
                  <a :href="section.imageUrl" class="links more_links_anchor">
                    <img :src='section.videoOptions.poster' class="mouse_over side_nav_image">
                    <div class="link mouse_over">
                      {{section.headers[0].expandedText}}
                    </div>
                  </a>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PageVisitLogger activityType="LANDING_PAGE" displayType="inline"></PageVisitLogger>
  </div>
  <div class="footer_container" >
    <Footer alignment="center" :show_background="false"></Footer>
  </div>
  </div>
</template>
<script>
import Footer from "../shared/Footer";
import PageVisitLogger from "../shared/PageVisitLogger";
import Feedback from '../shared/Feedback';
import VideoPlayer from '../VideoPlayer.vue';
import jsonLogic from 'json-logic-js';
import { data } from '../../shared';

export default {
  components: { Footer, PageVisitLogger, VideoPlayer, Feedback },
  name: "aetnamapd",
  computed: {
   
  },
  data() {
    return {
      accordion: null,
      activeSection: null,
      clientCode: "KFORCE",
      componentKey: 0,
      logoNavigateUrl: '',
      feedbackQuestions: [
        {
          question: "How helpful was this page for you?",
          sequence: 1,
          subject: "",
          icons: [
              {score: 1, class: 'far fa-grimace', text:'Missed the mark'},
              {score: 2, class: 'far fa-frown', text: 'Not helpful'},
              {score: 3, class: 'far fa-meh', text: "I'm not sure"},
              {score: 4, class: 'far fa-smile', text: 'Helpful'},
              {score: 5, class: 'far fa-grin-stars', text: 'Extremely helpful'}
          ]
        }
      ],
      parameters: {"VIDEO":"hsa-basics"}
    };
  },
  async mounted() {
    if(this.$route.params == null)
      this.$router.push({
        path: '/videos/medical',
        query: this.$route.query
      }).then(() => { this.$router.go() });

    const storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);
    this.accordion = await data.getAccordion('KFORCE_VIDEOS', null);
    var lastIndex = this.$route.path.lastIndexOf('/');
    var newPath = this.$route.path.substring(0, lastIndex + 1) + '%1%'; 
    var url = location.href.replace(this.$route.path, newPath);
    var loadingMedical = false;
    this.parameters["VIDEO"] = this.$route.params["videoName"];
    this.logoNavigateUrl =  url.replace('%1%','medical');

    this.accordion.sections.sort((a,b) => a.sequence - b.sequence);
    this.accordion.sections.forEach(sec => {
      sec.videoOptions = sec.details.find(det => det.mediaProperty.mediaType === 'Video').mediaProperty.configuration;
      sec.mediaPropertyID = sec.details.find(det => det.mediaProperty.mediaType === 'Video').mediaPropertyID;
      sec.videoDescription = sec.details.find(det => det.mediaProperty.mediaType === 'Video').mediaProperty.description;
      switch(this.parameters["VIDEO"]) {
        case 'hsa-basics':
          sec.feedback_subject = 'KFORCE_HSA_BASICS';
          break;
        case 'hsa-fsa':
          sec.feedback_subject = 'KFORCE_HSA_FSA';
          break;
        case 'hsa-pay':
          sec.feedback_subject = 'KFORCE_HSA_PAY';
          break;
        case 'mental-health':
          sec.feedback_subject = 'KFORCE_MENTAL_HEALTH';
          break;
        case 'nurseline':
          sec.feedback_subject = 'KFORCE_NURSELINE';
          break;
        case 'medical': 
          sec.feedback_subject = 'KFORCE_MED_PLANS';
          loadingMedical = true;
          break;
        case 'enrollment': 
          sec.feedback_subject = 'KFORCE_HOW_TO_ENROLL';
          break;
        case 'voluntary': 
          sec.feedback_subject = 'KFORCE_VOL_BENEFITS';
          break;
        case 'preventive-care':
          sec.feedback_subject = 'KFORCE_PREVENTIVE';
          break;
        case 'save-with-hsa':
          sec.feedback_subject = 'KFORCE_SAVE_WITH_HSA';
          break;
        case 'telemedicine':
          sec.feedback_subject = 'KFORCE_TELEMEDICINE';
          break;
      }

      sec.videoOptions.sources.forEach(source => {
        source.src = storageBaseUrl + source.src;
      });

      sec.videoOptions.poster = storageBaseUrl + sec.videoOptions.poster;
      if(sec.conditionalJson) {
        sec.imageUrl =  url.replace('%1%', JSON.parse(sec.conditionalJson).and[0]["=="][1]);

        if(jsonLogic.apply(JSON.parse(sec.conditionalJson), this.parameters)) {
          this.activeSection = sec;
          this.feedbackQuestions[0].subject = this.activeSection.feedback_subject;
        }
      }

      sec.videoOptions.tracks.forEach(track => {
         track.src = storageBaseUrl + track.src;
      });
    });

    if(this.activeSection == null)
      this.$router.push({
        path: loadingMedical === false ? '/videos/medical' : '/videos/hsa-basics',
        query: this.$route.query
      }).then(() => { this.$router.go() });
  },
  methods: {
    logClick(activity_type_cd) {
      data.postActivityLog({
        logActivityTypeCode: activity_type_cd
      });
    },
    showSideNavHr(index) {
      // Hide hr element if this section is the last in the array, or if it is the second to last in the array and the section that is last is active
      if(index >= (this.accordion.sections.length - 1) || (index === (this.accordion.sections.length - 2) && this.accordion.sections.indexOf(this.activeSection) === (this.accordion.sections.length - 1)))
        return false;
      else
        return true;
    }
  }
};
</script>
<style scoped>
.fa-external-link:before{content:"\f08e"}
</style>
<style lang="scss" scoped src="@/assets/css/client/kforce/style.scss"></style>
<style lang="scss" scoped src="@/assets/css/shared/feedback/non_modal_standard.scss"></style>
