<template >
    <div class="feedbackModalContainer noprint" >
        <div class="feedbackOpenModalContainer" v-on:click="openModal">
            <span class="feedbackOpenButtonText" v-html="openButtonText"></span>
            <span class="feedbackOpenButtonPlus">+</span>
        </div>
        <div v-if="modalActive" class="feedbackModalBackdrop" v-on:click="closeModal"></div>
        <transition name="feedbackModalTransition">
            <div class="feedbackModal" v-if="modalActive">
                <div class="feedbackModalClose" v-on:click="closeModal">-</div>
                <div class="feedbackModalContent">
                    <Feedback :key="componentKey" :subject="subject" :questions="questions" :IsModal="true"
                                :thankYouMessage="thankYouMessage" :ShowCommentBox="true" :RequireSubmitButton="true" 
                                :icons="icons" :AlwaysShowImageText="false" @resetControl="closeModal" 
                                :CommentsQuestionText="CommentsQuestionText" 
                                :submitFeedbackButtonText="submitFeedbackButtonText" :previousButtonText="previousButtonText" ></Feedback>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Feedback from "./Feedback";

export default {
    components: { Feedback},
    name: "FeedbackModal",
    props: {
        questions: Array,
        question: String,
        subject: String,
        submitFeedbackButtonText: String,
        thankYouMessage: String,
        openButtonText: {
            type: String,
            default: "Feedback"
        },
        CommentsQuestionText: {
            type: String
        },
        previousButtonText: String,
        icons: {
            type: Array,
            default: function() {
                return [
                        {score: 1, class: 'fas fa-grimace fa-2x', text:'Totally missed the mark', show: false},
                        {score: 2, class: 'fas fa-frown fa-2x', text: 'Not helpful', show: false},
                        {score: 3, class: 'fas fa-meh fa-2x', text: "I'm not sure", show: false},
                        {score: 4, class: 'fas fa-smile fa-2x', text: 'Very helpful', show: false},
                        {score: 5, class: 'fas fa-grin-stars fa-2x', text: 'Extremely helpful', show: false}
                    ];
            }
        }
    },
    data() {
        return {
            modalActive: false,
            componentKey: 0
        };
    },
    methods: {
        openModal: function() {
            this.modalActive = true;
        },
        closeModal: function() {
            this.modalActive = false;
        }
    },
}
</script>
