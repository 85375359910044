<template>
    <span class="tooltip-container">
        <span class="tooltip-slot-container" @mouseover="hover = true" @mouseleave="hover = false">
            <slot />
            <i class="fas fa-question-circle tooltip-icon"></i>
        </span>
        <div class="tooltip-content" :class="{hidden: !hover}" v-html="text"></div>
    </span>
</template>
<script>
export default {
  props: { 
    text: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      hover: false
    };
  },
};
</script>
<style scoped>
  .tooltip-container { 
    position: relative;
    display: inline-block;
  }

  .tooltip-slot-container {
      display:flex;
      flex-direction: row;
  }

  .tooltip-icon {
      padding-top: .2em;
      padding-left: .2em;
      font-size: 1em;
      cursor: pointer;
  }

  .tooltip-content {
      position: absolute;
      z-index: 100;
      bottom: 2.4em;
      left: -5em;
      text-transform: none;
      opacity: 1;
  }

  .tooltip-content.hidden {
      display: block;
      pointer-events: none;
      opacity: 0;
  }
</style>
