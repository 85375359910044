import { defineStore } from "pinia";

export const useCache = defineStore ({
    id: 'cache',
    state: () => ({
        cache: {}
    }),
    actions: {
        setCache(obj) {
            this.cache = obj;
        }
    },
    getters: {
        getCache(state) {
            return state.cache;
        }
    }
});