`<template>
    <div>
        <div class="vsl-input-container">
            <input :type="question.displayParameters.allowNumbersOnly ? 'number' : 'text'" 
                v-model="inputValue" @blur="valueChanged" :placeholder="placeholder" class="vsl-input" />
        </div>
        <div class="vsl-dropdown-container" v-if="question.displayParameters.labelDisplayType === 'Dropdown'" >
            <select v-model="selectValue" @change="valueChanged" class="vsl-dropdown">
                <option v-for="ans in visibleAnswers" :key="ans.id" :value="ans.answerValue">{{ getTextForLanguage(ans.answerTextJson) }}</option>
            </select>
        </div>
        <div class="vsl-radiobutton-list-container" v-if="question.displayParameters.labelDisplayType === 'Checkbox_Radiobutton'">
            <div  v-for="(ans, index) in visibleAnswers" :key="ans.id" class="vsl-radiobutton-container" >  
                <input type="radio" v-model="selectValue" name="answerRadioButton" :id="'answerRadioButton' + index" 
                        :value="ans.answerValue" class="vsl-radio-button" @change="valueChanged">
                <label :for="'answerRadioButton' + index" class="vsl-radiobutton-label">{{ getTextForLanguage(ans.answerTextJson) }}</label>
            </div>
        </div>
    </div>
</template>

<script>
import { translation } from '@/shared/translation';
export default {
    name: 'ValueSelectLabel',
    props: {
        question: {
            type: Object
        },
        onValueChanged: {
            type: Function
        },
        initialValue: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        language: {
            type: String,
            default: "English"
        }
    },
    data() {
        return {
            inputValue: '',
            selectValue: ''
        }
    },
    computed :{
        visibleAnswers() {
            if(this.question.answers == null) return [];
            
            return this.question.answers.filter(search => search.visible);            
        }
    },
    mounted() {
        if(this.initialValue)
            this.inputValue = this.initialValue;
    },
    methods: {
        valueChanged: async function() {
            if(this.inputValue !== '' && this.selectValue !== '') {
                if(this.onValueChanged) {
                    this.onValueChanged(this.question.attribute.code, this.inputValue, this.selectValue);
                }
            }
        },
        getTextForLanguage: function(jsonField) {
            return translation.getTextForLanguage(jsonField, this.language);
        }
    }
}
</script>
`