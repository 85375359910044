<template>
    <div v-if="section && section.headers">
        <div class="header" :key="header.id" v-for="header in section.headers" v-html="header.expandedText"></div>
    </div>
    <div v-if="section" class="section">
        <div v-for="column in columns" class="column" :class="[layoutCssClass, 'column-' + column]" :key="column">
            <AccordionDetail v-for="detail in getColumnDetails(column)" :key="detail.id" :detail="detail" 
                :onUpdateAudio="updateAudio" :replacements="replacements"></AccordionDetail>
        </div>
    </div>
</template>

<script>
import AccordionDetail from '@/components/Ikea/AccordionDetail.vue';

export default {
    name: 'AccordionSection',
    components: { AccordionDetail }, 
    props: {
        section: Object,
        replacements: Object,
        onUpdateAudio: Function
    },
    computed: {
        columnCount: function() {
            switch(this.section.layout) {
                case 'SingleColumn': return 1;
                case 'TwoColumn': return 2;
                case 'ThreeColumn': return 3;
                default: return 0;
            }
        },
        columns: function() {
            var retColumns = [];
            for(var i = 1; i <= this.columnCount; i++) {
                retColumns.push(i);
            }

            return retColumns;
        },
        layoutCssClass: function() {
            switch(this.section.layout) {
                case 'SingleColumn': return 'one-column-layout';
                case 'TwoColumn': return 'two-column-layout';
                case 'ThreeColumn': return 'three-column-layout';
                default: return '';
            }               
        }
    },
    methods: {
        updateAudio: function(newTrackInfo) {
            if(this.onUpdateAudio)
                this.onUpdateAudio(newTrackInfo);
        },
        getColumnDetails: function(column) {
            var modCompare = column === this.columnCount ? 0 : column;
            var details = this.section.details.filter(det => (det.sequence % this.columnCount) === modCompare);

            details.sort((a, b) => {
                return a.sequence - b.sequence;
            });
            
            return details;
        }
    }
}
</script>