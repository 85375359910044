<template>
    <div class="surveyTopBar">
        <div class="sectionTitle">
            <h5>{{sectionTitle}}</h5>
        </div>
        <div class="navigationContainer">
            <Breadcrumb v-if="areBreadcrumbsEnabled" :pages="breadcrumbPages"
                :activePageNumber="activeBreadcrumbPageNumber"
                separator=">"
                :showFuturePages="true"
                :onBreadcrumbClick="breadcrumbPageClick"
                :key="breadcrumbKey">
            </Breadcrumb>
            <div class="backButton" @click="goBackToPreviousPage()" v-if="activePage && (activePageIndex > 0 || onGoBack) && buttonLocation !== 'bottom'">
                <i class="fas fa-chevron-left"></i> {{goBackButtonText}}
            </div>
        </div>
    </div>
    <div class="surveyContainer" v-if="survey">
        <div class="page" :class="'page' + activePageIndex" v-if="activePageIndex >= 0">
            <div v-html="activePage.headerText" class="surveyPageTitleInfo"></div>
            <div class="question" v-for="(question, index) in visibleQuestions" :key="question.id" >
                <div class="questionText">
                    <span v-if="!hasTooltip(question)" v-html="question.questionInfo.questionText"></span>
                    <span v-if="hasTooltip(question)" v-html="question.preText"></span>
                    <ToolTip v-if="hasTooltip(question)" :text="question.questionInfo.mediaPropertyInfo.configuration.text">{{question.middleText}}</ToolTip>
                    <span v-if="hasTooltip(question)" v-html="question.postText"></span>
                </div>
                <div :class="[questionRowClass, question.questionInfo.questionType + '-' + question.questionInfo.answerType]" style="display:flex; flex-direction: row">
                    <div class="questionColumn">
                        <div v-if="question.questionInfo.questionType === 'Text'">
                            <input v-if="question.questionInfo.answerType === 'Numeric'" type="number"
                                    v-bind="numericInputAttributes(question.questionInfo.displayParameters)"
                                    oninput="validity.valid||(value=Math.abs(value));" v-model="surveyAnswers[question.questionInfo.attribute.code]"
                                    @blur="setAnswer(question.questionInfo.attribute.code)" :placeholder="question.questionInfo.displayParameters.placeholder"/>
                            <input v-if="question.questionInfo.answerType === 'Text'" type="text" v-model="surveyAnswers[question.questionInfo.attribute.code]" @blur="setAnswer(question.questionInfo.attribute.code)" :placeholder="question.questionInfo.displayParameters.placeholder"/>
                        </div>
                        <div v-if="question.questionInfo.questionType === 'Date'"><input type="date" v-model="surveyAnswers[question.questionInfo.attribute.code]" @blur="setAnswer(question.questionInfo.attribute.code)" /></div>
                        <div v-if="question.questionInfo.questionType === 'Time'"><input type="time" v-model="surveyAnswers[question.questionInfo.attribute.code]" @blue="setAnswer(question.questionInfo.attribute.code)"  /></div>
                        <div v-if="question.questionInfo.questionType === 'FileUpload'"><input type="file" /></div>
                        <div v-if="question.questionInfo.questionType === 'SingleSelect' || question.questionInfo.questionType === 'MultiSelect'">
                            <AutoComplete v-if="question.questionInfo.answerType ==='Dropdown'" :attributeCode="question.questionInfo.attribute.code"
                            displayField="answerTextJson" internalValueField="answerValue" searchField="answerTextJson" 
                            :data="getVisibleAnswers(question, 'ANSWER')" v-model:value="surveyAnswers[question.questionInfo.attribute.code]"
                            :onValueChanged="setAutoCompleteAnswer" :placeholder="question.questionInfo.displayParameters.placeholder" >
                            </AutoComplete>
                            <select v-if="question.questionInfo.answerType === 'Dropdown1'" @change="setAnswer(question.questionInfo.attribute.code)" v-model="surveyAnswers[question.questionInfo.attribute.code]">
                                <option></option>
                                <template v-if="answerListHasGroups(question)">
                                    <optgroup v-for="group in getVisibleAnswers(question, 'GROUPING')" :key="group.id" :label="group.answerText">
                                        <option v-for="ans in getVisibleAnswers(question, null, group.id)" :key="ans.id" :value="ans.answerValue">{{ans.answerText}}</option>
                                    </optgroup>
                                </template>
                                <template v-if="!answerListHasGroups(question)">
                                    <option v-for="ans in getVisibleAnswers(question)" :key="ans.id" :value="ans.answerValue">{{ans.answerText}}</option>
                                </template>
                            </select>
                            <div class="answerButtonListContainer" v-if="question.questionInfo.answerType === 'Button'"
                                    :class="[widthClass(question) !== 'Columns1' ? 'flex-row' : 'flex-column']">
                                <div class="answerButtonContainer"                  
                                    v-for="ans in getVisibleAnswers(question)" 
                                    :class="widthClass(question)"
                                    :key="ans.id" >
                                    <div v-if="ans.type === 'GROUPING'" class="answer-list-grouping" v-html="ans.answerText"></div>
                                    <div v-if="ans.type === 'ANSWER'" class="answerButton"  :class="[answerSelected(index, ans) ? 'selected' : '']" @click="selectAnswer(index, ans)" v-html="ans.answerText"></div>
                                </div>
                            </div>
                        </div>
                        <img v-if="question.questionInfo.questionType === 'MediaDisplay' && question.questionInfo.mediaPropertyInfo.mediaType === 'Image'" :style="getImageStyle(question.questionInfo.mediaPropertyInfo.configuration)" :src="question.questionInfo.mediaPropertyInfo.configuration.src" />
                        <div v-if="question.questionInfo.questionType === 'MediaDisplay' && question.questionInfo.mediaPropertyInfo.mediaType === 'Video'" :style="question.questionInfo.mediaPropertyInfo.configuration.style">
                            <VideoPlayer v-if="question.questionInfo.mediaPropertyInfo" :mediaPropertyID="question.questionInfo.mediaPropertyId"
                                        :options="question.questionInfo.mediaPropertyInfo.configuration" 
                                        :ref="'video' + question.id"   :key="question.id"
                                        :onVideoFinish="onVideoFinish"
                                        :videoDescription="question.questionInfo.mediaPropertyInfo.description"></VideoPlayer>
                        </div>
                        <div v-if="question.questionInfo.questionType === 'Combination' && question.questionInfo.answerType === 'TextboxAndButton'">
                            <TextboxButton :question="question.questionInfo" :onValueChanged="ComponentValueChanged" :key="question.id" :placeholder="question.questionInfo.displayParameters.placeholder"></TextboxButton>
                        </div>
                        <div v-if="question.questionInfo.questionType === 'Combination' && question.questionInfo.answerType === 'ValueSelectLabel'">
                            <ValueSelectLabel :question="question.questionInfo" :onValueChanged="ValueSelectLabelChanged" :key="question.id" :placeholder="question.questionInfo.displayParameters.placeholder"></ValueSelectLabel>
                        </div>
                    </div>
                    <div v-if="showButtonsWithQuestions && !showNextButtonInFooter" class="buttonColumn">
                        <div @click="submitPage" class="actionButton submitButton"
                            v-html="activePage.configuration.continueButtonConfig.text" 
                            v-if="checkToDisplayContinue(question, index)" >                        
                        </div>
                    </div>
                </div>
                <div class="moreInfo" v-if="question.questionInfo.moreInfo" v-html="question.questionInfo.moreInfo">
                </div>
            </div>
            <div class="actionButtonContainer">
                <button class="actionButton backButton" v-if="activePage.configuration.backButtonConfig.visible"
                    v-html="activePage.configuration.backButtonConfig.text" @click="goBackToPreviousPage()" ></button>
                <button class="actionButton submitButton" v-if="activePage.configuration.continueButtonConfig.visible && showButtonsWithQuestions === false && !showNextButtonInFooter" 
                    v-html="activePage.configuration.continueButtonConfig.text" @click="submitPage" :disabled="!continueButtonEnabled"></button>
                <button class="actionButton skipButton"  v-if="activePage.configuration.skipButtonConfig.visible && !continueButtonEnabled" 
                    v-html="activePage.configuration.skipButtonConfig.text" @click="submitPage"></button>
            </div>
        </div>
        <ProgressBar v-if="survey.configuration.progressBar.enable && activePageIndex >= 0" :totalPages="survey.pages.length" :currentPage="survey.pages[activePageIndex].pageNumber"
                        :textFormat="survey.configuration.progressBar.textFormat" :textLocation="survey.configuration.progressBar.textLocation"></ProgressBar>
    </div>
    <div v-if="buttonLocation === 'bottom'"> 
            <Navigation ref="navigation" :audioTracks="pageAudioEvents" :playTrackId="currentTrackId" 
             :showGoToPreviousPage="activePage && (activePageIndex > 0 || onGoBack)"
             :showGoToNextPage="showNextButtonInFooter"
             :onGoToNextPage="onEndOfSurvey"
             :onGoToPreviousPage="goBackToPreviousPage" :showAudioPlayer="true"></Navigation>
    </div>
    <SingleButtonModal :buttonText="popupModalButtonText" :displayText="popupModalContent" :onButtonClicked="onModalButtonClicked"></SingleButtonModal>
    <template v-if="showProgressIcon">
        <img src="/images/loading-spinner-fish.gif" style="width: 10%; max-width:100px; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto;" />
    </template>
</template>

<script>
import { data } from '@/shared/data';
import jsonLogic from 'json-logic-js';
import { useLoaStore } from "@/stores/loa";
import { evaluate } from 'mathjs';
import ToolTip from '@/components/shared/ToolTip';
import VideoPlayer from '@/components/VideoPlayer.vue';
import  TextboxButton  from "@/components/shared/Questions/TextboxButton";
import ValueSelectLabel from '@/components/shared/Questions/ValueSelectLabel';
import AutoComplete from "@/components/shared/AutoComplete";
import Breadcrumb from '@/components/shared/Breadcrumb';
import Navigation from '@/components/shared/Navigation';
import SingleButtonModal from '@/components/shared/Survey/SingleButtonModal';
import { audio } from '@/shared/audio';
import ProgressBar from '@/components/shared/Survey/ProgressBar';

require('../../assets/css/shared/tooltip/tooltip_variables_standard.scss');

export default {
    name: "Survey",
    components: { ToolTip, VideoPlayer, TextboxButton, AutoComplete, Breadcrumb, ValueSelectLabel, Navigation, SingleButtonModal, ProgressBar },
    props: {
        buttonLocation: {
            type: String,
            default: 'top'
        },
        code: String,
        sectionTitle: String,
        onSurveyLoad: Function,
        onPageDisplay: Function,
        onEndOfSurvey: {
            type: Function
        } ,
        goBackButtonText: String,
        onGoBack: {
            type: Function
        },
        onQuestionAnswered: {
            type: Function
        },
        onValidateAnswer: {
            type: Function
        },
        allowSkip: {
            type: Boolean,
            default: false
        },
        languageCode: {
            type: String,
            default: 'en'
        },
        definedBreadCrumbs: {
            type: Array,
        },
        onBreadcrumbClick: {
            type: Function
        },
        showNextButtonInFooter: {
            type: Boolean,
            default: false
        },
        gotoPage: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            survey: null,
            activePageIndex: -1,
            surveyAnswers: {},
            storageBaseUrl: '',
            questionsAnswered: [],
            isDebug: false,
            continueButtonEnabled: true,
            activeBreadcrumbPageNumber: 1,
            areBreadcrumbsEnabled: false,
            breadcrumbKey: 0,
            breadcrumbPages: [],
            audioTracks: [],
            popupModalConfiguration: null,
            currentTrackId: '',
            showProgressIcon: false
        }
    },
    computed: {
        popupModalButtonText() {
            if(this.popupModalConfiguration != null)
                return this.popupModalConfiguration.buttonText;
            else 
                return '';
        },
        popupModalContent() {
            if(this.popupModalConfiguration != null)
                return this.popupModalConfiguration.windowText;
            else 
                return '';
        },
        showButtonsWithQuestions() {
            return this.survey.configuration.submitButtonLocation === 'WithQuestions';
        },
        questionRowClass() {
            return this.showButtonsWithQuestions ? "twoColumns" : "oneColumn";
        },
        activePage() {    
            if(this.activePageIndex >= 0 && this.survey.pages && this.activePageIndex < this.survey.pages.length)
                return this.survey.pages[this.activePageIndex];
            else 
                return null;
        },
        visibleQuestions() {
            if(this.activePage == null || this.activePage.questions == null) return [];
            return this.activePage.questions.filter(search => search.visible);
        },
        allowBreadcrumb() {
            if(this.survey.configuration &&
                this.survey.configuration.breadcrumb &&
                this.survey.configuration.breadcrumb.enable)
                return true;
            else
                return false;
        },
        pageAudioEvents() {
            var attributes = this.piniaSurvey.getAttributes;
            if(this.activePage != null && this.audioTracks.length > 0) {
                var  actions =  this.survey.actions.filter((asset) => { 
                            return this.activePage.id === asset.configuration.surveyPageID && 
                                    this.isAudioAction(asset) &&
                                    jsonLogic.apply(JSON.parse(asset.conditionalJson), attributes)
                                }).sort((a,b) => a.sequence - b.sequence);

                var lst = [];
                actions.forEach((action) => {
                    var foundInfo = this.audioTracks.find(search => search.id === action.configuration.mediaPropertyID.toString());
                    if(foundInfo) {
                        lst.push(foundInfo);
                    }
                });

                return lst;
            }   

            return [];
        },
        calculationActions() {
            if(this.survey && this.survey.actions) 
                return this.survey.actions.filter(act => {return act.type === 'SetVariable'});
            else 
                return [];
        }
    },
    watch: {
        pageAudioEvents(newArray, oldArray) {
            if(this.pageAudioEvents.length) {
                const newTrack = newArray.find(newTrack => !oldArray.find(oldTrack => oldTrack.id.toString() === newTrack.id.toString()));
                this.currentTrackId = newTrack ? newTrack.id.toString() : '';
            }
        }
    },
    setup() {
      const piniaSurvey = useLoaStore();
      return {
        piniaSurvey
      };
    },
    mounted: async function() {
        this.surveyAnswers = {};
        let uri = window.location.search.substring(1); 
        let params = new URLSearchParams(uri);
        this.isDebug = (params.get("debug"))

        await this.getSurvey();

        this.areBreadcrumbsEnabled = this.allowBreadcrumb && this.survey.configuration.breadcrumb.enable === true;

        for(let i = 0; i < this.survey.pages.length; i++) {
            for(let j = 0; j < this.survey.pages[i].questions.length; j++) {
                var question  = this.survey.pages[i].questions[j];
                if(question.questionInfo.questionType === 'MediaDisplay' && question.questionInfo.mediaPropertyInfo.mediaType === 'Image'){
                    var configuration = question.questionInfo.mediaPropertyInfo.configuration;
                    if(configuration.isLocalPath === false) {
                        var location = this.storageBaseUrl + (configuration.src[0] !== '/' ? '/' : '') + configuration.src; 
                        var ret = await data.getMediaFileProperties(location);
                        configuration.src = location + '?v=' + ret.LastModifiedTicks;
                    }   
                }
            }
        }

        if(!this.areBreadcrumbsEnabled) return;

        if(this.breadcrumbPages) 
            this.breadcrumbPages.pop();
        
        if(this.definedBreadCrumbs)
            this.breadcrumbPages = this.definedBreadCrumbs.slice();

        for(let i = 0; i < this.survey.pages.length; i++) {
            if(!this.survey.pages[i].configuration.breadcrumbConfiguration) continue;
            const bcpg = this.breadcrumbPages.find(bc => bc.number === this.survey.pages[i].configuration.breadcrumbConfiguration.number);
            if(!bcpg)
                this.breadcrumbPages.push(this.survey.pages[i].configuration.breadcrumbConfiguration);
        }

        this.activeBreadcrumbPageNumber = 2;
        this.breadcrumbKey++;
    },
    methods: {
        getImageStyle(configuration) {
            var style = {};
            if(configuration.height !== '') {
                if(configuration.height.indexOf("%") === -1 && configuration.height.indexOf("px") === -1)
                    style["height"] = configuration.height + "px";
                else 
                    style["height"] = configuration.height;
            }

            if(configuration.width !== '') {
                if(configuration.width.indexOf("%") === -1 && configuration.width.indexOf("px") === -1)
                    style["width"] = configuration.width + "px";
                else 
                    style["width"] = configuration.width;
            }

            return style;
        },
        onModalButtonClicked() {
            this.piniaSurvey.setAttributes(null);
            this.surveyAnswers = {};
            this.popupModalConfiguration = {};
            this.displayPage(0);
        },
        checkToDisplayContinue(question, index) {
            var show = false;
            switch(question.questionInfo.questionType) {
                case 'Text':
                case 'Date':
                case 'Time':
                case 'Combination':
                    show = true;
                    break;
                default: 
                    if(question.questionInfo.answerType == 'Dropdown')
                      show = true;
                    break;
            }

            return show && this.activePage.configuration.continueButtonConfig.visible 
                && this.continueButtonEnabled 
                && index === (this.visibleQuestions.length - 1);
        },
        isAudioAction(action) {
            return action.type === 'PlayAudio';
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        answerSelected(index, answer) {
            var attributes = this.piniaSurvey.getAttributes;
           
            if(!this.activePage) return false;
            var code = this.visibleQuestions[index].questionInfo.attribute.code;
            if(attributes[code]) {
                if(Array.isArray(attributes[code]) &&  attributes[code].indexOf(answer.answerValue) >= 0 )
                    return true;
                else if(!Array.isArray(attributes[code]) && attributes[code] == answer.answerValue) 
                    return true;
            }

            return false;
                            
        },
        widthClass(question) {
            if(question.questionInfo.displayParameters == null ||
                question.questionInfo.displayParameters.columns == null ||
                question.questionInfo.displayParameters.columns <= 1) return "Columns1";
            else
                return 'Columns' + question.questionInfo.displayParameters.columns;
        },
        hasTooltip(question) {
            if(question.questionInfo.mediaPropertyId && 
                question.questionInfo.mediaPropertyId > 0 &&
                question.questionInfo.mediaPropertyInfo != null && 
                question.questionInfo.mediaPropertyInfo.mediaType == 'ToolTip')
                return true;
            else
                return false;
        },
        answerListHasGroups(question) {
           return question.questionInfo.answers.some(search => search.type === 'GROUPING'); 
        },
        getVisibleAnswers(question, groupsOrAnswers, answerGroupID) {
            if(question.questionInfo.answers == null) return [];
            
            if(groupsOrAnswers && groupsOrAnswers !== '')
                return question.questionInfo.answers.filter(search => search.visible && search.type === groupsOrAnswers);            
            else if(answerGroupID != null) {
                var beginIndex = question.questionInfo.answers.findIndex(search => search.id === answerGroupID);
                if(beginIndex === -1 || beginIndex === question.questionInfo.answers.length - 1)
                    return [];

                beginIndex++;
                var elementCount = question.questionInfo.answers.slice(beginIndex).findIndex(search => search.type === 'GROUPING');
               
                if(elementCount >= 0)
                    return question.questionInfo.answers.slice(beginIndex, beginIndex + elementCount);
                else 
                    return question.questionInfo.answers.slice(beginIndex);
            }
            else              
                return question.questionInfo.answers.filter(search => search.visible);            
        },
        getSurvey: async function() {
            this.storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);

            this.survey = await data.getSurveyByCode(this.code);
            this.survey.pages.sort((a, b) => {
                return a.pageNumber - b.pageNumber;
            });

            this.survey.actions.sort((a,b) => {
                return a.sequence - b.sequence;
            });

            //call async function say that everything else can continue while audio files are loaded.
            this.loadAudioTracks();

            if(this.isDebug)
                console.log(this.survey);            

            if(this.onSurveyLoad)
                await this.onSurveyLoad(this.survey);

            if(!this.gotoPage)
                await this.setActivePage(true);
            else
                await this.displayPage(this.gotoPage);
        },
        async loadAudioTracks() {
            if(this.survey.actions.length)
                this.audioTracks = await audio.loadAudioTracks(this.survey.actions);
        },
        async checkQuestions(index) {
            if(this.onQuestionAnswered) {
                this.showProgressIcon = true;
                await this.onQuestionAnswered();
                this.showProgressIcon = false;
            }

            var attributes = this.piniaSurvey.getAttributes;
            var page = this.survey.pages[index];
            if(page == null || page.questions == null) return;
            for(var i = 0; i < page.questions.length; i++)
            {
                if(attributes == null)
                    page.questions[i].visible = true;
                else if(page.questions[i].conditionalJson) {
                    var result =  jsonLogic.apply(JSON.parse(page.questions[i].conditionalJson), attributes);
                    if(result != page.questions[i].visible) {
                        page.questions[i].visible = result;
                    }
                } 
                else 
                    page.questions[i].visible = true;

                var questionInfo = page.questions[i].questionInfo;
                if(questionInfo.answers) {
                    for(var j = 0; j < questionInfo.answers.length; j++) {
                        if(attributes == null)
                            questionInfo.answers[j].visible = true;
                        else if(questionInfo.answers[j].conditionalJson) {
                            var answerResult =  jsonLogic.apply(JSON.parse(questionInfo.answers[j].conditionalJson), attributes);
                            if(answerResult != questionInfo.answers[j].visible) {
                                questionInfo.answers[j].visible = answerResult;
                            }
                        } 
                        else 
                            questionInfo.answers[j].visible = true;
                    }
                }
            }
        },
        async goToBreadcrumbPage(breadcrumb) {
            const index = this.survey.pages.findIndex(pg => pg.configuration.breadcrumbConfiguration.number === breadcrumb.number);
            await this.displayPage(index);
        },
        async displayPage(newPageIndex) {
            if(!this.survey.pages) return;

            if(newPageIndex >= this.survey.pages.length) {
                this.activePageIndex = -1;
                this.processEndofSurvey();
                return;
            }
            
            var page = this.survey.pages[newPageIndex];
            var disableContinueButton = false;
            var attributes = this.piniaSurvey.getAttributes;

            if(this.isDebug)
                console.log(page);

            //display any pages that have 0 questions defined.  Only skip pages that have questions but no visible questions.
            if(page.questions.length == 0){
                this.activePageIndex = newPageIndex;
                return;
            } 

            if(page.questions.length > 1) {
                page.questions.sort((a,b) => {
                    return a.sequence - b.sequence;
                });
            }

            let langCode = this.languageCode;
            page.questions.forEach((ques) => {
                if(ques.questionInfo.attribute && !Object.hasOwn(attributes, ques.questionInfo.attribute.code)) {
                    attributes[ques.questionInfo.attribute.code] = null;
                }

                if(ques.answers) {
                    ques.answers.sort((a,b) => {
                        return a.sequence - b.sequence;
                    });
                }

                if(ques.questionInfo.mediaPropertyId && ques.questionInfo.mediaPropertyId > 0) {
                    if(ques.questionInfo.mediaPropertyInfo.mediaType === 'ToolTip') {
                        const toolTipStart = ques.questionInfo.questionText.indexOf('<ToolTip');
                        const middleTextStart = ques.questionInfo.questionText.search(/(<ToolTip>)/) + 9;
                        const middleTextEnd = ques.questionInfo.questionText.indexOf('</ToolTip>');
                        const toolTipEnd = ques.questionInfo.questionText.indexOf('</ToolTip>') + 10;
                        ques.preText = ques.questionInfo.questionText.substr(0, toolTipStart);
                        ques.postText = ques.questionInfo.questionText.slice(toolTipEnd, ques.questionInfo.questionText.length);
                        ques.middleText = ques.questionInfo.questionText.slice(middleTextStart, middleTextEnd);
                    }
                    if(ques.questionInfo.mediaPropertyInfo.mediaType == 'Video') {
                        if(ques.questionInfo.mediaPropertyInfo.configuration.poster)
                            ques.questionInfo.mediaPropertyInfo.configuration.poster = this.storageBaseUrl + ques.questionInfo.mediaPropertyInfo.configuration.poster;
                        ques.questionInfo.mediaPropertyInfo.configuration.sources.forEach(sc => {
                            if(sc.src.indexOf('http') < 0)
                                sc.src = this.storageBaseUrl + sc.src;
                        });
                        
                        ques.questionInfo.mediaPropertyInfo.configuration.tracks.forEach(tk => {
                            if(tk.src.indexOf('http') < 0)
                                tk.src = this.storageBaseUrl + tk.src;
                            if(!tk.default) {
                                if(tk.srclang === langCode && tk.srclang !== 'en')
                                    tk.default = true;
                                else
                                    tk.default = false;
                            }
                        });
                        ques.questionInfo.mediaPropertyInfo.configuration.style = {
                            height: ques.questionInfo.mediaPropertyInfo.configuration.height + 'px',
                            width: ques.questionInfo.mediaPropertyInfo.configuration.width + 'px'
                        }

                        disableContinueButton = true;
                    }
                }
            });

            this.piniaSurvey.setAttributes(attributes); 

            await this.checkQuestions(newPageIndex);
            if(this.isDebug)
                page.questions.filter(search => search.visible);

            if(page.questions.filter(search => search.visible).length == 0) {
                if(newPageIndex > this.activePageIndex) await this.displayPage(newPageIndex + 1);
                else {
                    await this.displayPage(newPageIndex - 1);
                }
                return;
            }
            else {
                if(page.finishedVideo)
                    this.continueButtonEnabled = true;
                else
                    this.continueButtonEnabled = !disableContinueButton;
                    this.activePageIndex =  newPageIndex;
            }

            this.scrollToTop();

            if(this.areBreadcrumbsEnabled && page.configuration.breadcrumbConfiguration) {
                this.activeBreadcrumbPageNumber = page.configuration.breadcrumbConfiguration.number ?? 0;
                this.breadcrumbKey++;
            }

            if(this.onPageDisplay)
                await this.onPageDisplay(this.survey, this.activePageIndex);
        },
        onVideoFinish: function() {
            this.continueButtonEnabled = true;
            this.survey.pages[this.activePageIndex].finishedVideo = true;
        },
        RemovePageValuesFromStore : async function(pageIndex) {
            var attributes = this.piniaSurvey.getAttributes;
            var page = this.survey.pages[pageIndex];

            for(var j = 0; j < page.questions.filter(search => search.visible).length; j++) {
                var question = page.questions[j];
                if(question.questionInfo.attribute && question.questionInfo.attribute.code != null) {
                    attributes[question.questionInfo.attribute.code] = null;
                    this.surveyAnswers[question.questionInfo.attribute.code] = null;

                    if(attributes[question.questionInfo.attribute.code + '_VALUE'] != null) {
                        attributes[question.questionInfo.attribute.code + '_VALUE'] = null;
                        this.surveyAnswers[question.questionInfo.attribute.code + '_VALUE'] = null;    
                    }

                    if(attributes[question.questionInfo.attribute.code + '_LABEL'] != null) {
                        attributes[question.questionInfo.attribute.code + '_LABEL'] = null;
                        this.surveyAnswers[question.questionInfo.attribute.code + '_LABEL'] = null;    
                    }
                }
            }

            this.piniaSurvey.setAttributes(attributes);

        },
        setActivePage: async function(isMovingForward) {
            if(!this.survey.pages) return;

            if(!this.activePage){
                await this.displayPage(0);
            }
            else {
                if(isMovingForward) {
                    await this.displayPage(this.activePageIndex + 1);
                } else {
                    if(this.activePageIndex > 0) {
                        await this.RemovePageValuesFromStore(this.activePageIndex);
                        await this.displayPage(this.activePageIndex - 1);
                    }
                    else if(this.onGoBack) 
                        await this.onGoBack();
                }
            }
        },
        ComponentValueChanged: function(attributeCode, value) {
            var attributes = this.piniaSurvey.getAttributes;
            attributes[attributeCode] = value;
            this.piniaSurvey.setAttributes(attributes);
            this.checkQuestions(this.activePageIndex);
            this.checkToSubmitPage();                  
        },
        ValueSelectLabelChanged: function(attributeCode, value, label) {
            var attributes = this.piniaSurvey.getAttributes;
            attributes[attributeCode] = value;
            attributes[attributeCode + "_LABEL"] = label;
            this.piniaSurvey.setAttributes(attributes);
            this.checkQuestions(this.activePageIndex);
            this.checkToSubmitPage();                  
        },
        setAnswer(code) {
            let attributes = this.piniaSurvey.getAttributes;
            attributes[code] = this.surveyAnswers[code];
            this.piniaSurvey.setAttributes(attributes);      

            if(this.isDebug)
                console.log("setAnswer: " + JSON.stringify(attributes));

            this.checkQuestions(this.activePageIndex);
            this.checkToSubmitPage();  
        },
        setAutoCompleteAnswer(code, answer, answerValue) {
            this.surveyAnswers[code] = answer;
            this.surveyAnswers[code + "_VALUE"] = answerValue; 
            this.setAnswer(code);
            this.setAnswer(code + "_VALUE");
        },        
        selectAnswer: function(index, ans) {
            let attributes = this.piniaSurvey.getAttributes;
            var code = this.visibleQuestions[index].questionInfo.attribute.code;
            if(this.visibleQuestions[index].questionInfo.questionType === 'SingleSelect') {
                attributes[code] = ans.answerValue;
            }
            else if(this.visibleQuestions[index].questionInfo.questionType === 'MultiSelect') {
                if(attributes[code] == null)
                    attributes[code] = [];

                if(attributes[code].indexOf(ans.answerValue) < 0) {
                   attributes[code].push(ans.answerValue);
                } else {
                    attributes[code].splice(attributes[code].indexOf(ans.answerValue), 1);
                }
            }

            if(this.isDebug)
                console.log(attributes);


            this.piniaSurvey.setAttributes(attributes);
            this.checkQuestions(this.activePageIndex);
            this.checkToSubmitPage();
        },
        checkToSubmitPage: function() {
            //check for popup actions
            let attributes = this.piniaSurvey.getAttributes;

            for(var i = 0; i < this.survey.actions.length; i++) {
                var action = this.survey.actions[i];

                if(action.type === 'PopupModalWindow' && jsonLogic.apply(JSON.parse(action.conditionalJson), attributes)) {
                    this.popupModalConfiguration = action.configuration;
                    return;
                }
            }

            if(this.visibleQuestions.length == 1 && !this.activePage.configuration.continueButtonConfig.visible ) {
                this.submitPage();
            }
        },
        submitPage: async function() {
            let attributes = this.piniaSurvey.getAttributes;
            var question; 
            var code;
            var answer;

            for(var i = 0; i < this.visibleQuestions.length; i++)
            {
                question = this.visibleQuestions[i]; 

                if(!question.questionInfo.attribute || !question.questionInfo.attribute.code) continue;
                if(question.requiredTF == false) continue;

                code = question.questionInfo.attribute.code;
                answer = attributes[code];

                if(answer == null || answer === '') {
                    this.$toast.error('You must answer every question before continuing', );
                    return;
                }
                else if(this.onValidateAnswer) {
                    var retVal = await this.onValidateAnswer(code, answer);
                    if(retVal.success === false) {
                        this.$toast.error(retVal.error_message, );
                        return;
                    }
                }
            } 

            //do this separately so no logging until we know all questions are answered
            for(i = 0; i < this.visibleQuestions.length; i++)
            {
                question = this.visibleQuestions[i]; 
                if(!question.logActivityTypeCode) continue;

                if(!question.questionInfo.attribute || !question.questionInfo.attribute.code) continue;
                code = question.questionInfo.attribute.code;
                answer = attributes[code];
                
                var answerText = '';
                var answerIndex = !question.questionInfo.answers || Array.isArray(answer) ? -1 : question.questionInfo.answers.findIndex(search => search.answerValue === answer);
                if(answerIndex >= 0)
                    answerText = question.questionInfo.answers[answerIndex].answerText;

                else if (Array.isArray(answer)) {
                    answerText = [];
                    for(var j = 0; j < answer.length; j++) {
                        answerIndex = question.questionInfo.answers.findIndex(search => search.answerValue === answer[j]);
                        if(answerIndex >= 0) 
                            answerText.push(question.questionInfo.answers[answerIndex].answerText);
                    }
                } 

                var loggingObject = { 
                    questionCode: question.questionInfo.attribute.code,
                    answerText: answerText,
                    answerValue: answer,
                    questionText: question.questionInfo.questionText,
                    questionKey: question.id
                };

                if(question.logActivityTypeCode) {
                    data.postActivityLog({
                        logActivityTypeCode: question.logActivityTypeCode,
                        data: JSON.stringify(loggingObject)
                    });
                }
            }          

            // perform calculations
            for(let i = 0; i < this.calculationActions.length; i++) {
                attributes = this.piniaSurvey.getAttributes;

                switch(this.calculationActions[i].subType) {
                    case 'DateCalculation': {
                        const value1 = this.processDateValue(this.calculationActions[i].configuration.operation.value1);
                        const value2 = this.calculationActions[i].configuration.operation.operator === 'FirstOfMonth' ? '' : this.processDateValue(this.calculationActions[i].configuration.operation.value2);
                        if(!value1 || (!value2 && this.calculationActions[i].configuration.operation.operator !== 'FirstOfMonth'))
                            break;
                        switch(this.calculationActions[i].configuration.operation.operator) {
                            case 'FirstOfMonth':
                                this.setAttribute(this.calculationActions[i].attribute, `${value1.getMonth() + 1}-01-${value1.getFullYear()}`);
                                break;
                            case 'DateDiffDays': {
                                let diff = value2 - value1 + ((value2.getTimezoneOffset() - value1.getTimezoneOffset()) * 60 * 1000);
                                const oneDay = 1000 * 60 * 60 * 24;
                                const days = Math.floor(diff / oneDay);
                                this.setAttribute(this.calculationActions[i].attribute, days <= 0 ? 0 : days);
                                break;
                            }
                            case 'DateDiffMonths': {
                                let months = (value2.getFullYear() - value1.getFullYear()) * 12;
                                months += value2.getMonth();
                                months -= value1.getMonth();
                                if(value2.getDate() < value1.getDate())
                                    months--;
                                this.setAttribute(this.calculationActions[i].attribute, months <= 0 ? 0 : months);
                                break;
                            }
                        }
                        break;
                    }
                    case 'NumberCalculation': {
                        let value1 = Object.hasOwn(attributes, this.calculationActions[i].configuration.operation.value1) ? attributes[this.calculationActions[i].configuration.operation.value1] : this.calculationActions[i].configuration.operation.value1;
                        let value2 = Object.hasOwn(attributes, this.calculationActions[i].configuration.operation.value2) ? attributes[this.calculationActions[i].configuration.operation.value2] : this.calculationActions[i].configuration.operation.value2;
                        if(value1 == null || value2 == null || isNaN(value1) || isNaN(value2))
                            break;
                        let operator;
                        switch(this.calculationActions[i].configuration.operation.operator) {
                            case 'Add':
                                operator = '+';
                                break;
                            case 'Subtract':
                                operator = '-';
                                break;
                            case 'Multiply':
                                operator = '*';
                                break;
                            case 'Divide':
                                operator = '/';
                                break;
                        }
                        this.setAttribute(this.calculationActions[i].attribute, evaluate(value1.toString().concat(operator, value2.toString())));
                        break;
                    }
                    case 'LogicalCalculation':
                        this.calculationActions[i].configuration.calculations.sort((a,b) => {return a.sequence - b.sequence;});
                        this.calculationActions[i].configuration.calculations.some(calc => {          
                            let logicalValue = Object.hasOwn(attributes, calc.value) ? attributes[calc.value] : calc.value;
                      

                            if(calc.jsonExpression === 'ELSE') {
                                this.setAttribute(this.calculationActions[i].attribute, logicalValue);
                                 // ends iteration
                                return true;
                            }
                            if(jsonLogic.apply(JSON.parse(calc.jsonExpression), attributes)) {

                                this.setAttribute(this.calculationActions[i].attribute, logicalValue);
                                // ends iteration
                                return true;
                            }
                        });
                        break;
                    case 'SetConstant':
                        this.setAttribute(this.calculationActions[i].attribute, this.calculationActions[i].configuration.value);
                        break;
                }
            }

            if(this.isDebug)
                console.log(attributes);

            if(!(await this.processSurveyAction())) 
                await this.setActivePage(true);
        },
        setAttribute: function(attribute, value) {
            if(attribute.useAsReplacementString) {
                let replacementStrings = this.piniaSurvey.getReplacementStrings;
                replacementStrings['%' + attribute.code + '%'] = value;
                this.piniaSurvey.setReplacementStrings(replacementStrings);
            } else {
                let attributes = this.piniaSurvey.getAttributes;
                attributes[attribute.code] = value;
                this.piniaSurvey.setAttributes(attributes);
            }
        },
        goBackToPreviousPage: function() {
            this.setActivePage(false);
        },
        processEndofSurvey: function() {
            for (let i = 0; i < this.survey.actions.length; i++) {
                if(this.survey.actions[i].configuration.isDefault === true) { 
                    this.SetSurveyAction(this.survey.actions[i]);
                    return true;
                }
            }
            
            if(this.onEndOfSurvey) {
                this.onEndOfSurvey();
                return true;
            }

            try {
                throw new Error("Reached end of survey without a specific place to go:" + JSON.stringify(this.piniaSurvey.getAttributes));
            }
            catch(error) {
                data.postErrorLog(error);
            }           
        },
        processSurveyAction: function() {
            //don't process actions if there's only one item and it's the default
            if(this.survey.actions.length === 1 && this.survey.actions[0].configuration.isDefault === true )  return false;

            if(this.survey.actions && this.survey.actions.length) {
                for (let i = 0; i < this.survey.actions.length; i++) {
                    if(this.survey.actions[i].type === 'ShortCirct' 
                            && this.survey.actions[i].conditionalJson !== '{}') {
                        if(jsonLogic.apply(JSON.parse(this.survey.actions[i].conditionalJson), this.piniaSurvey.getAttributes)) {
                            this.SetSurveyAction(this.survey.actions[i]);
                            return true;
                        }
                    }
                }
            } 

            return false;
        },
        SetSurveyAction: function(action) {
            var logObject = {
                survey_action_key: action.id,
                action_type: action.type,
                action_sub_type: action.subType
            }

            if(action.subType == 'SimpleInformationPage') { 
                logObject.description = action.configuration.description === '' ? 
                            action.configuration.displayText : action.configuration.description;
            }
            else if(action.subType == 'AccordionInformationPage') { 
                logObject.accordion = action.configuration.accordionCode;
            } 
        
            data.postActivityLog({
                    logActivityTypeCode: 'SURVEY_ACTION',
                    sourceType: 'IPAddress',
                    data: JSON.stringify(logObject)
                });

            if(this.onEndOfSurvey)
                this.onEndOfSurvey(action);
        },
        breadcrumbPageClick: async function(breadcrumbPage) {
            const newPageIndex = this.survey.pages.findIndex(pg => pg.configuration.breadcrumbConfiguration.number === breadcrumbPage.number );
            if(newPageIndex >= 0) {
                for(let i = newPageIndex + 1; i < this.survey.pages.length; i++) {
                    await this.RemovePageValuesFromStore(i);
                }
                await this.displayPage(newPageIndex);
            }
            else if(this.onBreadcrumbClick)
                await this.onBreadcrumbClick(breadcrumbPage);
        
        },
        processDateValue: function(dateStr) {
            if(dateStr === 'NOW')
                return new Date();
            else if(!(dateStr instanceof Date)) { 
                const attributes = this.piniaSurvey.getAttributes;
                const attrVal = attributes[dateStr];
                if(attrVal)
                    return new Date(attrVal); 
                else 
                    return null;
            }
            else
                return new Date(dateStr);
        },
        isJsonString: function(str) {
            try {
                let obj = JSON.parse(str);
                if(typeof obj === 'number')
                    return false;
            } catch (e) {
                return false;
            }
            return true;
        },
        numericInputAttributes: function(displayParams) {
            return {
                min: displayParams.minValue || displayParams.minValue === 0 ? displayParams.minValue : null,
                max: displayParams.maxValue || displayParams.maxValue === 0 ? displayParams.maxValue : null,
                step: displayParams.numberStep,
                pattern: displayParams.pattern
            }
        }
    }
}
</script>

<style lang="scss" scoped src="@/assets/css/shared/tooltip/tooltip_standard.scss"></style>