<template>
    <div class="progress-bar-container">
        <div v-if="textLocation === 'Top'" v-html="progressBarText" class="progress-bar-text"></div>
        <div class="progress-bar">
            <div class="completed-bar" :style="completedWidth">
                <div v-if="textLocation === 'Inside'" v-html="progressBarText" class="progress-bar-text"></div>
            </div>
            <div class="uncompleted-bar" :style="uncompletedWidth"></div>
        </div>
        <div v-if="textLocation === 'Bottom'" v-html="progressBarText" class="progress-bar-text"></div>
    </div>
</template>

<script>

export default {
    name: 'ProgressBar',
    props: {
        totalPages: {
            type: Number,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        },
        textFormat: {
            type: String,
            required: true
        },
        textLocation: {
            type: String,
            required: true,
            validator(value) {
                return ['Top', 'Bottom', 'Inside'].includes(value);
            }
        }
    },
    computed: {
        completedWidth() {
            return {
                width: (this.currentPage / this.totalPages) * 100 + '%'
            };
        },
        uncompletedWidth() {
            return {
                width: ((this.totalPages - this.currentPage) / this.totalPages) * 100 + '%'
            };
        },
        progressBarText() {
            return this.textFormat.replace('%CURRENT_PAGE%', this.currentPage).replace('%TOTAL_PAGES%', this.totalPages);
        }
    }
}
</script>

<style lang="scss" scoped>
    .progress-bar-container {
        position: relative;
        display: flex;
        flex-direction: column;
        .progress-bar {
            width: 100%;
            display: flex;
            flex-direction: row;
            .completed-bar {
                overflow-x:visible;
                display: flex;
                align-items: center;
            }
        }
    }
</style>