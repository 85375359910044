<template>
  <div v-if="show_idomoo_error" style="color:red;">Unable to generate videos at this time.  Please try back again later.   NOTE: Saving changes is unaffected</div>
  <div style="width: 100%; display: flex; flex-direction: row;">
      <div class="row survey" style="width: 70%">
      <div class="col-md-auto" style="display:flex; flex-direction: column;">
          <div class="surveyText">Name of the configuration<span class="required">*</span></div>
          <div class="surveyTextNote">50 character maximum</div>
          <div  class="questionPadding">
          <input type="text" placeHolder="Configuration Name" v-model="ClientDataName">
          </div>
          <div style="padding-bottom: 27px;">Fill out all the required(<span class="required">*</span>) fields below. Then, press Generate Video to create a video file you can download and send.</div>
          <div>
          <div class="questionHeader">Logo Files</div>
          <div class="surveyText">Logos will look best if they’re at least 300px wide. PNG, JPG, or GIF files are accepted.</div>
          <div class="surveyText">Upload the <span class="bold">client</span> logo for this video. <span class="required">*</span></div>
          <div class="questionPadding">
              <input type="file" name="client_logo" id="client_logo" @change="postFile('clientLogoUrl')" accept=".jpg, .gif, .png" hidden>
              <label v-if="!configData.clientLogoUrl" for="client_logo" class="fileInput">Upload a File</label>
              <label v-if="configData.clientLogoUrl" for="client_logo" class="fileInput">Upload a Different File</label>
              <img v-if="configData.clientLogoUrl" :src="configData.clientLogoUrl" class="logoPreview" :key="clientLogoKey" onerror="this.style.display='none'" />
          </div>
          <div class="surveyText">Upload the benefits <span class="bold">administration</span> logo for this video (if applicable).</div>
          <div class="questionPadding">
              <input type="file" name="provider_logo" id="provider_logo" @change="postFile('providerLogoUrl')" accept=".jpg, .gif, .png" hidden>
              <label v-if="!configData.providerLogoUrl" for="provider_logo" class="fileInput">Upload a File</label>
              <label v-if="configData.providerLogoUrl" for="provider_logo" class="fileInput">Upload a Different File</label>
              <img v-if="configData.providerLogoUrl" :src="configData.providerLogoUrl" class="logoPreview" :key="providerLogoKey" onerror="this.style.display='none'" />
          </div>
          </div>
      </div>
      </div>
      <div class="rightColumn">
      <div @click="onClickSampleVideo" style="cursor: pointer;">
          <img src="images/MMA/samplevideo-thumb.png" class="sampleVideo" />
          <div class="sampleVideoText">
          Click the image above to see an example of where the fields appear once you generate your video (opens in a new tab).
          </div>
      </div>
      </div>
  </div>
  <div style="width: 100%; margin: 0px; padding: 0px;"><hr class="grayLine" /></div>
  <div class="row survey" style="width: 70%">
      <div class="col-md-auto" style="display:flex; flex-direction: column;">
      <div class="questionHeader">Enrollment Details</div>
      <div class="surveyText">Is this an Open or an Annual Enrollment? <span class="required">*</span></div>
      <div class="questionPadding">
          <select v-model="configData.isOpenEnrollment">
          <option disabled value="" selected >Select One</option>
          <option>Open</option>
          <option>Annual</option>
          </select>   
      </div>
      <div class="surveyText">Select the plan year(s): <span class="required">*</span></div>
      <div class="questionPadding">
          <select v-model="configData.planYearStart">
          <option disabled value="" selected>Select One</option>
          <option v-for="year in planYears" :key="year">{{year}}</option>
          </select> -  
          <select v-model="configData.planYearEnd">
          <option disabled value="" selected>Select One</option>
          <option v-for="year in planYearsPlusOne" :key="year">{{year}}</option>
          </select> 
      </div>  
      <div class="surveyText">Please select the start and end dates of the enrollment period. <span class="required">*</span></div>
      <div style="display:flex; flex-direction: row; justify-content: space-between">
          <div style="width:49%" class="questionPadding">
          <input type="date" v-model="configData.startDate" class="dateInput" placeholder="Choose start date" max="2999-12-31" />
          </div>
          <div style="width:49%" >
          <input type="date" v-model="configData.endDate" class="dateInput" placeholder="Choose end date" max="2999-12-31" />
          </div>
      </div>
                  
      </div>
  </div>
  <div style="width: 100%; margin: 0px; padding: 0px;"><hr class="grayLine" /></div>
  <div class="row survey" style="width: 70%">
      <div class="col-md-auto" style="display:flex; flex-direction: column;">
      <div class="question">
          <div class="questionHeader">This Year's Details</div>
          <div class="surveyText">Do you want to include details about this year’s open enrollment?</div>
          <div style="padding-left: 10px" class="questionPadding">
          <div>
              <input type="radio" id="includeDetailsYes" name="includeDetails" value="Yes" v-model="configData.includeDetails">
              <label class="radioLabel" for="includeDetailsYes">Yes</label>
          </div>
          <div>
              <input type="radio" id="includeDetailsNo" name="includeDetails" value="No" v-model="configData.includeDetails">
              <label class="radioLabel" for="includeDetailsNo">No</label>
          </div>
          </div>
          <div v-if="configData.includeDetails === 'Yes'">
          <div class="questionPadding">Title: What's 
              <select v-model="configData.newOrComingUp">
              <option disabled value="" selected>Select One</option>
              <option>New</option>
              <option>Coming Up</option>
              </select> <span class="required">*</span> 
              this plan year?        
          </div>      
          <div class="surveyText">List up to four new plan features or important reminders.</div>
          <div class="surveyTextNote questionPadding" >We recommend keeping these to around 75 characters each. Leave fields blank if they’re not needed.</div>
          <div style="display:flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap;" 
              class="questionPadding">
              <div style="width:49%; padding-bottom: 19px; display: flex;">
              <textarea class="bulletPoints" placeHolder="Featured bullet point #1" v-model="configData.bulletPoints[0]" />
              <span class="required">*</span>
              </div>
              <div style="width:49%; padding-bottom: 19px;">
              <textarea class="bulletPoints" placeHolder="Featured bullet point #2" v-model="configData.bulletPoints[1]" />
              </div>
              <div style="width:49%;">
              <textarea class="bulletPoints" placeHolder="Featured bullet point #3" v-model="configData.bulletPoints[2]" />
              </div>
              <div style="width:49%;">
              <textarea class="bulletPoints" placeHolder="Featured bullet point #4" v-model="configData.bulletPoints[3]" />
              </div>
          </div>
          </div>
      </div>
      </div>
  </div>
  <div style="width: 100%; margin: 0px; padding: 0px;"><hr class="grayLine" /></div>
  <div class="row survey" style="width: 70%">
      <div class="col-md-auto" style="display:flex; flex-direction: column;">
      <div class="question">
          <div class="questionHeader">Meeting Information</div>
          <div class="surveyText">Do you want to include meeting information?</div>
          <div style="padding-left: 10px" class="questionPadding">
          <div>
              <input type="radio" id="includeMeetingYes" name="includeMeeting" value="Yes" v-model="configData.includeMeeting">
              <label class="radioLabel" for="includeMeetingYes">Yes</label>
          </div>
          <div>
              <input type="radio" id="includeMeetingNo" name="includeMeeting" value="No" v-model="configData.includeMeeting">
              <label class="radioLabel" for="includeMeetingNo" >No</label>
          </div>
          </div>
          <div v-if="configData.includeMeeting === 'Yes'">              
          <div class="surveyText">What is the title of your virtual or in-person meeting? <span class="required">*</span></div>
          <div class="surveyTextNote">50 character maximum</div>
          <div  class="questionPadding">
              <input type="text" placeHolder="Meeting Title" v-model="configData.meetingTitle">
          </div>
          <div class="surveyText">Meeting details line 1: <span class="required">*</span></div>
          <div class="questionPadding">
              <input type="text"  placeHolder="Ex. June 1, 2:00-3:00pm CST" v-model="configData.meetingDateTime" />
          </div>
          <div class="surveyText">(Optional) Meeting details line 2:</div>
          <div class="questionPadding">
              <input type="text" placeHolder="Ex. June 3, 2:00-3:00pm CST" v-model="configData.meetingDateTime2" />
          </div>
          <div class="surveyText">(Optional) location info, zoom link, recording info, etc.</div>
          <div  class="questionPadding">
              <textarea placeHolder='Ex. Conference Room A or "Zoom link to follow"' v-model="configData.meetingLocation"></textarea>
          </div>
          <div class="surveyText">
              Want to see examples of how you can enter meeting info 
              <i class="fa-solid fa-circle-question popupQuestion" @mouseover="onShowTooltip" style="position:relative;">
              <div class="popup" v-show="showTooltip">
                  <div class="popupText">Example with one meeting</div>
                  <img src="/images/MMA/onemeeting-ex.png" style="width:320px" />
                  <div class="popupText">Example with several meetings</div>
                  <img src="/images/MMA/moremeetings-ex.png" style="width: 320px" />
              </div>
              </i>
          </div>
          </div>
      </div>
      </div>
  </div>
  <div style="width: 100%; margin: 0px; padding: 0px;"><hr class="grayLine" /></div>
  <div class="row survey" style="width: 70%">
      <div class="col-md-auto" style="display:flex; flex-direction: column;">
      <div>
          <div class="questionHeader">Next Steps</div>
          <div class="surveyText">Do you want to add instructions for what next steps employees should take?</div>
          <div style="padding-left: 10px" class="questionPadding">
          <div>
              <input type="radio" id="includeNextStepsYes" name="includeNextSteps" value="Yes" v-model="configData.includeNextSteps">
              <label class="radioLabel" for="includeNextStepsYes">Yes</label>
          </div>
          <div>
              <input type="radio" id="includeNextStepsNo" name="includeNextSteps" value="No" v-model="configData.includeNextSteps">
              <label class="radioLabel" for="includeNextStepsNo" >No</label>
          </div>
          </div>
          <div v-if="configData.includeNextSteps === 'Yes'">
          <div class="surveyText">Finish this sentence: When you're ready to enroll: <span class="required">*</span></div>
          <div class="surveyTextNote">For example, “Visit the benefits administration site to make your benefits elections for the year. If you have any questions, reach out to Human Resources or your Marsh McLennan Agency Team.” 200 character maximum</div>
          <div class="questionPadding">
              <input type="text" placeHolder="Describe employee next steps" style="width: 450px;" v-model="configData.nextSteps" />
          </div>
          </div>
      </div>
      </div>
  </div>
  <div style="width: 100%; margin: 0px; padding: 0px;"><hr class="grayLine" /></div>
  <div class="row survey" style="width: 70%; padding-bottom: 20px;">
      <div class="col-md-auto" style="display:flex; flex-direction: column;">
      <div>
          <div>
          <button class="submit" @click="onSave" :disabled="!validated">Save Configuration</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button class="submit" @click="onClickCancel">Go Back</button>
          </div>
          <div class="questionHeader">That's It!</div>
          <div v-if="!validated" class="disabled">
          Fill out the required fields
          </div>
          <div v-if="validated && !videoReady" class="surveyText">You’re ready to make your video and get your link.</div>
          <div>
          <button v-if="validated && allowGenerate" class="submit" @click="onSubmit">Generate Video</button>
          <div v-if="videoReady">
              <div class="surveyText">Your video link:</div>
              <span><a :href="videoUrl" target="_blank">{{videoUrl}}</a></span>
          </div>
          </div>
      </div>
      </div>
  </div>
</template>

<script>
import { data } from '@/shared';
import * as axios from 'axios';
export default {
name: "ConfigMaintenance",
props: {
  ID : {
    type: Number
  },
  onLogActivity : {
    type: Function
  },
  onCancel: {
    type: Function 
  }
},
data() {
    return {
        loaded: false,
        clientLogoKey: 0,
        providerLogoKey: 0,
        configData: {
          clientLogoUrl: "",
          providerLogoUrl: "",
          isOpenEnrollment: "",
          startDate: null,
          endDate: null,
          planYearStart: "",
          planYearEnd: "",
          bulletPoints: [],
          meetingTitle: null,
          meetingLocation: null,
          meetingDateTime: null,
          meetingDateTime2: null,
          newOrComingUp: "",
          nextSteps: null,
          includeDetails: "No",
          includeMeeting: "No",
          includeNextSteps: "No"
        },
        ClientDataName: "",
        toastTimedOptions: {
            position: 'top-right',
            duration: 1500,
        },
        toastStaticOptions: {
            position: 'top-right',
        },
        idomooConfiguration: { 
          storyboard_id: '',
          data: [],
          output: {
            video: [{
              height: 720,
              label: "test",
              quality: 21,
              suffix: "134652",
              format: "mp4"
            }]
          }
        },
        videoUrl: "",
        allowGenerate: true,
        videoReady: false,
        planYears: [],
        planYearsPlusOne: [],
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        showTooltip: false,
        recordID: 0
    }
},
computed: {
  year() { 
    return new Date().getFullYear();
  },
  show_idomoo_error() {
    return this.loaded === true && this.idomooConfiguration.storyboard_id === '';
  },
  validated () {
    return (
      this.idomooConfiguration.storyboard_id != '' &&
      this.ClientDataName &&
      this.configData.clientLogoUrl && 
      this.configData.isOpenEnrollment &&
      this.configData.startDate &&
      this.configData.endDate &&
      this.configData.planYearStart && 
      (this.configData.includeDetails === 'No' ||
        (this.configData.includeDetails === 'Yes' && this.configData.newOrComingUp &&
          this.configData.bulletPoints.length > 0 && 
          this.configData.bulletPoints[0] && this.configData.bulletPoints[0].trim().length > 0)
      ) &&
      (this.configData.includeMeeting === 'No' ||
        (this.configData.includeMeeting === 'Yes' && 
          this.configData.meetingTitle && this.configData.meetingTitle.trim().length > 0 &&
          this.configData.meetingDateTime && this.configData.meetingDateTime.trim().length > 0)
      ) &&
      (this.configData.includeNextSteps === 'No' ||
        (this.configData.includeNextSteps === 'Yes' && 
          this.configData.nextSteps && this.configData.nextSteps.trim().length > 0)
      )
    );
  }
},
async mounted() {

  if(this.ID > 0)
      this.recordID = this.ID;
  else 
      this.recordID = null;

  var constants = await data.getClientConstants();

  if(constants) {
    const found = constants.find(search => search.key === 'IDOMOO_STORYBOARD_ID');

    if(found != null)
      this.idomooConfiguration.storyboard_id = found.value;
  }
  
  this.logActivity('LOADED_PAGE');
  let today = new Date();
  let year = today.getFullYear();
  this.planYears.push(year);
  this.planYears.push(++year);
  this.planYearsPlusOne = this.planYears;
  this.planYearsPlusOne.push(++year);

  this.getClientData();
  this.loaded = true;
},
methods: { 
  getClientData: async function() {
      if(this.recordID)
      {
          var clientData = await data.loadClientData(this.recordID)
          if(clientData) {
            this.configData = clientData.clientDataConfiguration;
            this.ClientDataName = clientData.clientDataName;
            let dt = new Date(this.configData.startDate);
            this.configData.startDate = dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + '-' +  ("0" + dt.getDate()).slice(-2);
             dt = new Date(this.configData.endDate);
            this.configData.endDate = dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + '-' +  ("0" + dt.getDate()).slice(-2);
            if(this.configData.clientLogoUrl) {
              let vm = this;
               await axios.get(this.configData.clientLogoUrl)
                          .catch(async function(error) {
                            if(error.response.status === 404) {
                              await axios.post('/mmaDownloadWasabiFileToWebsite',{path: vm.configData.clientLogoUrl});
                              vm.clientLogoKey++;
                            }
                          })
            }
            if(this.configData.providerLogoUrl) {
              let vm = this;
              await axios.get(this.configData.providerLogoUrl)
                                    .catch(async function(error) {
                                      if(error.response.status === 404) {
                                        await axios.post('/mmaDownloadWasabiFileToWebsite',{path: vm.configData.providerLogoUrl});
                                        vm.providerLogoKey++;
                                      }
                                    })
            }
          }
      }
  },
  onClickSampleVideo: async function() {
    const storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);
    var url = await data.getMediaFile(storageBaseUrl +  "/General/enrollmentSample.mp4");

    window.open(url, "_blank");

/*    Commented code for downloading to the page, just in case we change our mind  
    axios.get(url, { responseType: 'blob' })
    .then(response => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = "sample.mp4";
      link.click();
      URL.revokeObjectURL(link.href);
    }).catch(console.error);*/

  },
  onShowTooltip() {
    this.showTooltip = true;
  },
  closePopup() {
    this.showTooltip = false;
  },
  onSave: async function() {
    if(!this.validateForm()) return;

    this.logActivity("CLICK_SAVE");
      var returnData = await data.saveClientData(this.recordID, this.ClientDataName, "ECardVideoConfig", this.configData);

      if(returnData.success) { 	  
        this.$toast.success('Saved!');
        if(this.recordID == null)
          this.recordID = returnData.id;
      } 
      else
        this.$toast.error("Could not save eCard Configuration<br />" + 
            returnData.errors.join("<br />"));
  },
  onClickCancel: async function() {
     if(this.onCancel)
      this.onCancel();
  },
  onSubmit: async function() {
    if(!this.validateForm())
      return;

    this.allowGenerate = false;
    this.$toast.success('Processing...', this.toastStaticOptions);
     
    this.createIdomooObject();

    var urls = await data.postIdomoo(this.idomooConfiguration);

    this.logActivity('CLICK_GENERATE_VIDEO');
    await this.delay(1000);

    do{
      this.videoReady = await this.checkIfVideoReady(urls.checkStatusUrl);
    }
    while(!this.videoReady);

    this.$toast.success('Video Generation Complete', this.toastTimedOptions);
    this.videoUrl = urls.videoUrl;
  },
  delay: async function(milliseconds){
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
  }, 
  checkIfVideoReady: async function(url) {
   let complete = await data.checkIdomooStatus(url);
    if(!complete) {
      await this.delay(500);
      return false;
      //this.checkIfVideoReady(url);
    }
    else
      return true;
  },
  createIdomooObject() {
    this.idomooConfiguration.data = [];
    if(this.configData.clientLogoUrl)
      this.idomooConfiguration.data.push({key: "clientLogo", val: window.location.protocol + '//' + window.location.hostname + this.configData.clientLogoUrl});

    if(this.configData.providerLogoUrl)
      this.idomooConfiguration.data.push({key: "providerLogo", val: window.location.protocol + '//' + window.location.hostname + this.configData.providerLogoUrl});

    if(this.configData.isOpenEnrollment)
      this.idomooConfiguration.data.push({key: "is_open_enrollment", val: this.configData.isOpenEnrollment});

    if(this.configData.startDate) {
      let dateArray = this.configData.startDate.split("-");
      this.idomooConfiguration.data.push({key: "start_date", val: this.months[parseInt(dateArray[1]) - 1] + ' ' + dateArray[2]});
    }

    if(this.configData.endDate) {
      let dateArray = this.configData.endDate.split("-");
      this.idomooConfiguration.data.push({key: "end_date", val: this.months[parseInt(dateArray[1]) - 1] + ' ' + dateArray[2]});
    }

    if(this.configData.isPassiveEnrollment)
      this.idomooConfiguration.data.push({key: "is_passive_enrollment", val: this.configData.isPassiveEnrollment});

    if(this.configData.includeMeeting)
      this.idomooConfiguration.data.push({key: "include_meeting", val: this.configData.includeMeeting});

    if(this.configData.meetingTitle)
      this.idomooConfiguration.data.push({key: "meeting_title", val: this.configData.meetingTitle});

    if(this.configData.meetingLocation)
      this.idomooConfiguration.data.push({key: "meeting_location", val: this.configData.meetingLocation});

    if(this.configData.meetingDateTime)
      this.idomooConfiguration.data.push({key: "meeting_dtm", val: this.configData.meetingDateTime});

    if(this.configData.meetingDateTime2)
      this.idomooConfiguration.data.push({key: "meeting_dtm2", val: this.configData.meetingDateTime2});

    if(this.configData.newOrComingUp)
      this.idomooConfiguration.data.push({key: "new_or_coming_up", val: this.configData.newOrComingUp});

    if(this.configData.nextSteps)
      this.idomooConfiguration.data.push({key: "next_steps", val: this.configData.nextSteps});

    if(this.configData.planYearStart)
    {
      var planYear = this.configData.planYearStart;
      if(this.configData.planYearEnd && this.configData.planYearStart != this.configData.planYearEnd)
        planYear = planYear + ' - ' + this.configData.planYearEnd;

      this.idomooConfiguration.data.push({key: "plan_year", val: planYear});
    }

    for(var i = 0; i < this.configData.bulletPoints.length; i++)
    {
      if(this.configData.bulletPoints[i])
        this.idomooConfiguration.data.push({key: "bullet_point_" + i, val: this.configData.bulletPoints[i]});
    }
  },
  validateForm() {
    if(this.configData.meetingTitle && this.configData.meetingTitle.length > 50) {
      this.$toast.error("Meeting Title must be 50 characters or less");
      return false;
    }
    if(this.configData.nextSteps && this.configData.nextSteps.length > 200) {
      this.$toast.error("Next Steps must be 200 characters or less");
      return false;
    }

    if(this.configData.startDate > this.configData.endDate)
    {
      this.$toast.error("The start date of enrollment period must be before the end date");
      return false;
    }

    if(this.configData.planYearStart && this.configData.planYearEnd && this.configData.planYearEnd < this.configData.planYearStart)
    {
      this.$toast.error("The plan year end must be after the plan year start");
      return false;
    }

    return true;
  },
  async postFile(fileUrlField) {
    try {
      let files = event.target.files;
      if(!files) return;    
      var file = files[0];

      // Check file type
      if(file.type != 'image/png' && file.type != 'image/jpeg' && file.type != 'image/gif')
      {
        this.$toast.error('Invalid File Type', this.toastTimedOptions);
        event.target.value = null;
        return;
      }

      let formData = new FormData();
      formData.append('file', file);
      

      const websiteResponse = await axios.post('/singleFile',formData, {headers: {'Content-Type': 'multipart/form-data'}})
      await data.postSingleFileToWasabiMMA(file, websiteResponse.data.path);

      this.configData[fileUrlField] = websiteResponse.data.path;
      if(fileUrlField === 'clientLogoUrl')
        this.clientLogoKey++;
      else if(fileUrlField === 'providerLogoUrl')
        this.providerLogoKey++;
    }
    catch(error)
    {
        console.error(error);
    }
  },
  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
        callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  },
  logActivity: async function(activityTypeCode) {
    if(this.onLogActivity)
    {
      if(activityTypeCode == "CLICK_SAVE")
        this.onLogActivity(activityTypeCode, JSON.stringify({"name": this.ClientDataName, "id": this.recordID, "configData": this.configData}));
      else {
        this.onLogActivity(activityTypeCode, JSON.stringify({"name": this.ClientDataName, "id": this.recordID, "idomooData": this.idomooConfiguration.data}));
      }
    }
  }
}
};
</script>
<style lang="scss" scoped src="@/assets/css/client/JV/style.scss"></style>
