<template>
  <div>
    <Header></Header>
    <Accordion
      accordionCode="CS_JELLYROLL"
      v-bind:displayTitle="false"
    ></Accordion>
    <FeedbackModal :questions="feedbackQuestions" 
       thankYouMessage="Thanks for helping!"  ></FeedbackModal>
    <Footer></Footer>
    <PageVisitLogger client="JV" activityType="JV_VISIT_CST"></PageVisitLogger>
  </div>
</template>
<script>
import Header from "./shared/Header.vue";
import Accordion from "@/components/shared/Accordion";
import Footer from "./shared/Footer";
import FeedbackModal from "./shared/FeedbackModal";
import PageVisitLogger from "./shared/PageVisitLogger";

require('../assets/css/shared/feedback/feedback_variables_standard.scss');

export default {
  components: { Header, Accordion, Footer, FeedbackModal, PageVisitLogger },
  name: "Timeline",
  data() {
    return {
      myVal: 0,
      feedbackQuestions: [
        {
          question: "How helpful was this info about Solutions & Services?",
          subject: "JV_TIMELINE",
          sequence: 1,
          icons: [
            {score: 1, class: 'fas fa-grimace fa-2x', text:'Totally missed the mark', show: false},
            {score: 2, class: 'fas fa-frown fa-2x', text: 'Not helpful', show: false},
            {score: 3, class: 'fas fa-meh fa-2x', text: "I'm not sure", show: false},
            {score: 4, class: 'fas fa-smile fa-2x', text: 'Very helpful', show: false},
            {score: 5, class: 'fas fa-grin-stars fa-2x', text: 'Extremely helpful', show: false}
          ]
        }
      ]
    };
  },
};
</script>
<style lang="scss" scoped src="@/assets/css/scoped/cs_timeline.scss"></style>
<style lang="scss" scoped src="@/assets/css/shared/feedback/modal_standard.scss"></style>
