<template>
    <div class="errorPageContainer">
        <div class="errorPageContent">
            <div class="errorTextContainer">
                <div class="errorTitle">Whoops.</div>
                <div class="errorSubTitle">Looks like you caught us in a bit of a jam!</div>
                <div class="errorText">
                    Something isn't working on our end and it's resulting in this error.
                    <br/>
                    Please try again or refresh your page. If you're still seeing an error message, please reach out to an administrator for assistance.
                </div>
            </div>
            <div class="errorImageContainer">
                <img src="/images/error-page.png" />   
            </div>
        </div>
        <Footer alignment="left"></Footer>
    </div>
</template>

<script>
require('../assets/css/scoped/error_page/error_page_variables_standard.scss');
import Footer from "./shared/Footer.vue";

export default {
    name: 'ErrorPage',
    components: {Footer},
    data() {
        return {};
    }
}
</script>

<style lang="scss" scoped src="@/assets/css/scoped/error_page/error_page.scss"></style>