import { data } from '@/shared';

const loadAudioTracks = async function(actions) {
    var tracks = [];
    if(actions && actions.length) {
        for(var i = 0; i < actions.length; i++) {
            // Filters out non-audio actions
            if(!_isAudioAction(actions[i])) continue;

            //check to see if track is already in the list.  Don't add again.
            if(tracks.some((element) => element.id === actions[i].configuration.mediaPropertyID.toString())) continue;

            tracks.push(await getAudioTrack(actions[i].configuration.mediaPropertyID));
        }
    }

    return tracks;
}

const getAudioTrack = async function(mpId) {
    const audioInfo = await data.getMediaProperty(mpId);
    const storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);
    var location = audioInfo.configuration.location;
    let ccLocation = audioInfo.configuration.closedCaptionsLocation;
    if(audioInfo.configuration.isLocalPath === false) {
        var ticks = data.convertToTicks(new Date());      
        location = storageBaseUrl + (location[0] !== '/' ? '/' : '') + location;
        //commented out because it was taking too long....just gonna use the current date for now.
      //  var ret = await data.getMediaFileProperties(location);
     //   location = location + '?v=' + ret.LastModifiedTicks;
        location = location + '?v=' + ticks;
        ccLocation = ccLocation ? storageBaseUrl + (ccLocation[0] !== '/' ? '/' : '') + ccLocation : '';
       // ret = await data.getMediaFileProperties(ccLocation);
     //   ccLocation = ccLocation + '?v=' + ret.LastModifiedTicks;
        ccLocation = ccLocation + '?v=' + ticks;
    } 
    return {
        "id": audioInfo.id.toString(), 
        "url": location,
        "waitTimeAfterVideo": audioInfo.configuration.millisecondsBeforeNextAudio,
        "type": audioInfo.configuration.fileType,
        "ccUrl": ccLocation
    };
}

const _isAudioAction = function(action) {
    return action.type === 'PlayAudio';
}

export const audio = {
    loadAudioTracks,
    getAudioTrack
}