import BenefitInfo from '@/components/Ikea/BenefitInfo';
import Video  from '@/components/Ikea/Video';
import { authGuard } from '@auth0/auth0-vue';

export const routes = [
  {
    path: '/',
    redirect: '/myalexcomp/video'
  },
  {
    path: '/myalexcomp/video',
    component: Video,
    meta: {
      title: 'Ikea'
    }
  },
  {
    path: '/myalexcomp/benefits',
    component: BenefitInfo,
    meta: {
      title: 'Ikea'
    },
    beforeEnter: authGuard
  }

];
