<template>
  <div v-if="accordion">
    <div v-for="widget in accordion.sections[0].details" :key="widget.id" >
      <bar-chart v-if="widget.mediaProperty.mediaType === 'BarChart'"
                  :legend="widget.mediaProperty.mediaConfiguration.legend"
                  :header="widget.mediaProperty.mediaConfiguration.header"
                  :headerTotal="widget.mediaProperty.mediaConfiguration.headerTotal"
                  :subheader="widget.mediaProperty.mediaConfiguration.subheader"
                  :xAxisLabel="widget.mediaProperty.mediaConfiguration.xAxisLabel"
                  :majorTicks="widget.mediaProperty.mediaConfiguration.majorTicks"
                  :minorTicks="widget.mediaProperty.mediaConfiguration.minorTicks"
                  :minValue="widget.mediaProperty.mediaConfiguration.minValue"
                  :maxValue="widget.mediaProperty.mediaConfiguration.maxValue"
                  :barData="widget.mediaProperty.mediaConfiguration.barData"
                  :finePrint="widget.mediaProperty.mediaConfiguration.finePrint"
                  :showMeasuringTape="widget.mediaProperty.mediaConfiguration.showMeasuringTape"></bar-chart>
      <checklist v-if="widget.mediaProperty.mediaType === 'Checklist'"
                  :header="widget.mediaProperty.mediaConfiguration.header"
                  :footer="widget.mediaProperty.mediaConfiguration.footer"
                  :items="widget.mediaProperty.mediaConfiguration.items"
                  :selectable="widget.mediaProperty.mediaConfiguration.selectable"
                  :width="widget.mediaProperty.mediaConfiguration.width"></checklist>
      <sample-language-box v-if="widget.mediaProperty.mediaType === 'SampleLanguage'"
                        :text="widget.mediaProperty.mediaConfiguration.text"
                        :width="widget.mediaProperty.mediaConfiguration.width"></sample-language-box>
      <div v-if="widget.mediaProperty.mediaType === 'ToolTip'">
        <span>{{widget.preText}}</span>
        <ToolTip :text="widget.mediaProperty.mediaConfiguration.text">{{widget.middleText}}</ToolTip>
        <span>{{widget.postText}}</span>
      </div>
    </div>
    <div>
      <input placeholder="Enter Age" v-model="age"><button v-on:click="getSurvey">Submit</button>
      <div>
        <div v-for="question in survey.surveyQuestions" :key="question.id">
          <div v-if="question.display">{{question.question.questionText}}</div>
          <div v-else>Question Blocked</div>
        </div>
      </div>
    </div>
  </div>
  <PieChart v-if="pieChart" :configuration="pieChart.configuration"></PieChart>
</template>

<script>
import BarChart from './shared/BarChart.vue';
import Checklist from "./shared/Checklist.vue"
import SampleLanguageBox from './shared/SampleLanguageBox.vue';
import ToolTip from './shared/ToolTip.vue';
import { data } from '../shared/data';
import jsonLogic from 'json-logic-js';
import PieChart from './shared/MediaProperties/PieChart.vue';

require('../assets/css/shared/checklist/checklist_variables_standard.scss');
require('../assets/css/shared/bar_chart/bar_chart_variables_standard.scss');
require('../assets/css/shared/sample_language/sample_language_variables_standard.scss');
require('../assets/css/shared/tooltip/tooltip_variables_standard.scss');

export default {
  components: { Checklist, SampleLanguageBox, BarChart, ToolTip, PieChart},
  data() {
      return {
        survey: {},
        age: 0,
        accordion: null,
        pieChart: null
      }
  },
  beforeMount: function() {
        let accessCode = (new URL(document.location)).searchParams.get("access_code");
        if(accessCode !== 'abc123') {
            window.location.href = '/CustomSolutionsTimeline';
        }
  },
  mounted: async function() {
  /*  this.accordion = await data.getAccordion('LOA_TEST', {});
    this.accordion.sections.forEach(sec => {
      sec.details.forEach(det => {
        if(det.mediaProperty.mediaType === 'ToolTip') {
          const toolTipStart = det.displayText.indexOf('<ToolTip');
          const middleTextStart = det.displayText.search(/(<ToolTip>)/) + 1;
          const middleTextEnd = det.displayText.indexOf('</ToolTip>');
          const toolTipEnd = det.displayText.indexOf('</ToolTip>') + 10;
          det.preText = det.displayText.substr(0, toolTipStart);
          det.postText = det.displayText.slice(toolTipEnd, det.displayText.length);
          det.middleText = det.displayText.slice(middleTextStart, middleTextEnd);
        }
      })
    })*/

    this.pieChart = await data.getMediaProperty(172);
    console.log(this.pieChart);
  },
  methods: {
    getSurvey: async function() {
      this.survey = await data.getSurvey(1);
      this.survey.surveyQuestions.forEach(sq => {
        sq.display = true;
        if(sq.conditionalJson) {
          let display = jsonLogic.apply(JSON.parse(sq.conditionalJson), {age: this.age});
          sq.display = display;
        }
      });
    }
  }
}
</script>
<style lang="scss" scoped src="@/assets/css/shared/checklist/checklist_styling.scss"></style>
<style lang="scss" scoped src="@/assets/css/shared/tooltip/tooltip_standard.scss"></style>