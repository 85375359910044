<template>
    <div class="header noprint base-margin">
        <div class="headerTitle">
          <div class="ikeaLogo"> 
              <img class="ikeaLogo" :src="logoSrc" />
          </div>
          <div class="alex-logo-container">
              <img class="alexLogo" src="/images/Demo/alex-logo.svg" />
              <h2>Compensation</h2>
          </div>
        </div>
        <div class="headerButtons">
          <div class="contactButton" @click="showContactModal = true">Contact</div>
          <FeedbackModal :questions="questions" thankYouMessage="Thanks for your feedback!" 
            :CommentsQuestionText="commentsQuestionText"
          openButtonText="Give Feedback"></FeedbackModal>
        </div>
        <div v-if="showContactModal">  
          <div class="modalBackdrop" @click="showContactModal = false"></div>
          <div class="contactModal">
              <h3>Prefer to talk to a human?</h3>
              <p>If you have questions about any of this info, please reach out to your Compensation and/or People & Culture Managers</p>
          </div>
        </div>
    </div>
</template>
<script> 
require('@/assets/css/client/Ikea/ikea_variables.scss');
import { data } from '@/shared';
import FeedbackModal from "@/components/shared/FeedbackModal";

export default {
  name: "Header",
  components: { FeedbackModal},
  props: {
    showForPage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showContactModal: false,
      showFeedbackModal: false,
      logoSrc: '',
      componentKey: 0,
      feedbackQuestions_benefits: [
        {   question:"How helpful has this site been in communicating information about your compensation at IKEA?",
            subject: "IKEA_BENEFIT_HELPFUL",
            sequence: 1,
            icons:[
                {score: 1, class: 'far fa-grimace', text:'Not at all helpful'},
                {score: 2, class: 'far fa-frown', text: 'Not very helpful'},
                {score: 3, class: 'far fa-meh', text: "Somewhat helpful"},
                {score: 4, class: 'far fa-smile', text: 'Very helpful'},
                {score: 5, class: 'far fa-grin-stars', text: 'Extremely helpful'}
            ]
        },
        {  question:"How much more informed do you feel about the compensation and career opportunities you receive as an IKEA employee?",
            sequence: 2,
            subject: "IKEA_BEN_MORE_INFRMD",
            icons:[
                {score: 1, class: 'far fa-grimace', text:'Not at all'},
                {score: 2, class: 'far fa-frown', text: 'Not much'},
                {score: 3, class: 'far fa-meh', text: "About the same"},
                {score: 4, class: 'far fa-smile', text: 'A bit more'},
                {score: 5, class: 'far fa-grin-stars', text: 'Much more informed'}
            ]
        }
        ],
      feedbackQuestions_video: [
        {   question:"How well did our video communicate the benefits of working with IKEA?",
            subject: "IKEA_VIDEO_HELPFUL",
            sequence: 1,
            icons:[
              {score: 1, class: 'fas fa-grimace fa-2x', text:'Totally missed the mark', show: false},
              {score: 2, class: 'fas fa-frown fa-2x', text: 'Not very well', show: false},
              {score: 3, class: 'fas fa-meh fa-2x', text: "I'm not sure", show: false},
              {score: 4, class: 'fas fa-smile fa-2x', text: 'Very well', show: false},
              {score: 5, class: 'fas fa-grin-stars fa-2x', text: 'Hit the mark', show: false}            
            ]
        },
        {  question:"Did our video make you more excited about joining IKEA?",
            sequence: 2,
            subject: "IKEA_VIDEO_EXCITED",
            icons:[
                {score: 1, class: 'far fa-grimace', text:'No, definitely not'},
                {score: 2, class: 'far fa-frown', text: 'No, not really'},
                {score: 3, class: 'far fa-meh', text: "Maybe"},
                {score: 4, class: 'far fa-smile', text: 'Yes, a little'},
                {score: 5, class: 'far fa-grin-stars', text: 'Yes, definitely'}
            ]
        }
        ],
    }
  },
  computed: {
    questions() {
      if(this.showForPage === 'video')
        return this.feedbackQuestions_video;
      else 
        return this.feedbackQuestions_benefits;
    },
    commentsQuestionText() {
      if(this.showForPage === 'video')
        return "What else would you like to know about working with us?";
      else 
        return "What additional details or information would you like to see added to this site?";

    }
  },
  async mounted() {    
    this.storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);

    // Get logo, but don't break if not there
    let logo;
    const logoMpId = await data.getClientLogoAssetID();
    if(logoMpId)
        logo = await data.getMediaProperty(logoMpId);
    if(logo)
        this.logoSrc = (!logo.isLocalPath ? this.storageBaseUrl : '') + logo.configuration.src;
  },
  methods: {
  }
}

</script>

<style scoped lang="scss" src="@/assets/css/client/Ikea/benefit_info.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/Ikea/feedback_modal.scss"></style>
