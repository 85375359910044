<template v-if="pieChart_local">
    <div style="display: flex; flex-direction: column; width: 100%; align-items: center;">
        <div class="title">{{ pieChart_local.title }}</div>
        <div style="display: flex;" :style="LegendPlacement">
            <div class="legendContainer" v-if="pieChart_local.showLegend">
                <div class="legend" :style="legendStyle">
                    <div class="legendRow" v-for="(slice, index) in pieChart_local.pieSlices.filter((obj) => obj.displayInLegend === true).sort((a, b) => b.percentage - a.percentage)" :key="index">
                        <div class="legendSquare" :style="{'background': slice.color}"></div>
                        <div class="legendCategory">{{slice.label}} - {{ slice.displayPercentage}}%</div>
                    </div>
                </div>
            </div>
            <div class="pieContainer" :style="ContainerStyle" >
                <div class="pieBackground"></div>
                <div v-for="(slice, index) in pieChart_local.pieSlices" class="pieSlice" :style="getPieStyle(slice)" :key="index"><div class="pie"></div></div>
            </div>	
        </div>
    </div>
</template>


<script>
export default {
    name: "PieChart",
    props: {
        configuration: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
    },
    async mounted() {
    },
    computed: {
        LegendPlacement() {
            if(this.configuration.legendProperties == null) return {};

            var direction = this.configuration.legendProperties.legendLocation === 'Left' ? 'row' : 'row-reverse';
            return  { 'flex-direction': direction };
        },
        legendStyle() {
            if(this.configuration.showLegend) 
                return {
                    '--legendBorderColor': this.configuration.legendProperties.borderColor,
                    '--legendBorder' : 'solid'
                };
            else 
                return {};

        },
        pieChart_local() {
            if(!this.configuration || !this.configuration.pieSlices) return null;
            var addSlices = [];
            var config = this.configuration;
            config.pieSlices.forEach(slice => {
                slice.displayPercentage = slice.percentage;
                slice.displayInLegend = true;
                if(slice.percentage > 50) {
                    var newSlice = JSON.parse(JSON.stringify(slice));  
                    newSlice.percentage = slice.percentage - 50;
                    newSlice.displayInLegend = false;
                    addSlices.push( newSlice );
                    slice.percentage = 50;
                }
            });

            if(addSlices.length > 0)
                config.pieSlices = [].concat(config.pieSlices, addSlices);
    
            config.pieSlices.sort((a,b) => {return a.sequence - b.sequence} );
            
            var accumulated_percentage = 0;
            config.pieSlices.forEach(slice => {
                slice.rotation = accumulated_percentage;
                accumulated_percentage += slice.percentage;
            });

            return config;
        },
        ContainerStyle() {

            var obj =  {
                    '--diameter': this.configuration.size + 'px',
                    '--radius': (this.configuration.size / 2) + 'px',
                }

            return obj;
        },

    },
    methods: {
        getPieStyle: function(slice) {
            return {
                '--color': slice.color,
                '--rotation':  (slice.rotation / 100 * 360 ).toFixed(2)  + "deg" ,
                '--size': (slice.percentage / 100 * 360 ).toFixed(2) + "deg"                
            }
        }
    }

}
</script>
<style scoped>
    .pieContainer {
        position: relative;
        height: var(--diameter);
        width: var(--diameter);
        margin: 0px 30px;
    }

    .pieBackground {
      position: absolute;
      width: var(--diameter);
      height: var(--diameter);
      border-radius: 100%;
      box-shadow: 0px 0px 8px rgba(0,0,0,0.5);
    } 
	
	.pieSlice .pie {
      background-color: var(--color);
      transform:rotate(var(--size));
      position: absolute;
      width: var(--diameter);
      height: var(--diameter);
      border-radius: 100%;
      clip-path: inset(0px var(--radius) 0px 0px);
    }
	
	.pieSlice {
        transform:rotate(var(--rotation));
        position: absolute;
        width: var(--diameter);
        height: var(--diameter);
        border-radius: 100%;
        clip-path: inset(0px 0px 0px var(--radius));
   }

    .legend {
        display: flex;
        flex-direction: column;
        border: var(--legendBorder) 1px var(--legendBorderColor);
        padding: 5px 5px 5px 10px;
        border-radius: 10px;
        flex: 1 1 200px;
        margin-bottom: auto;
    }

.legendContainer {
    text-align: left;
    margin: auto 0px;
    display: flex;
    
}
.legendRow {
    display: inline-block;
    padding-right: 20px;
}
.legendSquare {
    height: 10px;
    width: 10px;
    display: inline-block;
    margin-right: 5px;
}
.legendCategory {
    display: inline-block;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    text-transform: uppercase;
}

</style>