<script>
import { h } from 'vue';

export default {
    props: {
        cssClass: {
        type: String,
        default: null
        },
        displayText: {
            type: String,
            default: null
        }
    },
    render() {
        switch(this.cssClass) {
            case 'h1':
            case 'h2':
            case 'h3':
            case 'h4':
            case 'h5':
            case 'h6':
                return h(this.cssClass, { innerHTML: this.displayText });
            default:
                return h('div', { innerHTML: this.displayText, class: this.cssClass});
        }
    }
}
</script>