<template>
    <div class="front-page">
        <div class="main-container">
            <div class="text-container">
                <div class="title" v-html="title"></div>
                <div class="subtitle" v-html="subtitle"></div>
                <div class="paragraph" v-html="mainText"></div>
                <div class="button-container"><button class="action-button get-started-button" @click="onClickStart" >{{ startButtonText }}</button></div>
            </div>
            <div class="right-container desktop_only" >
                <img src="/images/MATTR/tree.svg" />
            </div>
        </div>
        <div class="fine-print-container">
            <div class="fine-print-text col-1" v-if="finePrintColumn1" v-html="finePrintColumn1"></div>
            <div class="fine-print-text col-2" v-if="finePrintColumn2" v-html="finePrintColumn2"></div>
        </div>
    </div>
</template>
<script>
import { useLoaStore } from "@/stores/loa";

export default {
  name: "FrontPage",
  components: { },
  props: {
    clientCode: String,
    onClickStart : {
      type: Function
    },
    finePrintColumn1: {
        type: String,
        required: true
    },
    finePrintColumn2: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true
    },
    subtitle: {
        type: String,
        required: true
    },
    mainText: {
        type: String,
        required: true
    },
    startButtonText: {
        type: String,
        required: true
    }
  },
  setup() {
    const piniaSurvey = useLoaStore();
    return {
        piniaSurvey
    };
  },
  async mounted() {
      this.$parent.piniaSurvey.setAttributes(null);
  }
};
</script>
<style scoped>
    .separator {
        margin-top: 20px;
        border-right: none;
        border-left: none;
        border-bottom: none;
        border-top: solid 2px #f5f5f5;
        width: 90%;
    }
</style>