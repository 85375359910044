<template>
  <div class="base-font">
    <div class="homePage">
      <Header showForPage="video" />
      <div class="videoPage">
        <h1>Hej!</h1>
        <p class="greeting">Nice to meet you, future co-worker!</p>
        <div>                 
          <video-player v-if="activeVideoConfig" :options="activeVideoConfig" 
            :mediaPropertyID="162" videoDescription="Ikea Video"></video-player>
        </div>
        <p>Learn more about why IKEA is a great place to work:  <a href="https://www.seeacareerwithus.com/working-here" target="_blank">Visit the IKEA Careers page</a></p>
      </div>
      <div class="footer_container noprint">
        <div class="footer">
            <div>© 2002-{{year}}</div><div>The Jellyvision Lab, Inc.</div><div>All rights reserved.</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script> 

require('@/assets/css/client/Ikea/ikea_variables.scss');
import Header from '@/components/Ikea/Header';
import VideoPlayer from '@/components/VideoPlayer.vue';
import { data } from '@/shared';

export default {
  name: "Video",
  components: { Header, VideoPlayer },
  data() {
      return {
          videoConfig: {
              height: 460,
              width: 640,
              controls: true,
              autoPlay: false,
              client: this.clientCode,
              poster: "/LOA/LOA-Video-Thumbnail.png",
              sources: [
                  {
                      src: "/LOA/LOA Animated Video.mp4",
                      type: "video/mp4"
                  }
              ],
              tracks: [
                  {
                      order: 1, 
                      label: "English (US)", 
                      kind: "captions",
                      srclang: "en",
                      src: "/LOA/LOA Animated Video.vtt"
                  }
              ],
              loggingActivities: [
                  {
                      percentage: 0,
                      logActivityTypeCode: "VIDEO_STARTED"
                  },
                  {
                      percentage: 25,
                      logActivityTypeCode: "VIDEO_25%"
                  },
                  {
                      percentage: 50,
                      logActivityTypeCode: "VIDEO_50%"
                  },
                  {
                      percentage: 75,
                      logActivityTypeCode: "VIDEO_75%"
                  },
                  {
                      percentage: 100,
                      logActivityTypeCode: "VIDEO_FINISHED"
                  }
              ]
          },
          activeVideoConfig: null
      }
  },
  computed: {
    year() { 
      return new Date().getFullYear();
    }
  },
  async mounted() {
      var logObject = {
            url: window.location.href
        };
        
      data.postActivityLog({          
        clientCode: 'IKEA',
        logActivityTypeCode: 'LANDING_PAGE',
        data: JSON.stringify(logObject),
      });


    const storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);
    var video = await data.getMediaProperty(412);
    this.videoConfig = video.configuration;
    
    if(this.videoConfig.poster)
        this.videoConfig.poster = storageBaseUrl + this.videoConfig.poster;

    this.videoConfig.sources.forEach(sc => {
        sc.src = storageBaseUrl + sc.src;
    });
    this.videoConfig.tracks.forEach(tk => {
        tk.src = storageBaseUrl + tk.src;
    });
    this.activeVideoConfig = this.videoConfig;
  }

}

</script>

<style scoped lang="scss" src="@/assets/css/client/Ikea/benefit_info.scss"></style>
<style scoped lang="scss" src="@/assets/css/shared/breadcrumb/breadcrumb_standard.scss"></style>

