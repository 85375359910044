<template>
    <div v-if="survey">
        <div class="survey-top-bar">
            <div class="section-title">
                <h5>{{sectionTitle}}</h5>
            </div>
            <div class="navigation-container">
                <Breadcrumb v-if="areBreadcrumbsEnabled" :pages="breadcrumbPages"
                    :activePageNumber="activeBreadcrumbPageNumber"
                    separator=">"
                    :showFuturePages="true"
                    :onBreadcrumbClick="breadcrumbPageClick"
                    :key="breadcrumbKey">
                </Breadcrumb>
                <div class="back-button" @click="goBackToPreviousPage()" v-if="(activePageIndex > 0 || onGoBack) && buttonLocation !== 'bottom'">
                    <i class="fas fa-chevron-left"></i> {{goBackButtonText}}
                </div>
            </div>
        </div>
        <div v-if="survey" class="survey-container">
            <div class="page-container" :class="'page' + activePageIndex">
                <SurveyPage :page="survey.pages[activePageIndex]" :showButtonsWithQuestions="showButtonsWithQuestions"
                            :onVideoFinish="onVideoFinish" :onQuestionAnswered="onQuestionAnswered_local" :beforePageSubmit="beforePageSubmit"
                            :onGoToPreviousPage="goBackToPreviousPage" :onValidateAnswer="onValidateAnswer"
                            :onPageSubmit="onPageSubmit" :language="language" :continueButtonEnabled="continueButtonEnabled" :showContinueButton="showContinueButton"
                            :storageBaseUrl="storageBaseUrl" :skipPage="skipPage" :key="surveyPageKey"></SurveyPage>
            </div>
            <ProgressBar v-if="enableProgressBar" 
                    :totalPages="ProgressBar_TotalPages" :currentPage="ProgressBar_PageNumber"
                        :textFormat="survey.configuration.progressBar.textFormat" :textLocation="survey.configuration.progressBar.textLocation"></ProgressBar>
        </div>
        <div v-if="buttonLocation === 'bottom'"> 
            <Navigation ref="navigation" :audioTracks="pageAudioEvents" :playTrackId="currentTrackId" :showGoToPreviousPage="activePageIndex > 0 || onGoBack"
                :showGoToNextPage="showNextButtonInFooter" :onGoToNextPage="onEndOfSurvey"
                :onGoToPreviousPage="goBackToPreviousPage" :showAudioPlayer="true"></Navigation>
        </div>
        <SingleButtonModal :buttonText="popupModalButtonText" :displayText="popupModalContent" :onButtonClicked="onModalButtonClicked"></SingleButtonModal>
        <template v-if="showProgressIcon">
            <img src="/images/loading-spinner-fish.gif" style="width: 10%; max-width:100px; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto;" />
        </template>
    </div>
</template>

<script>
import { data } from '@/shared/data';
import jsonLogic from 'json-logic-js';
import SurveyPage from '@/components/shared/Survey/SurveyPage';
import Breadcrumb from '@/components/shared/Breadcrumb';
import Navigation from '@/components/shared/Navigation';
import { useLoaStore } from "@/stores/loa";
import { evaluate } from 'mathjs';
import { audio } from '@/shared/audio';
import SingleButtonModal from '@/components/shared/Survey/SingleButtonModal';
import ProgressBar from '@/components/shared/Survey/ProgressBar';

export default {
    name: 'Survey3',
    components: { SurveyPage, Breadcrumb, Navigation, SingleButtonModal, ProgressBar },
    props: {
        code: {
            type: String,
            required: true
        },
        additionalMergeSurveyCodes: Array,
        onQuestionAnswered: Function,
        onValidateAnswer: {
            type: Function
        },
        sectionTitle: String,
        onSurveyLoad: Function,
        onPageDisplay: Function,
        onEndOfSurvey: Function,
        language: {
            type: Object,
            default() { return { name: 'English', code: 'en'} }
        },
        showNextButtonInFooter: {
            type: Boolean,
            default: false
        },
        buttonLocation: {
            type: String,
            default: 'top'
        },
        goBackButtonText: String,
        onGoBack: {
            type: Function
        },
        //This will mean that the progress will designnate the page specified as page 1
        startProgressBarPagingAt: {
            type: Number
        },
        totalPages: {
            type: Number
        },
        gotoPage: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            survey: null,
            activePageIndex: -1,
            newPageIndex: -1,
            isDebug: false,
            activeBreadcrumbPageNumber: 1,
            areBreadcrumbsEnabled: false,
            breadcrumbKey: 0,
            breadcrumbPages: [],
            surveyAnswers: {},
            languages: [],
            continueButtonEnabled: true,
            storageBaseUrl: null,
            audioTracks: [],
            currentTrackId: '',
            popupModalAction: null,
            showProgressIcon: false,
            surveyPageKey: 0, 
            isMovingForward: true,
            caclAttributes: null
        }
    },
    setup() {
      const piniaSurvey = useLoaStore();
      return {
        piniaSurvey
      };
    },
    computed: {
        enableProgressBar() {
            return this.survey.configuration.progressBar && this.survey.configuration.progressBar.enable && this.ProgressBar_PageNumber >= 0;
        },
        showButtonsWithQuestions() {
            return this.survey.configuration.submitButtonLocation === 'WithQuestions' && !this.showNextButtonInFooter;
        },
        showContinueButton() {
            return !this.showButtonsWithQuestions && !this.showNextButtonInFooter;
        },
        calculationActions() {
            return (this.survey && this.survey.actions) ? this.survey.actions.filter(act => {return act.type === 'SetVariable'}) : [];
        },
        pageAudioEvents() {
            var attributes = this.piniaSurvey.getAttributes;
            if(this.activePageIndex >= 0 && this.audioTracks.length > 0) {
                var actions = this.survey.actions.filter((asset) => { 
                                return this.survey.pages[this.activePageIndex].id === asset.configuration.surveyPageID && 
                                    this.isAudioAction(asset) &&
                                    jsonLogic.apply(JSON.parse(asset.conditionalJson), attributes)
                                }).sort((a,b) => a.sequence - b.sequence);

                var lst = [];
                actions.forEach((action) => {
                    var foundInfo = this.audioTracks.find(search => search.id === action.configuration.mediaPropertyID.toString());
                    if(foundInfo) {
                        lst.push(foundInfo);
                    }
                });

                return lst;
            }   

            return [];
        },
        popupModalButtonText() {
            if(this.popupModalAction != null)
                return this.popupModalAction.configuration.buttonText;
            else 
                return '';
        },
        popupModalContent() {
            if(this.popupModalAction != null)
                return this.popupModalAction.configuration.windowText;
            else 
                return '';
        },
        allowBreadcrumb() {
            if(this.survey.configuration &&
                this.survey.configuration.breadcrumb &&
                this.survey.configuration.breadcrumb.enable)
                return true;
            else
                return false;
        },
        ProgressBar_PageNumber() {
            if(this.activePageIndex < 0) return -1;
            if(this.startProgressBarPagingAt) {
                var pageNumber  = this.survey.pages[this.activePageIndex].pageNumber
                if(this.startProgressBarPagingAt > pageNumber)
                    return -1;
                else 
                    return pageNumber - this.startProgressBarPagingAt + 1;
            }
            else 
                return pageNumber;
        },
        ProgressBar_TotalPages() {
            if(this.activePageIndex < 0) return -1;
            return this.totalPages ? this.totalPages : 
                (this.survey.pages.length - (this.survey.pages[this.activePageIndex].pageNumber ? this.survey.pages[this.activePageIndex].pageNumber : 0));
        }
    },
    async mounted() {
        this.surveyAnswers = {};
        let uri = window.location.search.substring(1); 
        let params = new URLSearchParams(uri);
        this.isDebug = (params.get("debug"));
        this.storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);

        await this.getSurvey();

        this.areBreadcrumbsEnabled = this.allowBreadcrumb && this.survey.configuration.breadcrumb.enable === true;

        for(let i = 0; i < this.survey.pages.length; i++) {
            for(let j = 0; j < this.survey.pages[i].questions.length; j++) {
                var question  = this.survey.pages[i].questions[j];
                if(question.questionInfo.questionType === 'MediaDisplay' && question.questionInfo.mediaPropertyInfo.mediaType === 'Image'){
                    var configuration = question.questionInfo.mediaPropertyInfo.configuration;
                    if(configuration.isLocalPath === false) {
                        var location = this.storageBaseUrl + (configuration.src[0] !== '/' ? '/' : '') + configuration.src; 
                        var ret = await data.getMediaFileProperties(location);
                        configuration.src = location + '?v=' + ret.LastModifiedTicks;
                    }   
                }
            }
        }

        if(!this.areBreadcrumbsEnabled) return;

        if(this.breadcrumbPages) 
            this.breadcrumbPages.pop();
        
        if(this.definedBreadCrumbs)
            this.breadcrumbPages = this.definedBreadCrumbs.slice();

        for(let i = 0; i < this.survey.pages.length; i++) {
            if(!this.survey.pages[i].configuration.breadcrumbConfiguration) continue;
            const bcpg = this.breadcrumbPages.find(bc => bc.number === this.survey.pages[i].configuration.breadcrumbConfiguration.number);
            if(!bcpg)
                this.breadcrumbPages.push(this.survey.pages[i].configuration.breadcrumbConfiguration);
        }

        this.activeBreadcrumbPageNumber = 2;
        this.breadcrumbKey++;
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        breadcrumbPageClick: async function(breadcrumbPage) {
            const newPageIndex = this.survey.pages.findIndex(pg => pg.configuration.breadcrumbConfiguration.number === breadcrumbPage.number );
            if(newPageIndex >= 0) {
                for(let i = newPageIndex + 1; i < this.survey.pages.length; i++) {
                    await this.removePageValuesFromStore(i);
                }
                await this.displayPage(newPageIndex);
            }
            else if(this.onBreadcrumbClick)
                await this.onBreadcrumbClick(breadcrumbPage);
        
        },
        getSurvey: async function() {
            this.storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);

            this.survey = await data.getSurveyByCode(this.code, this.additionalMergeSurveyCodes);
            this.survey.pages.sort((a, b) => {
                return a.pageNumber - b.pageNumber;
            });

            this.survey.actions.sort((a,b) => {
                return a.sequence - b.sequence;
            });

            //call async function say that everything else can continue while audio files are loaded.
            this.loadAudioTracks();

            if(this.isDebug)
                console.log(this.survey);            

            if(this.onSurveyLoad)
                await this.onSurveyLoad(this.survey);

            if(!this.gotoPage)
                await this.setActivePage(true);
            else
                await this.displayPage(this.gotoPage);
        },
        async loadAudioTracks() {
            if(this.survey.actions.length)
                this.audioTracks = await audio.loadAudioTracks(this.survey.actions);
        },
        setActivePage: async function(isMovingForward) {
            if(!this.survey.pages) return;

            if(this.activePageIndex < 0){
                await this.displayPage(0);
            }
            else {
                if(isMovingForward) {
                    this.isMovingForward = true;
                    await this.displayPage(this.activePageIndex + 1);
                } else {
                    this.isMovingForward = false;
                    if(this.activePageIndex > 0) {
                        await this.removePageValuesFromStore(this.activePageIndex);
                        await this.displayPage(this.activePageIndex - 1);
                    }
                    else if(this.onGoBack) 
                        await this.onGoBack();
                }
            }
        },
        async displayPage(newPageIndex) {
            if(!this.survey.pages) return;

            this.newPageIndex = newPageIndex;

            if(newPageIndex >= this.survey.pages.length) {
                this.activePageIndex = -1;
                this.processEndofSurvey();
                return;
            }
            
            var page = this.survey.pages[newPageIndex];

            if(this.isDebug)
                console.log(page);

            //display any pages that have 0 questions defined.  Only skip pages that have questions but no visible questions.
            if(page.questions.length == 0){
                this.activePageIndex = newPageIndex;
                return;
            } 

            if(page.questions.length > 1) {
                page.questions.sort((a,b) => {
                    return a.sequence - b.sequence;
                });
            }

            this.scrollToTop();

            if(this.areBreadcrumbsEnabled) {
                this.activeBreadcrumbPageNumber = page.configuration.breadcrumbConfiguration.number ?? 0;
                this.breadcrumbKey++;
            }

            this.activePageIndex = newPageIndex;

            if(this.onPageDisplay)
                await this.onPageDisplay(this.survey, this.activePageIndex);

            this.surveyPageKey++;
        },
        removePageValuesFromStore : async function(pageIndex) {
            var attributes = this.piniaSurvey.getAttributes;
            var page = this.survey.pages[pageIndex];

            for(var j = 0; j < page.questions.filter(search => search.visible).length; j++) {
                var question = page.questions[j];
                if(question.questionInfo.attribute && question.questionInfo.attribute.code != null) {
                    attributes[question.questionInfo.attribute.code] = null;
                    this.surveyAnswers[question.questionInfo.attribute.code] = null;

                    if(attributes[question.questionInfo.attribute.code + '_VALUE'] != null) {
                        attributes[question.questionInfo.attribute.code + '_VALUE'] = null;
                        this.surveyAnswers[question.questionInfo.attribute.code + '_VALUE'] = null;    
                    }

                    if(attributes[question.questionInfo.attribute.code + '_LABEL'] != null) {
                        attributes[question.questionInfo.attribute.code + '_LABEL'] = null;
                        this.surveyAnswers[question.questionInfo.attribute.code + '_LABEL'] = null;    
                    }
                }
            }

            this.piniaSurvey.setAttributes(attributes);

        },
        onQuestionAnswered_local: async function() {
            if(!this.onQuestionAnswered) return;
            this.showProgressIcon = true;
            await this.onQuestionAnswered();
            this.showProgressIcon = false;
        },
        onVideoFinish: function() {
            this.continueButtonEnabled = true;
            this.survey.pages[this.activePageIndex].finishedVideo = true;
        },
        beforePageSubmit: function() {
            let attributes = this.piniaSurvey.getAttributes;

            for(var i = 0; i < this.survey.actions.length; i++) {
                var action = this.survey.actions[i];

                if(action.type === 'PopupModalWindow' && jsonLogic.apply(JSON.parse(action.conditionalJson), attributes)) {
                    this.popupModalAction = action;
                    return false;
                }
            }

            return true;
        },
        onPageSubmit: async function() {
            this.calcAttributes = this.piniaSurvey.getAttributes;
            for(let i = 0; i < this.calculationActions.length; i++) {

                switch(this.calculationActions[i].subType) {
                    case 'DateCalculation': {
                        const value1 = this.processDateValue(this.calculationActions[i].configuration.operation.value1);
                        const value2 = this.calculationActions[i].configuration.operation.operator === 'FirstOfMonth' ? '' : this.processDateValue(this.calculationActions[i].configuration.operation.value2);
                        if(!value1 || (!value2 && this.calculationActions[i].configuration.operation.operator !== 'FirstOfMonth'))
                            break;
                        switch(this.calculationActions[i].configuration.operation.operator) {
                            case 'FirstOfMonth':
                                this.setAttribute(this.calculationActions[i].attribute, `${value1.getMonth() + 1}-01-${value1.getFullYear()}`);
                                break;
                            case 'DateDiffDays': {
                                let diff = value2 - value1 + ((value2.getTimezoneOffset() - value1.getTimezoneOffset()) * 60 * 1000);
                                const oneDay = 1000 * 60 * 60 * 24;
                                const days = Math.floor(diff / oneDay);
                                this.setAttribute(this.calculationActions[i].attribute, days <= 0 ? 0 : days);
                                break;
                            }
                            case 'DateDiffMonths': {
                                let months = (value2.getFullYear() - value1.getFullYear()) * 12;
                                months += value2.getMonth();
                                months -= value1.getMonth();
                                if(value2.getDate() < value1.getDate())
                                    months--;
                                this.setAttribute(this.calculationActions[i].attribute, months <= 0 ? 0 : months);
                                break;
                            }
                        }
                        break;
                    }
                    case 'NumberCalculation': {
                        let value1 = Object.hasOwn(this.calcAttributes, this.calculationActions[i].configuration.operation.value1) ? this.calcAttributes[this.calculationActions[i].configuration.operation.value1] : this.calculationActions[i].configuration.operation.value1;
                        let value2 = Object.hasOwn(this.calcAttributes, this.calculationActions[i].configuration.operation.value2) ? this.calcAttributes[this.calculationActions[i].configuration.operation.value2] : this.calculationActions[i].configuration.operation.value2;
                        if(value1 == null || value2 == null || isNaN(value1) || isNaN(value2))
                            break;
                        let operator;
                        switch(this.calculationActions[i].configuration.operation.operator) {
                            case 'Add':
                                operator = '+';
                                break;
                            case 'Subtract':
                                operator = '-';
                                break;
                            case 'Multiply':
                                operator = '*';
                                break;
                            case 'Divide':
                                operator = '/';
                                break;
                        }
                        this.setAttribute(this.calculationActions[i].attribute, evaluate(value1.toString().concat(operator, value2.toString())));
                        break;
                    }
                    case 'LogicalCalculation':
                        this.calculationActions[i].configuration.calculations.sort((a,b) => {return a.sequence - b.sequence;});
                        this.calculationActions[i].configuration.calculations.some(calc => {          
                            let logicalValue = Object.hasOwn(this.calcAttributes, calc.value) ? this.calcAttributes[calc.value] : calc.value;
                      

                            if(calc.jsonExpression === 'ELSE') {
                                this.setAttribute(this.calculationActions[i].attribute, logicalValue);
                                 // ends iteration
                                return true;
                            }
                            if(jsonLogic.apply(JSON.parse(calc.jsonExpression), this.calcAttributes)) {

                                this.setAttribute(this.calculationActions[i].attribute, logicalValue);
                                // ends iteration
                                return true;
                            }
                        });
                        break;
                    case 'SetConstant':
                        this.setAttribute(this.calculationActions[i].attribute, this.calculationActions[i].configuration.value);
                        break;
                }
            }

            this.piniaSurvey.setAttributes(this.calcAttributes);

            if(this.isDebug)
                console.log(this.calcAttributes);

            if(!(await this.processSurveyAction())) 
                await this.setActivePage(true);
        },
        setAttribute: function(attribute, value) {
            if(attribute.useAsReplacementString) {
                let replacementStrings = this.piniaSurvey.getReplacementStrings;
                replacementStrings['%' + attribute.code + '%'] = value;
                this.piniaSurvey.setReplacementStrings(replacementStrings);
            } else {
                //this ensures any calculated values are saved as a number, rather than string
                if(value != null && !isNaN(value))
                    this.calcAttributes[attribute.code] = Number(value);
                else 
                    this.calcAttributes[attribute.code] = value;
            }
        },
        processSurveyAction: function() {
            //don't process actions if there's only one item and it's the default
            if(this.survey.actions.length === 1 && this.survey.actions[0].configuration.isDefault === true )  return false;

            if(this.survey.actions && this.survey.actions.length) {
                for (let i = 0; i < this.survey.actions.length; i++) {
                    if(this.survey.actions[i].type === 'ShortCirct' 
                            && this.survey.actions[i].conditionalJson !== '{}') {
                        if(jsonLogic.apply(JSON.parse(this.survey.actions[i].conditionalJson), this.piniaSurvey.getAttributes)) {
                            this.setSurveyAction(this.survey.actions[i]);
                            return true;
                        }
                    }
                }
            } 

            return false;
        },
        setSurveyAction: function(action) {
            var logObject = {
                survey_action_key: action.id,
                action_type: action.type,
                action_sub_type: action.subType
            }

            if(action.subType == 'SimpleInformationPage') { 
                logObject.description = action.configuration.description === '' ? 
                            action.configuration.displayText : action.configuration.description;
            }
            else if(action.subType == 'AccordionInformationPage') { 
                logObject.accordion = action.configuration.accordionCode;
            } 
        
            data.postActivityLog({
                    logActivityTypeCode: 'SURVEY_ACTION',
                    sourceType: 'IPAddress',
                    data: JSON.stringify(logObject)
                });

            if(this.onEndOfSurvey)
                this.onEndOfSurvey(action);
        },
        goBackToPreviousPage: function() {
            this.setActivePage(false);
        },
        onModalButtonClicked() {
            if(this.popupModalAction.type === 'PopupModalWindow') {
                this.piniaSurvey.setAttributes(null);
                this.surveyAnswers = {};
                this.popupModalConfiguration = {};
                this.displayPage(0);
            }
            else
                this.$toast.error('Survey action could not be processed.')
        },
        async skipPage() {
            await this.setActivePage(this.isMovingForward);
        },
        isAudioAction(action) {
            return action.type === 'PlayAudio';
        },
        processDateValue: function(dateStr) {
            if(dateStr === 'NOW')
                return new Date();
            else if(!(dateStr instanceof Date)) { 
                const attributes = this.piniaSurvey.getAttributes;
                const attrVal = attributes[dateStr];
                if(attrVal)
                    return new Date(attrVal); 
                else 
                    return null;
            }
            else
                return new Date(dateStr);
        },
        processEndofSurvey: function() {
            for (let i = 0; i < this.survey.actions.length; i++) {
                if(this.survey.actions[i].configuration.isDefault === true) { 
                    this.setSurveyAction(this.survey.actions[i]);
                    return true;
                }
            }
            
            if(this.onEndOfSurvey) {
                this.onEndOfSurvey();
                return true;
            }

            try {
                throw new Error("Reached end of survey without a specific place to go:" + JSON.stringify(this.piniaSurvey.getAttributes));
            }
            catch(error) {
                data.postErrorLog(error);
            }           
        },
    },
    watch: {
        pageAudioEvents(newArray, oldArray) {
            if(this.pageAudioEvents.length) {
                const newTrack = newArray.find(newTrack => !oldArray.find(oldTrack => oldTrack.id.toString() === newTrack.id.toString()));
                this.currentTrackId = newTrack ? newTrack.id.toString() : '';
            }
        }
    }
}
</script>
