<template>
    <div style="width:100%; display:flex; justify-content: center">
        <VideoPlayer v-if="video" :options="video.configuration" :mediaPropertyID="activeVideo.mediaPropertyID"
                    :videoDescription="activeVideo.videoDescription"></VideoPlayer>
    </div>
</template>

<script>
import VideoPlayer from '../VideoPlayer.vue';
import { data } from '../../shared';

export default {
    name: 'RxPriceShop',
    components: { VideoPlayer },
    data() {
        return {
            video: null,
            videoData: [
                {
                    name: 'RxPriceShop',
                    mediaPropertyID: 470
                },
                {
                    name: 'HSAvsFSA',
                    mediaPropertyID: 471
                },
                {
                    name: 'HSABasics',
                    mediaPropertyID: 472
                },
                {
                    name: 'ComparisonShopping',
                    mediaPropertyID: 473
                },
                {
                    name: 'NewPlan',
                    mediaPropertyID: 474
                },
            ]
        }
    },
    computed: {
        activeVideo() {
            return this.videoData.find(dat => dat.name.toLowerCase() === this.$route.params["videoName"].toLowerCase());
        }
    },
    async mounted() {
        if(this.$route.params == null || this.$route.params["videoName"] == null)
        this.$router.push({
            path: '/videos/RxPriceShop',
            query: this.$route.query
        }).then(() => { this.$router.go() });

        const storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);
        let video_local = await data.getMediaProperty(this.activeVideo.mediaPropertyID);
        video_local.configuration.sources.forEach(source => source.src = storageBaseUrl + source.src);
        video_local.configuration.tracks.forEach(trk => trk.src = storageBaseUrl + trk.src);
        video_local.configuration.poster = storageBaseUrl + video_local.configuration.poster;

        this.video = video_local;
    }
}
</script>
