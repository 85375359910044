<template>
    <span class="tooltip-container">
        <span class="tooltip-slot-container" ref="tooltipContainer" @mouseover="showTooltip" @mouseleave="hideTooltip">
            <slot name="hoverText" />
            <slot name="hoverIcon"><i class="fas fa-question-circle tooltip-icon"></i></slot>
        </span>
        <div class="tooltip-content-container" ref="tooltip" style="display:none;">
          <div class="tooltip-content">
            <slot name="content" />
          </div>
        </div>
    </span>
</template>
<script>
export default {
  name: 'ToolTip',
  //Usage, <Tooltip position="top" alignment="middle" :useSmartRepositioning="true">
  //            <template #content>This is tooltip popup content</template>
  //            <template #hoverText>This is the text that will trigger the popup</template>
  //            <template #hoverIcon>This overrides the icon to display after the hover text</template>
  props: { 
    position: {
        type: String,
        default: 'right',
        validator: function(value) {
        // List of allowed values
        const allowedValues = ['top', 'bottom', 'left', 'right'];
        // Check if the value is one of the allowed values
        return allowedValues.includes(value);
      }
    },
    alignment: {
      type: String,
      default: 'middle',
      validator: function(value) {
        // List of allowed values
        const allowedValues = ['start', 'middle', 'end'];
        // Check if the value is one of the allowed values
        return allowedValues.includes(value);
      }
    },
    useSmartRepositioning: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      hover: false
    };
  },
  methods: {
    showTooltip: async function() {
      if(this.hover == true) return;

      this.hover = true;
      
      await this.repositionTooltip();
      this.$refs.tooltip.style.display = 'block';
    },
    hideTooltip: async function() {
        this.hover = false;
        const tooltip = this.$refs.tooltip;
        tooltip.style.display = 'none';
    },
    repositionTooltip: async function() {
      if(!this.hover) return;

      const tooltipContainer = this.$refs.tooltipContainer;
      const tooltip = this.$refs.tooltip;

      tooltip.style.visibility = 'hidden';
      tooltip.style.display = 'block';
      tooltip.style.left = "-9999px";
      
      const rect = tooltipContainer.getBoundingClientRect();
      const tooltipRect = tooltip.getBoundingClientRect();
      var absolutePosition = await this.calculatePosition();

      var top = absolutePosition.top - tooltipRect.height + window.scrollY;
      var v_middle = ((rect.top + rect.bottom) / 2)  - (tooltipRect.height / 2) + window.scrollY;
      var bottom = absolutePosition.top + rect.height + window.scrollY;

      var left = absolutePosition.left - tooltipRect.width + window.scrollX;
      var h_middle= absolutePosition.left + (rect.width/2) - (tooltipRect.width /2)  + window.scrollX;
      var right = absolutePosition.left + rect.width +  window.scrollY + window.scrollX;
      var alignments = ['start', 'middle', 'end'];

      var positions= {};
      positions["top-middle"] = {
        top: top,
        left: h_middle,
        alternatePositions: ['top', 'bottom', 'right', 'left']
      };

      positions["top-start"] = {
        top: top,
        left: left,
        alternatePositions: ['top', 'bottom', 'right', 'left']
      };

      positions["top-end"] = {
        top: top,
        left: right,
        alternatePositions: ['top', 'bottom', 'right', 'left']
      };

      positions["bottom-middle"] = {
        top: bottom,
        left: h_middle,
        alternatePositions: ['top', 'bottom', 'right', 'left']
      };

      positions["bottom-start"] = {
        top: bottom,
        left: left,
        alternatePositions: ['top', 'bottom', 'right', 'left']
      };

      positions["bottom-end"] = {
        top: bottom,
        left: right , 
        alternatePositions: ['top', 'bottom', 'right', 'left']
      };

      positions["left-middle"] = {
        top: v_middle, 
        left: left ,
        alternatePositions: ['left', 'right', 'top', 'bottom']
      };


      positions["left-start"] = {
        top: top, 
        left: left ,
        alternatePositions: ['left', 'right', 'top', 'bottom']
      };


      positions["left-end"] = {
        top: bottom, 
        left: left, 
        alternatePositions: ['left', 'right', 'top', 'bottom']
      };

      positions["right-middle"] = {
        top: v_middle,
        left: right, 
        alternatePositions: ['right', 'left', 'top', 'bottom']
      };

      positions["right-start"] = {
        top: top,
        left: right,
        alternatePositions: ['right', 'left', 'top', 'bottom']
      };

      positions["right-end"] = {
        top: bottom,
        left: right,
        alternatePositions: ['right', 'left', 'top', 'bottom']
      };

      Object.keys(positions).forEach(key => {
        positions[key].right = positions[key].left + tooltipRect.width;
        positions[key].bottom = positions[key].top + tooltipRect.height;
        positions[key].isValidTop = positions[key].top > 0;
        positions[key].isValidBottom = positions[key].bottom < window.innerHeight;
        positions[key].isValidLeft = positions[key].left > 0;
        positions[key].isValidRight = positions[key].right < window.innerWidth;
        positions[key].isValid = positions[key].isValidTop && positions[key].isValidLeft && positions[key].isValidRight && positions[key].isValidBottom;
      });

      var pref_pos_and_align = this.position + '-' + this.alignment;
      var preferredPosition = positions[pref_pos_and_align];
      if(preferredPosition.isValid === false && this.useSmartRepositioning === true) {
        for(const pos of preferredPosition.alternatePositions) {
            for(var i = 0; i < alignments.length; i++)
            {
              var position = pos + '-' + alignments[i];
              if(position === pref_pos_and_align || positions[position] == null) continue;

              if(positions[position].isValid) {
                    preferredPosition = positions[position];
                    break;
              }
            }         
        }
      }

      tooltip.style.display = 'none';
      tooltip.style.visibility = 'visible';
      tooltip.style.left = preferredPosition.left + 'px';
      tooltip.style.top = preferredPosition.top + 'px';
    },
    calculatePosition: async function() {
        const child = this.$refs.tooltipContainer;
        let totalOffsetTop = 0; //child.offsetTop; 
        let totalOffsetLeft = 0; //child.offsetLeft;
        
        let parent = child.offsetParent;
        
        // Traverse up the DOM tree
        while (parent) {
            totalOffsetTop += parent.offsetTop; 
            totalOffsetLeft += parent.offsetLeft;

            // Move up to the next parent element
            parent = parent.offsetParent;
        }

        parent = child.parentElement; 
        while(parent && parent !== document.body) {
            totalOffsetTop -= parent.scrollTop;
            totalOffsetLeft -= parent.scrollLeft;
            parent = parent.parentElement;
        }

        return {
            top: totalOffsetTop,
            left: totalOffsetLeft
        };
    }
  },
};
</script>
<style scoped>
  .tooltip-container { 
    position: relative;
    display: inline-block;
  }

  .tooltip-slot-container {
      display:flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
  }

  .tooltip-icon {
      padding-left: .2em;
      font-size: 1em;
  }

 .tooltip-content-container {
      position: fixed;
      z-index: 100;
      box-sizing: border-box;
 }
</style>
