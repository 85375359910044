<template>
    <div class="tbb-container" v-if="question.answerType === 'TextboxAndButton'">
        <div class="tbb-input-container">
            <input type="number" :min="minValue" v-model="textboxValue" @blur="textboxChanged" 
                @keydown.-="checkToAllowMinus" class="tbb-input" :placeholder="placeholder" />
            <label v-html="getTextForLanguage(question.answers[0].answerTextJson)" class="tbb-label"></label>
        </div>
        <div class="questionText tbb-separatorText">OR</div>
        <div class="answerButton tbb-button" @click="buttonClicked"
                :class="[buttonSelected == true ? 'selected' : '']" v-html="getTextForLanguage(question.answers[1].answerTextJson)" ></div>
    </div>
</template>
<script>
import { translation } from '@/shared/translation';
export default {
    name: "TextboxButton",
    props: {
        question: {
            type: Object
        },
        onValueChanged: {
            type: Function
        },
        initialValue: {
            type: String,
            default: ''
        },
        minValue: {
            type: Number,
            default: 0
        },
        placeholder: String,
        language: {
            type: String,
            default: "English"
        }
    },
    data() {
        return {  
            textboxValue: '',
            buttonSelected: false,
            attribute: {
                code: '',
                value: ''
            }
        }
    },
    computed: {
        returnValue() {
            if(this.attribute)
                return this.buttonSelected ? this.attribute.value : this.textboxValue;
            else 
                return '';
        }
    },
    mounted: async function() {
        if(this.question.questionType === "Combination" &&
            this.question.answerType === "TextboxAndButton")
        {
            if(this.question.answers.length === 0) return;

            if(this.question.attribute) {
                this.attribute = {
                    code: this.question.attribute.code,
                    value: this.question.answers[1].answerValue
                };

                let attributes = this.$parent.piniaSurvey.getAttributes;
                if(attributes[this.question.attribute.code]) {
                    if(isNaN(attributes[this.question.attribute.code])) {
                        this.buttonSelected = true;
                        this.textboxValue = '';
                    } else {
                        this.buttonSelected = false;
                        this.textboxValue = attributes[this.question.attribute.code];
                    }
                }
            }
            
            if(this.initialValue === this.attribute.value) {
                this.buttonSelected = true;
                this.textboxValue = '';
            }
            else {
                this.buttonSelected = false;
                this.textboxValue = this.initialValue;
            }
        }
    },
    methods: {
        checkToAllowMinus: async function(event) {
            if(this.minValue >= 0)
                event.preventDefault();
        },
        buttonClicked: async function() {
            this.buttonSelected = true;
            this.textboxValue = '';
            this.valueChanged();
        },
        textboxChanged: async function() {
            this.buttonSelected = false;
            this.valueChanged();
        },
        valueChanged: async function() {
            if(this.onValueChanged) {
                this.onValueChanged(this.attribute.code, this.returnValue);
            }
    
        },
        getTextForLanguage: function(jsonField) {
            return translation.getTextForLanguage(jsonField, this.language);
        }
    }
}

</script>
<style scoped lang="scss">
.tbb-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;  
  
  .tbb-input-container {
    display: flex;
    flex-direction: column;
  }
}
</style>