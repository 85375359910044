<template>
    <rbcCommon :courseId="courseId" :activityTypeCode="activityTypeCode" :data="data" />
</template>
<script>
import rbcCommon from "./rbcCommon";

export default {
  name: "Dap",
  components: { rbcCommon },
  data() {
    return {
        courseId: "RBC-DAP-Convo-FinalCut-e4febcfd-8ccc-4825-8c38-3cd7749357b4",
        activityTypeCode: "TAKE_COURSE",
        data: { "course": "DAP" }
      }
  },
}
</script>
