import videos from '@/components/Carnival/videos';

export const routes = [
  {
    path: '/',
    redirect: '/videos/RxPriceShop'
  },
  {
    path: '/videos/:videoName',
    name: 'Default',
    component: videos,
    meta: {
      title: 'Carnival Videos'
    }
  }
];
