<template>
  <div>
    <Header></Header>
    <div class="introWrapper">
      <div class="intro">
        <span class="bigText"
          >Hi there, thanks for stopping by!<br /><br
        /></span>
        Capturing your employees attention can’t be accomplished with a one-and-done email. 
        Motivating employees to take action requires shaking up the regular modes of communication and introducing innovative solutions 
        that compels employees to act. Partner with Jellyvision’s Services & Solutions teams to launch custom-built projects curated by 
        creative experts with the HR industry know-how. 
        <br /><br />
        Whether you’re looking to promote top-of-mind HR and benefits initiatives, or looking for new ways to reach and engage employees, or if you need a virtual teammate to help you improve the employee experience -- we’re here to help. 
        <br /><br />
        Below you’ll find an overview of the process we typically use, but keep in mind that your project’s ingredient list may be different depending on 
        the solution and your needs. Generally speaking, you can expect to hit the following milestones as we work together:
      </div>
      <div class="jamImage">
        <img src="/images/jellyroll-gif-jamconcept.gif" />
      </div>
    </div>
    <Jellyroll
      accordionCode="CS_JELLYROLL"
      v-bind:displayTitle="false"
    ></Jellyroll>
    <FeedbackModal :questions="feedbackQuestions" 
       thankYouMessage="Thanks for helping!"  ></FeedbackModal>
    <Footer></Footer>
    <Vue3Html2pdf
    :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        filename="hee hee"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="800px"
    ref="html2Pdf"
    >
    <template  v-slot:pdf-content>
      <Accordion
      accordionCode="CS_JELLYROLL"
      v-bind:displayTitle="false"
    ></Accordion>
  </template>
  </Vue3Html2pdf>
  <button @click="generateReport">Generate</button>
    <PageVisitLogger client="JV" activityType="JV_VISIT_CST"></PageVisitLogger>
  </div>
</template>
<script>
import Header from "./shared/Header.vue";
import Accordion from "@/components/shared/Accordion";
import Footer from "./shared/Footer";
import FeedbackModal from "./shared/FeedbackModal";
import PageVisitLogger from "./shared/PageVisitLogger";
import Vue3Html2pdf from 'vue3-html2pdf'

require('../assets/css/shared/feedback/feedback_variables_standard.scss');

export default {
  components: { Header, Accordion, Footer, FeedbackModal, PageVisitLogger, Vue3Html2pdf },
  name: "Timeline",
  data() {
    return {
      myVal: 0,
      feedbackQuestions: [
        {
          question: "How helpful was this info about Solutions & Services?",
          subject: "JV_TIMELINE",
          sequence: 1,
          icons: [
            {score: 1, class: 'fas fa-grimace fa-2x', text:'Totally missed the mark', show: false},
            {score: 2, class: 'fas fa-frown fa-2x', text: 'Not helpful', show: false},
            {score: 3, class: 'fas fa-meh fa-2x', text: "I'm not sure", show: false},
            {score: 4, class: 'fas fa-smile fa-2x', text: 'Very helpful', show: false},
            {score: 5, class: 'fas fa-grin-stars fa-2x', text: 'Extremely helpful', show: false}
          ]
        }
      ]
    };
  },
  methods: {
    async generateReport () {
            this.$refs.html2Pdf.generatePdf();
        },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().output('datauristring').then((pdfAsString) => {
                this.attachment = pdfAsString;
            })
        }
  }
};
</script>
<style lang="scss" scoped src="@/assets/css/scoped/cs_timeline.scss"></style>
<style lang="scss" scoped src="@/assets/css/shared/feedback/modal_standard.scss"></style>
