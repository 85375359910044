<template>
  <div id="footerWrapper" :class="backgroundColorClass">
    <div class="footer" :class="[alignmentClass, backgroundClass]">
      <span class="copyright">© 2002-{{year}} | The Jellyvision Lab, Inc. | All rights reserved.</span>
    </div>
  </div>
</template>

<script>
require("../../assets/css/shared/footer/footer_variables_standard.scss");
export default {
  name: "Footer",
  data() {
    return {
      alignmentClass: 'footer_center',
      backgroundClass: 'footer_background',
      backgroundColorClass: 'footer_background_color'
    };
  },
  props: ["alignment", "show_background"],
  computed: {
    year() { 
      return new Date().getFullYear();
    }
  },
  setup() {},
  mounted() {
     if(this.alignment === 'left') {
      this.alignmentClass = 'footer_left';
     }

     if(!this.show_background) {
       this.backgroundClass = '';
       this.backgroundColorClass = '';
     } 
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/shared/footer/footer.scss"></style>
