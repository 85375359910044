import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'


function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, 
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const useGuidStore = defineStore({
    id: 'sessions',
    state: () => ({
        sessionId: useStorage('sessionId', uuidv4()),
        expirationDate: useStorage('expirationDate', null)
    }),
    actions: {
        getSessionId() {
            if(this.expirationDate == null || (new Date() > this.expirationDate))
                this.sessionId = uuidv4();

            this.expirationDate = this.getNewExpirationDate();
            return this.sessionId;
        },
        getNewExpirationDate() {
            var dt = new Date();
            dt.setTime(dt.getTime() + 1200000);
            return dt;
        }
    }
})