import  Dap  from '@/components/RBC/DAP';
import  Wap from '@/components/RBC/WAP';

export const routes = [
  {
    path: '/dap',
    component: Dap,
    meta: {
        title: 'RBC DAP'
    }
  },
  {
    path: '/wap',
    component: Wap,
    meta: {
        title: 'RBC WAP'
    }
  }
];
