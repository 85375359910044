<template>
    <div class="sampleLanguageContainer keepTogether" :style="{width: width + 'px'}">
        <div class="copyTextButton" v-on:click="copyTextToClipboard"><span v-html="copyText"></span></div>
        <div class="textbox" v-html="text"></div>
    </div>
</template>

<script>
export default {
    name: "SampleLanguageBox",
    data() {
        return {
            width: 500,
            text: '',
            copyText: 'COPY ME!'
        }
    },
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        },
        client: {
            type: String,
            default() {
                return '';
            }
        }
    },
    async mounted() {
        if(this.options.width)
            this.width = this.options.width;

        if(this.options.text)
            this.text = this.options.text;
            
        if(this.options.copyText)
            this.copyText = this.options.copyText;
    },
    methods: {
        fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            var successful = false;

            try {
                successful = document.execCommand('copy');
            } catch(err) {
                console.log(err);
            }

            const toastOptions = {
                position: 'top-right',
                duration: 1500
            }

            if(successful)
                this.$toast.success('Text Copied', toastOptions);
            else 
                this.$toast.error('Text Copy Failed', toastOptions);


            document.body.removeChild(textArea);
        },
        copyTextToClipboard() {
            const toastOptions = {
                position: 'top-right',
                duration: 1500
            }

            const plainText = this.stripHtml(this.text);

            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(plainText);
                return;
            }

            navigator.clipboard.writeText(plainText).then(() => {
                this.$toast.success('Text Copied', toastOptions);
            }, () => {
                this.$toast.error('Text Copy Failed', toastOptions);
            });
        },
        stripHtml(str) {
            const div = document.createElement('div');
            div.innerHTML = str;
            return div.textContent || div.innerText || '';
        }
    }
}
</script>