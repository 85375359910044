<template>
    <SinglePageAccordion :feedbackQuestions="feedbackQuestions"></SinglePageAccordion>
</template>

<script>
require('@/assets/css/client/demo/demo_variables.scss');
import SinglePageAccordion from '@/components/shared/SinglePageAccordion'

export default {
    name: 'Offboarding',
    components: { SinglePageAccordion },
    data() {
        return {
            feedbackQuestions: [
                {
                    sequence: 1,
                    answerType: 'Dropdown',
                    question: 'How helpful has this site been?',
                    subject: 'HOW_HELPFUL',
                    options: [
                        {sequence: 1, value: 5, displayText: 'Extremely helpful'},
                        {sequence: 2, value: 4, displayText: 'Very helpful'},
                        {sequence: 3, value: 3, displayText: 'Somewhat helpful'},
                        {sequence: 4, value: 2, displayText: 'Not very helpful'},
                        {sequence: 5, value: 1, displayText: 'Not at all helpful'}
                    ]
                },
                {
                    sequence: 2,
                    answerType: 'Dropdown',
                    question: 'Do you feel like you have a better understanding now of the next steps you should take in regard to your benefits?',
                    subject: 'BETTER_UNDRSTNDG',
                    options: [
                        {sequence: 1, value: 5, displayText: 'Very, definitely'},
                        {sequence: 2, value: 4, displayText: 'Yes, a little'},
                        {sequence: 3, value: 3, displayText: 'Maybe'},
                        {sequence: 4, value: 2, displayText: 'No, not really'},
                        {sequence: 5, value: 1, displayText: 'No, definitely not'}
                    ]
                },
                {
                    sequence: 3,
                    answerType: 'Text',
                    question: 'Do you have any more feedback?',
                    subject: 'MORE_FEEDBACK'
                }
            ]
        }
    }
}
</script>

<style scoped lang="scss" src="@/assets/css/client/demo/demooffboarding.scss"></style>