<template>
    <div class="box survey">
        <div :class="panelClasses">
            <div v-if="sending" class="sendingOverlay"></div>
            <div v-if="!submitted" class="gform-body gform_body gform_fields">
                <div v-for="ques in questions_local" :key="ques.sequence" :class="questionClasses">
                    <label class="gfield_label gform-field-label">{{ ques.question }}</label>
                    <div v-if="ques.answerType === 'Dropdown'" class="ginput_container ginput_container_select">
                        <select class="large gfield_select" v-model="ques.answer">
                            <option value="0">Select option</option>
                            <option v-for="opt in ques.options" :key="opt.sequence" :value="opt.value">{{ opt.displayText }}</option>
                        </select>
                    </div>
                    <div v-if="ques.answerType === 'Text'" class="ginput_container ginput_container_text">
                        <input type="text" v-model="ques.answer" class="large" />
                    </div>
                </div>
                <button type="button" @click="saveFeedback" class="gform_button button">{{ submitButtonText }}</button>
            </div>
            <div v-if="submitted" id="gform_confirmation_wrapper_1" :class="thankYouClasses">
                <div id="gform_confirmation_wrapper_1" class="gform_confirmation_message_1 gform_confirmation_message">{{ thankYouMessage }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { data } from "../../shared";

export default {
    name: 'FeedbackList',
    props: {
        questions: Array,
        thankYouMessage: {
            type: String,
            default: 'Thanks for contacting us! We will get in touch with you shortly.'
        },
        submitButtonText: {
            type: String,
            default: 'Submit'
        }
    },
    data() {
        return {
            questions_local: [],
            submitted: false,
            sending: false,
            panelClasses: 'gf_browser_chrome gform_wrapper gravity-theme gform-theme--no-framework',
            questionClasses: 'gfield gfield--type-select field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible',
            labelClasses: 'gfield_label gform-field-label',
            thankYouClasses: 'gform_confirmation_wrapper gravity-theme gform-theme--no-framework'
        }
    },
    mounted() {
        this.questions_local = this.questions;
        this.questions_local.sort((a,b) => a.sequence - b.sequence);
        this.questions_local.forEach(ques => {
            if(ques.answerType === 'Dropdown') {
                ques.answer = '0';
                ques.options.sort((a,b) => a.sequence - b.sequence);
            }
            if(ques.answerType === 'Text')
                ques.answer = '';
        })
    },
    methods: {
        saveFeedback: async function() {
            if(this.submitted || this.sending) return;
            this.sending = true;
            for(var i = 0; i < this.questions_local.length; i++)
            {
                let postBody = {
                    feedbackSubjectCode: this.questions_local[i].subject
                };
                if(this.questions_local[i].answerType === 'Dropdown' && this.questions_local[i].answer) 
                    postBody.feedbackScore = this.questions_local[i].answer;
                if(this.questions_local[i].answerType === 'Text' && this.questions_local[i].answer)
                    postBody.feedbackComment = this.questions_local[i].answer;

                if(postBody.feedbackScore || postBody.feedbackComment)
                    await data.postFeedback(postBody);
            }

            this.sending = false;
            this.submitted = true;
        },
    }
}
</script>

<style scoped lang="scss" src="@/assets/css/shared/offboarding.scss"></style>
<style scoped>
    .sendingOverlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        backdrop-filter: blur(4px);
    }
</style>