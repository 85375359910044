import Video from '@/components/TJUH/video';

export const routes = [
  {
    path: '/video',
    component: Video,
    meta: {
        title: 'Jefferson Video',
    }
  },
];