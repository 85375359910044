import Surest from '@/components/BestBuy/Surest';
import Coupe  from '@/components/BestBuy/Coupe';

export const routes = [
  {
    path: '/',
    redirect: '/surest'
  },
  {
    path: '/surest',
    component: Surest,
    meta: {
      title: 'Best Buy Coupe Video'
    }
  },
  {
    path: '/coupe',
    component: Coupe,
    meta: {
      title: 'Best Buy Coupe Video'
    }
  }

];
