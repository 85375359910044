<template>
  <div id="videoContainer">
		<video-player v-if="videoOptions" :options="videoOptions" ></video-player>
	</div>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";
import { data } from "../shared";

export default {
	name: "VideoExample",
	components: {
		VideoPlayer
	},
	data() {
		return {
            videoOptions: null
			// videoOptions: {
			// 	autoplay: false,
			// 	controls: true,
            //     height: 337,
            //     width: 600,
            //     logVideoStartId: 4,
            //     logVideoCompletedId: 5,
			// 	sources: [
            //         {
            //             src: "http://testurl.com/video1.ogg",
            //             type: "video/ogg"
            //         },
            //         {
			// 			src: "http://testurl.com/video.mp4",
			// 			type: "video/mp4"
			// 		}
			// 	],
            //     tracks: [
            //         {
            //             order: 2,
            //             label: "English (UK)",
            //             kind: "subtitles",
            //             srclang: "en-gb",
            //             src: "http://testurl.com/EnglishUK.vtt"
            //         },
            //         {
            //             order: 1,
            //             label: "English (US)",
            //             kind: "subtitles",
            //             srclang: "en-us",
            //             src: "http://testurl.com/EnglishUS.vtt",
            //             default: true
            //         }
            //     ]
            // }
		};
	},
    async mounted() {
        const resp = await data.getMediaProperty(126);
        this.videoOptions = resp.configuration;
    }
};
</script>

<style scoped>
 #videoContainer {
    width: 600px;
    height: 337px;
 }
</style>