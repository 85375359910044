<template>
  <BBGeneral></BBGeneral>
</template>

<script>
import BBGeneral from "@/components/BestBuy/BBGeneral";

export default {
  name: "Surest",
  components: {BBGeneral}
}
</script>
