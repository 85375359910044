<template>
    <div class="container" style="text-align:left; width: 100%;">
        <div style="width: 100%; display: flex; flex-direction: row;">
            <div class="row survey" style="width: 70%">
            <div class="col-md-auto" style="display:flex; flex-direction: column;">
                <div  class="questionPadding">
                    <input type="text" placeHolder="Filter by Name" v-model="filter" @Change="this.currentPage = 1"  />
                </div>
                <div style="">
                    <button class="submit listingButton" @click="filter = ''">Clear Filter</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                    <button class="submit listingButton" @click="editRecord(0)">New Configuration</button>        
                </div>
            </div>
            </div>
        </div>
        <div style="width: 100%; margin: 0px; padding: 0px;"><hr class="grayLine" /></div>
        <div class="row survey" style="width: 85%" v-show="sortedConfigurations.length > 0">
            <table class="listing">
            <thead>
            <tr>
                <th>Configuration Name <i class="fa-solid" :class="sortIcon" @click="changeSort"></i> 
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="configuration in sortedConfigurations" :key="configuration.id">
                <td style="width: 60%">{{ configuration.name }}</td>
                <td style="display: flex; justify-content: space-around">
                    <button class="submit gridButton" @click="editRecord(configuration.id)">Edit</button>
                    <button class="submit gridButton" @click="confirmDelete(configuration.id)">Delete</button>        
                </td>
            </tr>
            </tbody>
            </table>
            <div style="color:#2F2F52; padding-top: 10px; display: flex; flex-direction: row; justify-content: space-between;">
                <div>
                    <i class="fa-solid fa-angle-left" @click="prevPage" v-show="canGoBack"></i>&nbsp;&nbsp;
                    <i class="fa-solid fa-angle-right" @click="nextPage" v-show="canGoNext"></i>
                </div>
                <div> 
                    <div v-show="!filter">{{ this.configurations.length}} total record(s)</div>
                    <div v-show="filter">{{ this.filteredConfigurations.length}} filtered record(s)</div>
                </div>
            </div>
        </div>
        <div v-if="showConfirmDeleteModal" class="confirmDeleteModalBackdrop" v-on:click="closeConfirmDeleteModal"></div>
            <div class="confirmDeleteModal" v-if="showConfirmDeleteModal">
                <div class="confirmDeleteClose" v-on:click="closeConfirmDeleteModal">-</div>
                <div class="confirmDeleteContent">
                    <div class="confirmDeleteText">Are you sure you want to delete this eCard?</div>
                    <div class="modalButtonContainer">
                        <div class="modalButton yes" @click="onDelete(idToDelete)">Yes</div>
                        <div class="modalButton no" @click="closeConfirmDeleteModal">No</div>
                    </div>
                </div>
            </div>
        <div v-show="filteredConfigurations.length === 0 && configurations.length === 0">
            No configurations to show.  Click to add a configuration.
        </div>
        <div v-show="filteredConfigurations.length === 0 && configurations.length > 0">
            No configurations to show.  Change or clear filter to see records.
        </div>
    </div>
</template>

<script>
import { data } from '../../shared';

export default {
  name: "Listing",
  props: {
    onLogActivity : {
    type: Function
    },
    onEdit: {
        type: Function
    }
  },
  data() {
      return {
          configurations: [],
          filter: "",
          sortBy: "name",
          sortDirection: "asc",
          currentPage: 1,
          pageSize: 30,
          showConfirmDeleteModal: false,
          idToDelete: null
      }
  },
  computed: {
    canGoBack() {
        return this.currentPage > 1 && this.filteredConfigurations.length > 0;
    },
    canGoNext() {
        return this.currentPage < Math.ceil(this.filteredConfigurations.length / this.pageSize) 
    },
    sortIcon() {
        if(this.sortDirection === "asc") return "fa-caret-down";
        else 
            return "fa-caret-up";
    },
    filteredConfigurations() {
        var configs = this.configurations;
        //check filter
        if(this.filter != "")
        {
            configs = configs.filter((search) => search.name.toUpperCase().indexOf(this.filter.toUpperCase()) >= 0);
        }
        return configs;
    },
    sortedConfigurations () {
        var configs = this.filteredConfigurations;

        configs.sort(this.compareValues(this.sortBy, this.sortDirection));

        if(configs.length > this.pageSize)
        {
            var start = (this.currentPage - 1) * this.pageSize;
            configs = configs.slice(start, (start + this.pageSize));
        }

        return configs;
    }
  },
  async mounted() {
    this.getClientData();
  },
  methods: { 
    compareValues: function(key, order = 'asc') {
        return function innerSort(a, b) {
            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } 
            else if (varA < varB) {
                comparison = -1;
            }

            return ( (order === 'desc') ? (comparison * -1) : comparison );
        };
    },
    nextPage:function() {
      this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    changeSort: async function() {
        if(this.sortDirection == "asc") this.sortDirection = "desc";
        else 
            this.sortDirection = "asc";
    },
    getClientData: async function() {
        this.configurations = await data.browseClientData("ECardVideoConfig", this.filter);
    },
    logActivity: async function(activityTypeCode) {
      if(this.onLogActivity)
        this.onLogActivity(activityTypeCode, null);
    },
    editRecord: async function(id) {
        if(this.onEdit)
            this.onEdit(id);
    },
    confirmDelete: function(id) {
        this.showConfirmDeleteModal = true;
        this.idToDelete = id;
    },
    closeConfirmDeleteModal: function() {
        this.showConfirmDeleteModal = false;
        this.idToDelete = null;
    },
    onDelete: async function(id) {
      await data.deleteClientData(id);
      this.showConfirmDeleteModal = false;
      this.idToDelete = null;
      for(var i = 0; i < this.configurations.length; i++) {
        if(this.configurations[i].id === id) {
            this.configurations.splice(i, 1);
            break;
        }
      }
      this.$toast.success('Saved!');
    }
  }
};
</script>
<style lang="scss" scoped src="@/assets/css/client/MMA/style.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/MMA/mma-style.scss"></style>
