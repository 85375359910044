<template >
  <div class="autoCompleteContainer" @mouseleave="closeSuggestion" tabIndex="-1">
    <input
      :placeholder="placeholderText"
      :style="{width: reactiveWidth}"
      type="text" ref="input"
      @input="updateValue($event.target.value)"
      :value="value"
      @focus="onFocus"
      @blur="lostFocus"
      @keydown.enter="enter"
      @keydown.down="down"
      @keydown.up="up"
      :class="openSuggestion ? 'listOpen' : 'listClosed'"
    />
    <ul class="dropdown-menu" v-if="openSuggestion"         
        @mouseover="mouseover = true;" @mouseout="mouseover = false">
      <li
        v-for="(record, index) in matches"
        v-bind:class="{'active': isActive(index)}"

        @click="selectValue(index)"
        :key="index"
      >
        {{ record.displayValue }}
      </li>
    </ul>
  </div>
</template>
<script>
import { translation } from '@/shared/translation';
export default {
    props: {
        attributeCode: {
            type: String,
            required: true
        },
        placeholder: {
            type: String
        },
        value: {
            type: String
        },
        data: {   //formatted as displayValue, searchValue
            type: Array,
        },        
        numRecordsToDisplay: {
            type: Number,
            default: 15,
        },
        searchField: {
            type: String,
            required: true
        },
        displayField: {
            type: String,
            required: true        
        },
        internalValueField: {
            type: String,
            required: true
        },
        onValueChanged: {
            type: Function
        },
        language: {
            type: String,
            default: "English"
        }
    },
    data () {
        return {
            open: false,
            currentIndex: 0,
            internalValue: '',
            mouseover: false, 

        }
    },
    computed: {
        // Filtering the suggestion based on the input
        matches () {    
            var matchList = this.data;
            if(this.value && this.value != '') {
                var searchValue = this.value.toLowerCase();
                matchList = this.data.filter((obj) => {
                    if(!this.searchField) return false;
                    if(this.getTextForLanguage(obj[this.searchField]).toLowerCase().indexOf(searchValue) >= 0)
                            return true;

                    return false;
                });
            }

            if(matchList.length > this.numRecordsToDisplay) {
                matchList = matchList.slice(0, this.numRecordsToDisplay);
            }
            matchList.forEach(obj => {
                obj.displayValue = this.getDisplayValue(obj);
            });

            return matchList;
        },
        openSuggestion () {
            return  this.matches.length !== 0 &&
                    this.open === true 
        },
        reactiveWidth() {
            if(this.value == undefined || this.value === '' || this.value.length < 30)
                return "30ch";
            else if(this.value.length > 60)
                return "60ch";
            else 
                return this.value.length + 'ch';
        },
        placeholderText() {
            return this.placeholder ? this.placeholder : "Start Typing";
        }
    },
    methods: {
        // Triggered the input event to cascade the updates to
        // parent component
        updateValue (value) {
            this.showSuggestion();
            this.valueChanged(value);
        },
        valueChanged(newValue) {
            if(this.onValueChanged)
                this.onValueChanged(this.attributeCode, newValue, newValue != '' ? this.internalValue : '');
        },
        onFocus() {
            this.$refs.input.select();
            if((this.value ?? '') === '')
                this.showSuggestion();
        },
        showSuggestion() {
           if (this.open === false) {
                this.open = true;
                this.currentIndex = 0;
            }
        },
        hideSuggestion() {
            if(this.mouseover === false)
                this.open = false;
        },
        // When enter key pressed on the input
        enter () {
            this.selectValue(this.currentIndex);
        },
        // When up arrow pressed while suggestions are open
        up () {
            if (this.currentIndex > 0) {
                this.currentIndex--
            }
        },
        // When down arrow pressed while suggestions are open
        down () {
            if (this.currentIndex < this.matches.length - 1) {
                this.currentIndex++
            }
        },
        // For highlighting element
        isActive (index) {
            return index === this.currentIndex
        },
        // When one of the suggestion is clicked
        selectValue (index) {
            this.setDisplayValue(this.matches[index]);
            this.hideSuggestion();
        },
        setDisplayValue(record) {
           this.valueChanged(this.getDisplayValue(record));
           this.mouseover = false;
        },
        getDisplayValue(record) {
            if(!this.displayField || this.displayField === '' || record === '') {
                this.internalValue = '';
                return '';
            }
            else {
                this.internalValue = record[this.internalValueField];
                return this.getTextForLanguage(record[this.displayField]);
            }
        },
        lostFocus() {
            this.hideSuggestion();
            if(this.mouseover === false)
            {
                if((this.value ?? '').trim() === '' ) {
                    this.valueChanged(this.getDisplayValue(''));
                    //this.$emit('update:value',  '');
                    return;
                }

                var checkValue = this.value.toLowerCase();
                var valid = true;
                if(this.searchField === '') 
                    valid = false;
                else { 
                    var search = this.data.find((obj) => this.getTextForLanguage(obj[this.searchField]).toLowerCase() === checkValue);
                    
                    if(search == undefined) valid = false;
                    else if(this.getTextForLanguage(search[this.searchField]) != this.value)
                      this.valueChanged(this.getDisplayValue(search));
                }


                if(valid == false)
                    this.valueChanged('');                    
            }
        },      
        getTextForLanguage: function(jsonField) {
            return translation.getTextForLanguage(jsonField, this.language);
        }
    }    
  };
</script>