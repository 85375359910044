<template>
    <div class="button-row-container"> 
        <div class="navigation-button-container back-button-container">
            <div @click="goToPreviousPage" class="toggleButton toggleButton-back" v-if="showGoToPreviousPage">
                <i class="fa-solid fa-chevron-left"></i>
            </div>
        </div>
        <div class="audio-container" v-if="showAudioPlayer">
            <AudioPlayer ref="audioPlayer" :audioTracks="audioTracks" :playTrackId="playTrackId" :autoplay="true"></AudioPlayer>
        </div>
        <div class="navigation-button-container forward-button-container">
            <div @click="goToNextPage" class="toggleButton toggleButton-next" v-if="showGoToNextPage">
                <i class="fa-solid fa-chevron-right"></i>
            </div>
        </div>
    </div>
</template>
<script>
import AudioPlayer from '@/components/shared/MediaProperties/AudioPlayer';

export default {
    name: "Navigation",
    components: { AudioPlayer },
    props: {
        showGoToPreviousPage: {
            type: Boolean,
            default: false
        },
        showGoToNextPage: {
            type: Boolean,
            default: false
        },
        onGoToPreviousPage: {
            type: Function
        },
        onGoToNextPage: {
            type: Function
        },
        audioTracks: {
            type: Array
        },
        playTrackId: {
            type: String,
            default: ''
        },
        showAudioPlayer: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        goToPreviousPage: function() {
            if(this.onGoToPreviousPage)
                this.onGoToPreviousPage();
        },
        goToNextPage: function() {
            if(this.onGoToNextPage)
                this.onGoToNextPage();
        },
    }
}
</script>

<style scoped>
    .toggleButton {
        box-sizing: border-box;
        cursor: pointer;
        color: var(--audio-button-color, #74748C);
        font-size: 1rem;
        font-weight: 900;
        border-color: var(--audio-button-color, #74748C);
        border-width: 2px;
        border-style: solid;
        border-radius: 50%;
        width: 3em;
        height: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        transition-duration: 0.5s;
    }

    .toggleButton:hover {
        color: var(--off-white-color);
        background-color: var(--audio-button-color, #74748C);
    }

    .button-row-container {
        display:flex; 
        flex-direction: row; 
        justify-content: space-between;
        width: 100%; 
        background-color: #FFFFFF;
        padding: .625em 0px;
    }

    .navigation-button-container {
        flex: 0 0 3.125em; 
    }

    .audio-container {
        flex: 0 0 15.625em;
    }
  </style>
  