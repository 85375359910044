<template>
    <template v-if="survey">
        <select v-model="language">
            <option v-for="lang in languages" :key="lang.name">{{ lang.name }}</option>
        </select>        
        <SurveyPage v-for="surveyPage in survey.pages" :key="surveyPage.id" :page="surveyPage" :showButtonsWithQuestions="true" :onVideoFinish="onVideoFinish"
            :onQuestionAnswered="onQuestionAnswered" :skipPage="skipPage" :beforePageSubmit="beforePageSubmit" :onPageSubmit="onPageSubmit" :language="language" :storageBaseUrl="storageBaseUrl"></SurveyPage>
    </template>
</template>

<script>
import SurveyPage from '@/components/shared/Survey/SurveyPage';
import { useLoaStore } from "@/stores/loa";
import { data } from '@/shared/data';
import jsonLogic from 'json-logic-js';
import { evaluate } from 'mathjs';

export default {
    name: 'TestSinglePageSurvey',
    components: { SurveyPage },
    data() {
        return {
            survey: null,
            surveyAnswers: {},
            isDebug: false,
            languages: [{name: "English"}, {name: "Spanish"}, {name: "Portuguese"}],
            language: "English",
            storageBaseUrl: ''
        }
    },
    computed: {
        calculationActions() {
            if(this.survey && this.survey.actions) 
                return this.survey.actions.filter(act => {return act.type === 'SetVariable'});
 
            return [];
        }
    },
    setup() {
      const piniaSurvey = useLoaStore();
      return {
        piniaSurvey
      };
    },
    async mounted() {
        let uri = window.location.search.substring(1); 
        let params = new URLSearchParams(uri);
        this.isDebug = (params.get("debug"));
        this.storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);
        await this.getSurvey();
    },
    methods: {
        skipPage: function() {
            return true;
        },
        getSurvey: async function() {
            this.survey = await data.getSurveyByCode('MTAB_LOUNG');
            this.survey.pages.sort((a, b) => {
                return a.pageNumber - b.pageNumber;
            });
            for(let i = 0; i < this.survey.pages.length; i++) {
                this.survey.pages[i].questions.sort((a,b) => {
                    return a.sequence - b.sequence;
                });
            }
        },
        onQuestionAnswered: function() {

        },
        onVideoFinish: function(questionId, pageId) {
            const question = this.survey.pages.find(p => p.id === pageId).questions.find(q => q.id === questionId);
            if(question)
                question.finishedVideo = true;
        },
        beforePageSubmit: function() {
            let attributes = this.piniaSurvey.getAttributes;

            for(var i = 0; i < this.survey.actions.length; i++) {
                var action = this.survey.actions[i];

                if(action.type === 'PopupModalWindow' && jsonLogic.apply(JSON.parse(action.conditionalJson), attributes)) {
                    this.popupModalConfiguration = action.configuration;
                    return;
                }
            }
        },
        onPageSubmit: function() {
            let attributes = this.piniaSurvey.getAttributes;
            for(let i = 0; i < this.calculationActions.length; i++) {
                switch(this.calculationActions[i].subType) {
                    case 'DateCalculation': {
                        const value1 = this.processDateValue(this.calculationActions[i].configuration.operation.value1);
                        const value2 = this.calculationActions[i].configuration.operation.operator === 'FirstOfMonth' ? '' : this.processDateValue(this.calculationActions[i].configuration.operation.value2);
                        if(!value1 || (!value2 && this.calculationActions[i].configuration.operation.operator !== 'FirstOfMonth'))
                            break;
                        switch(this.calculationActions[i].configuration.operation.operator) {
                            case 'FirstOfMonth':
                                this.setAttribute(this.calculationActions[i].attribute, `${value1.getMonth() + 1}-01-${value1.getFullYear()}`);
                                break;
                            case 'DateDiffDays': {
                                let diff = value2 - value1 + ((value2.getTimezoneOffset() - value1.getTimezoneOffset()) * 60 * 1000);
                                const oneDay = 1000 * 60 * 60 * 24;
                                const days = Math.floor(diff / oneDay);
                                this.setAttribute(this.calculationActions[i].attribute, days <= 0 ? 0 : days);
                                break;
                            }
                            case 'DateDiffMonths': {
                                let months = (value2.getFullYear() - value1.getFullYear()) * 12;
                                months += value2.getMonth();
                                months -= value1.getMonth();
                                if(value2.getDate() < value1.getDate())
                                    months--;
                                this.setAttribute(this.calculationActions[i].attribute, months <= 0 ? 0 : months);
                                break;
                            }
                        }
                        break;
                    }
                    case 'NumberCalculation': {
                        let value1 = Object.hasOwn(attributes, this.calculationActions[i].configuration.operation.value1) ? attributes[this.calculationActions[i].configuration.operation.value1] : this.calculationActions[i].configuration.operation.value1;
                        let value2 = Object.hasOwn(attributes, this.calculationActions[i].configuration.operation.value2) ? attributes[this.calculationActions[i].configuration.operation.value2] : this.calculationActions[i].configuration.operation.value2;
                        if(!value1 || !value2 || isNaN(value1) || isNaN(value2))
                            break;
                        let operator;
                        switch(this.calculationActions[i].configuration.operation.operator) {
                            case 'Add':
                                operator = '+';
                                break;
                            case 'Subtract':
                                operator = '-';
                                break;
                            case 'Multiply':
                                operator = '*';
                                break;
                            case 'Divide':
                                operator = '/';
                                break;
                        }
                        this.setAttribute(this.calculationActions[i].attribute, evaluate(value1.toString().concat(operator, value2.toString())));
                        break;
                    }
                    case 'LogicalCalculation':
                        this.calculationActions[i].configuration.calculations.sort((a,b) => {return a.sequence - b.sequence;});
                        this.calculationActions[i].configuration.calculations.some(calc => {          
                            let logicalValue = Object.hasOwn(attributes, calc.value) ? attributes[calc.value] : calc.value;
                      

                            if(calc.jsonExpression === 'ELSE') {
                                this.setAttribute(this.calculationActions[i].attribute, logicalValue);
                                 // ends iteration
                                return true;
                            }
                            if(jsonLogic.apply(JSON.parse(calc.jsonExpression), attributes)) {

                                this.setAttribute(this.calculationActions[i].attribute, logicalValue);
                                // ends iteration
                                return true;
                            }
                        });
                        break;
                    case 'SetConstant':
                        this.setAttribute(this.calculationActions[i].attribute, this.calculationActions[i].configuration.value);
                        break;
                }
            }
        },
        processDateValue: function(dateStr) {
            if(dateStr === 'NOW')
                return new Date();
            else if(!(dateStr instanceof Date)) { 
                const attributes = this.piniaSurvey.getAttributes;
                const attrVal = attributes[dateStr];
                if(attrVal)
                    return new Date(attrVal); 
                else 
                    return null;
            }
            else
                return new Date(dateStr);
        },
        setAttribute: function(attribute, value) {
            if(attribute.useAsReplacementString) {
                let replacementStrings = this.piniaSurvey.getReplacementStrings;
                replacementStrings['%' + attribute.code + '%'] = value;
                this.piniaSurvey.setReplacementStrings(replacementStrings);
            } else {
                let attributes = this.piniaSurvey.getAttributes;
                attributes[attribute.code] = value;
                this.piniaSurvey.setAttributes(attributes);
            }
        }
    }
}
</script>
