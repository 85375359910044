<template>
    <Accordion accordionCode="TST1" :hasDisplayLogic="false"></Accordion>
</template>

<script>
import Accordion from '@/components/shared/Accordion';
require('@/assets/css/shared/table/table_variables_standard.scss');

export default {
    name: 'TestAccordion',
    components: {Accordion}
}
</script>