<template>
  <div class="page base-font">
    <div class="header noprint base-margin">
      <div class="headerTitle">
        <div class="logo"> 
            <img :src="logoSrc" />
        </div>
        <div class="alex-logo-container">
            <img class="alexLogo" src="/images/Demo/alex-logo.svg" />
        </div>
      </div>
    </div>
    <div class="content">
      <Accordion v-if="accCode" :accordionCode="accCode" :replacements="replacements" :logicParameters="parameters"></Accordion>
    </div>
    <div >
      <Footer  alignment="left"></Footer>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/shared/Footer";
import Accordion from '@/components/shared/Accordion';
import { data } from '@/shared';

export default {
  name: "BBGeneral",
  components: {Accordion, Footer},
  data() {
    return {
      accCode: null,
      logoSrc: null,
      replacements: {},
      parameters: {}

    };
  },
  async mounted() {    
    document.querySelector('body').style.height = "100%";
    document.querySelector('html').style.height = "100%";
    document.querySelector('#app').style.height = "100%";

    this.storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);

    // Get logo, but don't break if not there
    let logo;
    const logoMpId = await data.getClientLogoAssetID();
    if(logoMpId)
        logo = await data.getMediaProperty(logoMpId);
    if(logo)
        this.logoSrc = (!logo.isLocalPath ? this.storageBaseUrl : '') + logo.configuration.src;

    await this.getClientConstants();

    var logData = {
        "accordionCode": this.accCode
    };

    let activityObj = {
        logActivityTypeCode: 'LANDING_PAGE',
        data: JSON.stringify(logData)
    };
    
    await data.postActivityLog(activityObj);
  },
  methods: {
    getClientConstants: async function() {
        const constants = await data.getClientConstants();

        if(constants != null) {
            constants.forEach(constant => {
                switch(constant.key) {
                    case "ACCORDION_CODE":
                        this.accCode = constant.value;
                        break;
                }            
            });
        }

        this.i++;
    }
  }
}
</script>
<style scoped lang="scss" src="@/assets/css/client/BestBuy/style.scss"></style>
