<script>
import { data } from "../../shared";

export default {
    name: "PageVisitLogger",
    props: {
        activityType: String
    },
    render: () => null,
    data() {
        return {
            activityObj: {
                logActivityTypeCode: this.activityType
            }
        }
    },
    mounted() {
        data.postActivityLog(this.activityObj);
    }
}
</script>
