<template>
    <main role="main">
        <div v-if="accordionCode">
            <div class="row container wide offboarding">
                <div class="col-8">
                    <div v-if="accordion && accordion.headers && accordion.headers.length > 0">
                        <div class="accordionHeader">
                            <div v-for="header in accordion.headers" :key="header.id">
                                <img v-if="header.mediaProperty && header.mediaProperty.mediaType === 'Image'" :src="getImageSrc(header.mediaProperty.configuration)" />
                                <div v-if="header.displayText && !header.mediaProperty">
                                    <DisplayText :cssClass="header.cssClass" :displayText="getTextForLanguage(header.displayTextJson)"></DisplayText>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="timeline" v-if="accordion" class="post-1230 page type-page status-publish hentry">
                        <div v-for="section in accordion.sections" 
                            :key="section.id" 
                            :class="sectionClasses(section)" 
                            :style="{height: section.open ? 'auto' : section.collapsedHeight}" 
                            :ref="'section' + section.id" 
                            :id="getRefID(section)">
                            <h2 v-for="header in section.headers" :key="header.id">{{ getTextForLanguage(header.minimizedTextJson) }}</h2>
                            <p v-for="detail in section.details" v-bind:key="detail.id">
                                <span v-if="detail.displayText && !detail.mediaProperty">
                                    <DisplayText :cssClass="detail.cssClass" :displayText="getTextForLanguage(detail.displayTextJson)"></DisplayText>
                                </span>
                                <video-player v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'Video'" 
                                    :mediaPropertyID="detail.mediaPropertyID" :options="detail.mediaProperty.configuration" 
                                    :tracks="detail.mediaProperty.configuration.tracks" :ref="'video' + detail.id"
                                    :videoDescription="detail.mediaProperty.description"></video-player>
                                <img v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'Image'" :src="getImageSrc(detail.mediaProperty.configuration)" />
                                <Checklist v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'Checklist'" :options="detail.mediaProperty.configuration" :client="client" :ref="'checklist' + detail.id" />
                                <SampleLanguageBox v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'SampleLanguage'" :options="detail.mediaProperty.configuration" :client="client" :ref="'sample' + detail.id" />
                                <BarChart v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'BarChart'" :options="detail.mediaProperty.configuration" :client="client" id="'barchar' + detail.id" />
                                <span style="display: inline" v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'ToolTip'" v-html="detail.preText"></span>
                                <ToolTip v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'ToolTip'" :text="detail.mediaProperty.configuration.text">{{ detail.middleText }}</ToolTip>
                                <span style="display: inline" v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'ToolTip'" v-html="detail.postText"></span>
                                <Table v-if="detail.mediaProperty && detail.mediaProperty.mediaType === 'Table'" :options="detail.mediaProperty.configuration"></Table>
                            </p>
                            <button v-if="section.collapsedHeight" @click="section.open = !section.open" class="read-more fancy-link" :class="section.open ? 'less' : 'more'">
                                <span v-if="!section.open" v-html="readMoreText"></span>
                                <span v-if="section.open" v-html="readLessText"></span>
                                <span class="caret"></span>
                            </button>
                        </div>
                    </div>
                    <div v-if="accordion && accordion.footers" class="accordionFooter">
                        <div v-for="footer in accordion.footers" :key="footer.key">
                            <img v-if="footer.mediaProperty && footer.mediaProperty.mediaType === 'Image'" :src="getImageSrc(footer.mediaProperty.configuration)" />
                            <div v-if="footer.displayText && !footer.mediaProperty">
                                <DisplayText :cssClass="footer.cssClass" :displayText="getTextForLanguage(footer.displayTextJson)"></DisplayText>
                            </div>
                        </div> 
                    </div>
                    <FeedbackList v-if="accordion && feedbackQuestions" :questions="feedbackQuestions"></FeedbackList>
                </div>
                <div class="col-4 sidebar" v-if="accordion">
                    <nav class="box toc">
                        <h4>{{ jumpToText }}</h4>
                        <ul>
                            <li v-for="sec in jumpToSections" :key="sec.id"><a class="fancy-link" :href="'#' + getRefID(sec)">{{ listItemDisplay(sec) }}</a></li>
                        </ul>
                    </nav>
                    <button id="scroll-to-top" @click="scrollToTop">{{ backToTopText }}</button>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { data } from "@/shared";
import VideoPlayer from '@/components/VideoPlayer.vue';
import BarChart from '@/components/shared/BarChart';
import Checklist from '@/components/shared/Checklist';
import SampleLanguageBox from '@/components/shared/SampleLanguageBox';
import ToolTip from '@/components/shared/ToolTip';
import Table from '@/components/shared/Table';
import DisplayText from '@/components/shared/DisplayText';
import FeedbackList from '@/components/shared/FeedbackList';
import { translation } from '@/shared/translation';

export default {
    name: 'AccordionSectionView',
    components: { VideoPlayer, Checklist, SampleLanguageBox, BarChart, ToolTip, Table, DisplayText, FeedbackList }, 
    props: {
        accordionCode: String,
        hasDisplayLogic: Boolean, 
        logicParameters: Object,
        feedbackQuestions: Array,
        replacements: Object,
        maxSectionHeight: {
            type: Number,
            default: 700
        },
        backToTopText: {
            type: String,
            default: 'Back to Top'
        },
        jumpToText: {
            type: String,
            default: 'JUMP TO'
        },
        additionalAccordionCodes: Array,
        language: {
            type: String,
            default: 'English'
        },
        readMoreText: {
            type: String,
            default: 'Read more'
        },
        readLessText: {
            type: String,
            default: 'Read less'
        }
    },
    data() {
        return {
            accordion: null,
            storageBaseUrl: ''
        };
    },
    computed: {
      jumpToSections() { 
        return this.accordion.sections.filter(obj => obj.headers && obj.headers.length > 0);
      },
    },
    async mounted() {
        this.storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);

        if(!this.hasDisplayLogic || this.logicParameters)
            await this.getAccordion(this.accordionCode, this.logicParameters, this.replacements);
        

    this.accordion.sections.forEach((sec) => {
        this.$nextTick(() => {
          const innerContent = this.$refs['section' + sec.id][0].querySelector('.callout-boxes');
          
          sec.open = false;
            
          if(innerContent != null) {
            // Calculate the height of the first few inner contents
            sec.collapsedHeight = innerContent.offsetTop + innerContent.offsetHeight + 100 + 'px';
          }
          else {
            var height =this.$refs['section' + sec.id][0].clientHeight;
            if(height > this.maxSectionHeight)
                sec.collapsedHeight = this.maxSectionHeight + "px";                
            else 
                sec.collapsedHeight = null;
          }
        });
      });
    },
    methods: {
        getRefID: function(section) {
            if(section.headers && section.headers.length > 0) 
                return section.headers[0].minimizedText.split(' ')[0];
            else 
                return section.id;
        },
        getImageSrc: function(config) {
            if(config.isLocalPath === false)
                return this.storageBaseUrl + (config.src[0] !== '/' ? '/' : '') + config.src;    
            else    
                return config.src;
        },
        getAccordion: async function(code, parameters, replacementStrings) {
            this.accordion = await data.getAccordion(code, parameters, this.additionalAccordionCodes);
            const replacementKeys = replacementStrings ? Object.keys(replacementStrings) : null;

            let length = this.accordion.sections.length;
            this.accordion.sections.forEach((element) => {
                this.accordion.sections.push({ ...element, visible: false });
                element.details.forEach((det) => {
                    if((!det.mediaPropertyID || det.mediaProperty.mediaType === 'ToolTip') && replacementStrings) {
                        replacementKeys.forEach((replacementKey) => {
                            det.displayTextJson.forEach(dispText => {
                                dispText.text = dispText.text.replaceAll(replacementKey, replacementStrings[replacementKey]);
                            });
                            // det.displayText = det.displayText.replaceAll(replacementKey, replacementStrings[replacementKey]);
                        });
                    }

                    if(det.mediaPropertyID && det.mediaProperty.mediaType === 'ToolTip') {
                        const toolTipStart = det.displayText.indexOf('<ToolTip');
                        const middleTextStart = det.displayText.search(/(<ToolTip>)/) + 9;
                        const middleTextEnd = det.displayText.indexOf('</ToolTip>');
                        const toolTipEnd = det.displayText.indexOf('</ToolTip>') + 10;
                        det.preText = det.displayText.substr(0, toolTipStart);
                        det.postText = det.displayText.slice(toolTipEnd, det.displayText.length);
                        det.middleText = det.displayText.slice(middleTextStart, middleTextEnd);
                    }
                });
            });

            for (var i = 0; i < length; i++) {
                this.accordion.sections.shift();
            }

            if(replacementKeys && this.accordion.headers) {
                for (i = 0; i < this.accordion.headers.length; i++) 
                replacementKeys.forEach((replacementKey) => {
                    this.accordion.headers[i].displayTextJson.forEach(dispText => {
                        dispText.text = dispText.text.replaceAll(replacementKey, replacementStrings[replacementKey]);
                    });
                });
            }

            if(replacementKeys && this.accordion.footers) {
                for (i = 0; i < this.accordion.footers.length; i++) 
                replacementKeys.forEach((replacementKey) => {
                    this.accordion.footers[i].displayTextJson.forEach(dispText => {
                        dispText.text = dispText.text.replaceAll(replacementKey, replacementStrings[replacementKey]);
                    });
                });
            }
            if(this.onAccordionLoad)
                this.onAccordionLoad(this.accordion);
        },
        listItemDisplay: function(section) {
            if(section.headers && section.headers.length > 0)
                return this.getTextForLanguage(section.headers[0].minimizedTextJson ?? section.headers[0].expandedTextJson ?? '', this.language);
            else 
                 return '';
        },
        sectionClasses: function(sec) {
            return {
                box: true,
                'has-read-more': sec.collapsedHeight != null,
                open: sec.open
            }
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        getTextForLanguage: function(jsonField) {
            return translation.getTextForLanguage(jsonField, this.language);
        }
    },
    watch: {
        '$route.params.code': function(code) {
            this.getAccordion(code);
        }
    }
}
</script>

<style scoped lang="scss" src="@/assets/css/shared/offboarding.scss"></style>