<template>
    <div class="frontPage">
        <div class="mainContainer">
            <div class="videoContainer" style="display:none;" >
                <img src="/images/MTAB/video-placeholders-welcome.png" style="width: 100%; max-width: 600px;" />
            </div>
            <div class="videoContainer" >
                <video-player  v-if="activeVideoConfig" :options="activeVideoConfig" 
                :mediaPropertyID="157"
                videoDescription="Welcome to MTAB Benefit Lounge Video"></video-player>
            </div>
            <button class="actionButton" @click="onClickStart" >{{ activeStartButtonText }}</button>
            <div class="textContainer">
                <div class="leftContainer" v-html="activeLeftContainer"></div>
                <div class="rightContainer">
                    <span v-html="activeRightContainer"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VideoPlayer from '@/components/VideoPlayer.vue';
import { data } from '@/shared';

export default {
  name: "FrontPage",
  components: { VideoPlayer },
  props: {
    clientCode: String,
    onClickStart : {
      type: Function
    },
    languageCode: String
  },
  data() {
      return {
          videoConfig: {
              height: 460,
              width: 640,
              controls: true,
              autoPlay: true,
              muted: true,
              client: this.clientCode,
              sources: [
                  {
                      src: "/MTAB/Welcome to the Member Lounge.mp4",
                      type: "video/mp4"
                  }
              ],
              tracks: [
                {
                    label: "English",
                    language: 'en',
                    src: '/MTAB/Welcome to the Member Lounge.vtt'
                },
                {
                    label: "Español",
                    language: 'es',
                    src: '/MTAB/Welcome to the Member Lounge-es.vtt'
                },
                {
                    label: "Português",
                    language: 'pt',
                    src: '/MTAB/Welcome to the Member Lounge-pt.vtt'
                }
              ],
              loggingActivities: [
                  {
                      percentage: 0,
                      logActivityTypeCode: "VIDEO_STARTED"
                  },
                  {
                      percentage: 25,
                      logActivityTypeCode: "VIDEO_25%"
                  },
                  {
                      percentage: 50,
                      logActivityTypeCode: "VIDEO_50%"
                  },
                  {
                      percentage: 75,
                      logActivityTypeCode: "VIDEO_75%"
                  },
                  {
                      percentage: 100,
                      logActivityTypeCode: "VIDEO_FINISHED"
                  }
              ]
          },
          activeVideoConfig: null,
          activeLeftContainer: '',
          activeRightContainer: '',
          activeStartButtonText: '',
          leftContainerText: {
            english: "<h2>We're so happy you're here!</h2><p>Whether you're a newbie or a seasoned pro, we want to make sure you're getting all the amazing discounts and perks available to you as a member of the MTA.</p><p>To find the benefits that’ll fit you best, we'll ask a few questions to get to know you, then offer our top suggestions based on your input.</p>",
            spanish: "<h2>¡Estamos tan felices de que estés aquí!</h2><p>Ya sea que sea un novato o un profesional experimentado, queremos asegurarnos de que obtenga todos los increíbles descuentos y beneficios disponibles para usted como miembro de la MTA.</p><p>Para encontrar los beneficios que mejor se adapten a usted, le haremos algunas preguntas para conocerlo y luego le ofreceremos nuestras principales sugerencias según sus comentarios.</p>",
            portuguese: "<h2>Estamos tão felizes que você está aqui!</h2><p>Seja você um novato ou um profissional experiente, queremos garantir que você receba todos os incríveis descontos e vantagens disponíveis como membro do MTA.</p><p>Para encontrar os benefícios que melhor se adequam a você, faremos algumas perguntas para conhecê-lo e, em seguida, oferecer nossas principais sugestões com base em sua opinião.</p>"
          },
          rightContainerText: {
            english: "<p>We promise it’ll go quickly, and it’ll be worth it when you have your unique benefit recommendations ready to help you save some money (and peace of mind).</p><p>Sit back, get comfy, and let’s have a chat!</p>",
            spanish: "<p>Le prometemos que será rápido y valdrá la pena cuando tenga sus recomendaciones de beneficios únicas listas para ayudarlo a ahorrar algo de dinero (y tranquilidad).</p><p>¡Siéntate, ponte cómodo y hablemos!</p>",
            portuguese: "<p>Prometemos que será rápido e valerá a pena quando você tiver suas recomendações de benefícios exclusivas prontas para ajudá-lo a economizar algum dinheiro (e tranquilidade).</p><p>Sente-se, fique confortável e vamos bater um papo!</p>"
          },
          startButtonText: {
            english: "Find My Benefits",
            spanish: "Buscar mis beneficios",
            portuguese: "Encontre meus benefícios"
          }
      }
  },
  beforeMount() {
    this.loadLanguage(this.languageCode);
  },
  async mounted() {
      const storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);
      if(this.videoConfig.poster)
          this.videoConfig.poster = storageBaseUrl + this.videoConfig.poster;

      this.videoConfig.sources.forEach(sc => {
          sc.src = storageBaseUrl + sc.src;
      });
      this.videoConfig.tracks.forEach(tk => {
          tk.src = storageBaseUrl + tk.src;
      });
      this.activeVideoConfig = this.videoConfig;
      this.$parent.piniaSurvey.setAttributes(null);
  },
  methods: {
    loadLanguage: function(languageCode) {
        switch(languageCode) {
            case 'en':
                this.activeLeftContainer = this.leftContainerText.english;
                this.activeRightContainer = this.rightContainerText.english;
                this.activeStartButtonText = this.startButtonText.english;
                break;
            case 'es':
                this.activeLeftContainer = this.leftContainerText.spanish;
                this.activeRightContainer = this.rightContainerText.spanish;
                this.activeStartButtonText = this.startButtonText.spanish;
                break;
            case 'pt':
                this.activeLeftContainer = this.leftContainerText.portuguese;
                this.activeRightContainer = this.rightContainerText.portuguese;
                this.activeStartButtonText = this.startButtonText.portuguese;
                break;
        }
    }
  },
  watch: {
    languageCode: function(newValue) {
        this.loadLanguage(newValue);
    }
  }
};
</script>
<style scoped>
    .separator {
        margin-top: 20px;
        border-right: none;
        border-left: none;
        border-bottom: none;
        border-top: solid 2px #f5f5f5;
        width: 90%;
    }
    
    .actionButton {
        margin: auto;
        text-transform: uppercase;
    }
</style>