<template>
    <div v-if="accordion">
        <div class="accordion">
            <div :class="header.cssClass != '' ? header.cssClass : 'header'" v-for="header in accordionHeaders" :key="header.id" v-html="header.displayText"></div>
            <div>
                <AccordionSection :section="activeSection" :replacements="replacements" :onUpdateAudio="updateAudio"></AccordionSection>
            </div>
        </div>
        <div class="controlBar">
            <Navigation :onGoToPreviousPage="goToPrevious" :onGoToNextPage="goToNext" :showAudioPlayer="true"
                        :showGoToNextPage="true" :showGoToPreviousPage="true" :audioTracks="audioTracks" :playTrackId="currentTrackId"></Navigation>
        </div>
    </div>
</template>

<script>
import { data } from '@/shared';
import { audio } from '@/shared/audio';
import jsonLogic from 'json-logic-js';
import AccordionSection from './AccordionSection.vue';
import Navigation from '../shared/Navigation.vue';
import { useLoaStore } from "@/stores/loa";

export default {
    name: 'Accordion',
    components: { AccordionSection, Navigation },
    props: {
        accordionCode: String,
        replacements: Object,
        onAccordionEnd: {
            type: Function,
            required: true
        },
        returnToSummary: Boolean
    },
    setup() {
      const piniaSurvey = useLoaStore();

      return { piniaSurvey };
    },
    data() {
        return {
            accordion: null,
            activeSectionIndex: -1,
            attributes: [],
            currentTrackId: '',
            playableTracks: [],
            storageBaseUrl: '',
            additionalAudioTracks: [],
            playFirstTrack: true
        }
    },
    computed: {
        activeSection: function() {
            return this.accordion ? this.accordion.sections[this.activeSectionIndex] : null;
        },
        local_attributes: function() {
            var attrib = this.attributes;
            attrib["ACCORDION_SECTION_SEQ"] = this.activeSection.sequence;
            return attrib;
        },
        accordionHeaders: function() {
            var headers = this.accordion.headers.filter((search) => {
                return search.conditionalJson === '' || 
                                    jsonLogic.apply(JSON.parse(search.conditionalJson), this.local_attributes)
            });

            return headers;
        },
        audioTracks() {
            if(this.playableTracks.length > 0 && this.accordion.actions.length > 0) {
                var tracks = [];
                var actions = this.accordion.actions.filter((action) => 
                    this.isAudioAction(action) && 
                    ((action.accordionSectionID > 0 && 
                        action.accordionSectionID == this.activeSection.id ) ||
                     (action.accordionSectionID == 0)) && 
                    jsonLogic.apply(JSON.parse(action.conditionalJson), this.attributes)
                );
                
                actions.sort((a, b) => {
                    return a.sequence - b.sequence;
                });
                
                for(var i = 0; i < actions.length; i++) {
                    tracks.push(this.playableTracks.find((search) => { return actions[i].configuration.mediaPropertyID.toString() === search.id} ) );
                }

                if(this.additionalAudioTracks.length > 0) {
                    tracks.push(...this.additionalAudioTracks);
                }
                return tracks;
            }
            else if(this.additionalAudioTracks.length > 0)
                return this.additionalAudioTracks;
            else
                return [];
        }
    },
    async mounted() {
        this.attributes = this.piniaSurvey.getAttributes;        
        await this.getAccordion();
    },
    methods: {
        updateAudio: function(newTrackInfo) {
            if(newTrackInfo.length > 0) {
                this.playFirstTrack = false;
                this.additionalAudioTracks.push(...newTrackInfo);
                this.currentTrackId = newTrackInfo[0].id;
            }
        },
        getAccordion: async function() {
            this.storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);
            //kinda a hack here...since I wanted to get all of the accordion headers so I could adjust on the fly.  All 
            //of the headers have sequence = 0 OR what ever sequence they are looking for.   This way everything is returned from
            //the API and we can make adjustments on the fly.  Probably should look at doing this differently in the future.
            this.attributes["ACCORDION_SECTION_SEQ"] = "0";
            var accordion = await data.getAccordion(this.accordionCode, this.attributes);
            this.attributes["ACCORDION_SECTION_SEQ"] = "1";

            accordion.sections.sort((a, b) => {
                return a.sequence - b.sequence;
            });

            const replacementKeys = this.replacements ? Object.keys(this.replacements) : null;
            if(replacementKeys && accordion.headers) {
                for (let i = 0; i < accordion.headers.length; i++) 
                replacementKeys.forEach((replacementKey) => {
                    accordion.headers[i].displayText = accordion.headers[i].displayText.replaceAll(replacementKey, this.replacements[replacementKey]);
                });
            }

            this.loadAudioTracks(accordion);

            this.activeSectionIndex = 0;
            this.accordion = accordion;

        },
        goToPrevious: function() {
            this.additionalAudioTracks = [];
            this.playFirstTrack = true;
            if(this.activeSectionIndex > 0)
                this.activeSectionIndex--;
            else
                this.onAccordionEnd(false, this.returnToSummary);
        },
        goToNext: function() {
            this.additionalAudioTracks = [];
            this.playFirstTrack = true;
            if(this.activeSectionIndex < (this.accordion.sections.length - 1))  
                this.activeSectionIndex++;
            else
                this.onAccordionEnd(true, this.returnToSummary);
        },
        async loadAudioTracks(accordion) {
            if(accordion.actions && accordion.actions.length) {
                this.playableTracks = await audio.loadAudioTracks(accordion.actions);
            }
        },
        isAudioAction(action) {
            return action.type === 'PlayAudio';
        },

    },
    watch: {
        audioTracks() {
            if(this.audioTracks.length && this.playFirstTrack) 
                this.currentTrackId = this.audioTracks[0].id;
        }
    }
}
</script>
