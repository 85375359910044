<template v-if="isReady">
    <div class="question-container">
        <div class="question-text">
            <span v-if="!hasTooltip" v-html="getTextForLanguage(question.questionTextJson)"></span>
            <template v-else-if="hasTooltip">
                <span v-html="toolTipConfig.preText"></span>
                <ToolTip :position="question.mediaPropertyInfo.configuration.position" :alignment="question.mediaPropertyInfo.configuration.alignment" 
                                :useSmartRepositioning="question.mediaPropertyInfo.configuration.useSmartRepositioning">
                    <template #content><span v-html="getTextForLanguage(question.mediaPropertyInfo.configuration.textJson)"></span></template>
                    <template #hoverText><span v-html="toolTipConfig.middleText"></span></template>
                    <template v-if="question.mediaPropertyInfo.configuration.hoverIcon" #hoverIcon>{{ question.mediaPropertyInfo.configuration.hoverIcon }}</template>
                </ToolTip>
                <span v-html="toolTipConfig.postText"></span>
            </template>
        </div>
        <div class="answer-container"
            :class="[questionRowClass, question.questionType.toLowerCase() + '-' + question.answerType.toLowerCase()]" 
            style="display:flex; flex-direction: row">
            <component :is="componentType" v-bind="componentAttributes" v-on="componentEvents">
                <template v-if="visibleAnswers.length > 0 && (question.questionType === 'SingleSelect' || question.questionType === 'MultiSelect') && question.answerType === 'Dropdown1'">
                    <option></option>
                    <template v-if="answerListHasGroups">
                        <optgroup v-for="group in getVisibleAnswers('GROUPING')" :key="group.id" :label="getTextForLanguage(group.answerTextJson)">
                            <option v-for="ans in getVisibleAnswers(null, group.id)" :key="ans.id" :value="ans.answerValue">{{getTextForLanguage(ans.answerTextJson)}}</option>
                        </optgroup>
                    </template>
                    <template v-if="!answerListHasGroups">
                        <option v-for="ans in visibleAnswers" :key="ans.id" :value="ans.answerValue">{{getTextForLanguage(ans.answerTextJson)}}</option>
                    </template>
                </template>
                <template v-if="visibleAnswers.length > 0 && (question.questionType === 'SingleSelect' || question.questionType === 'MultiSelect') && question.answerType === 'Button'">
                    <div :class="[ans.type.toLowerCase()+ '-container', widthClass]"                  
                        v-for="ans in visibleAnswers" 
                        :key="ans.id" >
                           <div v-if="ans.type === 'GROUPING'" class="grouping-item" v-html="getTextForLanguage(ans.answerTextJson)"></div>
                           <div v-if="ans.type === 'ANSWER'" class="answer-button" :class="[answerSelected(ans) ? 'selected' : '']" @click="selectAnswer(ans)">{{getTextForLanguage(ans.answerTextJson)}}</div>
                    </div>
                </template>
                <template v-if="visibleAnswers.length > 0 && (question.questionType === 'SingleSelect' || question.questionType === 'MultiSelect') && question.answerType === 'List'">
                    <template v-if="answerListHasGroups">
                        <div v-for="group in getVisibleAnswers('GROUPING')" :class="[group.type.toLowerCase() + '-container', widthClass]"
                                :key="group.id" :label="getTextForLanguage(group.answerTextJson)">
                            <div class="select-list-grouping" v-html="getTextForLanguage(group.answerTextJson)"></div>
                            <ul class="select-list-answer-ul">
                                <li v-for="ans in getVisibleAnswers(null, group.id)" :key="ans.id" :value="ans.answerValue"
                                        :class="[answerSelected(ans) ? 'selected' : '']" v-html="getTextForLanguage(ans.answerTextJson)" @click="selectAnswer(ans)"></li>
                            </ul>
                        </div>
                    </template>
                    <template v-if="!answerListHasGroups">
                        <div class="answer-container" :class="widthClass" >
                            <ul class="select-answer-ul">
                                <li v-for="ans in visibleAnswers" :key="ans.id" :value="ans.answerValue" v-html="getTextForLanguage(ans.answerTextJson)" @click="selectAnswer(ans)"></li>
                            </ul>
                        </div>
                    </template>
                </template>
            </component>
        </div>
        <div v-if="showButtonsWithQuestions" class="buttonColumn">
            <div @click="submitPage" class="action-button submit-button"
                v-html="continueButtonText" 
                v-if="checkToDisplayContinue()" >                
            </div>
        </div>
        <div class="more-info" v-if="question.moreInfo" v-html="getTextForLanguage(question.moreInfoJson)"></div>
    </div>
</template>

<script>
import AutoComplete from "@/components/shared/AutoComplete";
import ToolTip from '@/components/shared/MediaProperties/ToolTip2';
import VideoPlayer from '@/components/VideoPlayer.vue';
import TextboxButton  from "@/components/shared/Questions/TextboxButton";
import ValueSelectLabel from '@/components/shared/Questions/ValueSelectLabel';
import jsonLogic from 'json-logic-js';
import { useLoaStore } from "@/stores/loa";
import { translation } from '@/shared/translation';

export default {
    name: 'Question',
    components: { AutoComplete, ToolTip, VideoPlayer, TextboxButton, ValueSelectLabel },
    props: {
        question: {
            type: Object,
            required: true
        },
        // executes whenever an answer is set
        onSetAnswer: {
            type: Function,
            required: true
        },
        showButtonsWithQuestions: {
            type: Boolean,
            default: false
        },
        onVideoFinish: {
            type: Function
        },
        attributes: {
            type: Object
        },
        // { name: 'English', code: 'en' }
        language: Object,
        storageBaseUrl: {
            type: String
        },
        showInlineContinueButton: Boolean,
        continueButtonText: String,
        submitPage: Function
    },
    setup() {
      const piniaSurvey = useLoaStore();
      return {
        piniaSurvey
      };
    },
    data() {
        return {
            // Value that get's passed as the question's answer to parent component
            answerValue: null,
            answerText: null,
            // Array of answers passed as the question's answer to parent component
            // when question is of type MultiSelect
            multiselectAnswers: [],
            // Local copy of answer array that can be manipulated
            answers: [],
            isReady: false
        }
    },
    computed: {
        // Sets component type based on QuestionType and AnswerType
        componentType() {
            switch(this.question.questionType) {
                case 'Text':
                case 'Date':
                case 'Time':
                case 'FileUpload':
                    return 'input';
                case 'SingleSelect':
                case 'MultiSelect':
                    switch(this.question.answerType) {
                        case 'Dropdown':
                            return AutoComplete;
                        case 'Dropdown1':
                            return 'select';
                        case 'Button':
                            return 'div';
                        default:
                            return 'span';
                    }
                case 'MediaDisplay': 
                    switch(this.question.mediaPropertyInfo.mediaType) {
                        case 'Image':
                            return 'img';
                        case 'Video':
                            return 'VideoPlayer';
                        default:
                            return 'span';
                    }
                case 'Combination':
                    switch(this.question.answerType) {
                        case 'TextboxAndButton':
                            return TextboxButton;
                        case 'ValueSelectLabel':
                            return ValueSelectLabel;
                        default:
                            return 'span';
                    }
                default:
                    return 'span';
            }
        },
        // Adds attributes to component based on QuestionType and AnswerType
        componentAttributes() {
            const displayParams = this.question.displayParameters;

            switch(this.question.questionType) {
                case 'Text':
                    switch(this.question.answerType) {
                        case 'Numeric':
                            return {
                                type: 'number',
                                min: displayParams.minValue || displayParams.minValue === 0 ? displayParams.minValue : null,
                                max: displayParams.maxValue || displayParams.maxValue === 0 ? displayParams.maxValue : null,
                                step: displayParams.numberStep || 'any',
                                pattern: displayParams.pattern || '',
                                placeholder: this.getTextForLanguage(displayParams.placeholderJson),
                                oninput: this.validateInput,
                                'v-model': this.answerValue
                            };
                        case 'Text':
                            return {
                                type: 'text',
                                placeholder: this.getTextForLanguage(displayParams.placeholderJson),
                                'v-model': this.answerValue
                            };
                        default:
                            return {};
                    }
                case 'Date':
                    return {
                        type: 'date',
                        'v-model': this.answerValue
                    };
                case 'Time':
                    return {
                        type: 'time',
                        'v-model': this.answerValue
                    };
                case 'FileUpload':
                    return {
                        type: 'file'
                    };
                case 'SingleSelect':
                case 'MultiSelect':
                    switch(this.question.answerType) {
                        case 'Dropdown':
                            return {
                                attributeCode: this.question.attribute.code,
                                displayField: "answerTextJson",
                                internalValueField: "answerValue",
                                searchField: "answerTextJson",
                                data: this.getVisibleAnswers('ANSWER'),
                                value: this.answerValue,
                                onValueChanged: this.setAutoCompleteAnswer,
                                placeholder: this.getTextForLanguage(displayParams.placeholderJson),
                                language: this.language.name
                            };
                        case 'Dropdown1':
                            return {
                                'v-model': this.answerValue
                            };
                        case 'Button':
                            return {
                                class: 'answer-button-list-container ' + (this.widthClass !== 'Columns1' ? 'flex-row' : 'flex-column')
                            };
                        default:
                            return {};
                    }
                case 'MediaDisplay': 
                    switch(this.question.mediaPropertyInfo.mediaType) {
                        case 'Image':
                            return {
                                style: this.imageStyle,
                                src: this.question.mediaPropertyInfo.configuration.src
                            };
                        case 'Video':
                            return {
                                style: this.videoConfig.style,
                                mediaPropertyID: this.question.mediaPropertyId,
                                options: this.videoConfig,
                                ref: 'video' + this.question.id,
                                key: this.question.id,
                                onVideoFinish: this.onVideoFinish(this.question.id),
                                videoDescription: this.question.mediaPropertyInfo.description
                            };
                        default:
                            return {};
                    }
                case 'Combination':
                    switch(this.question.answerType) {
                        case 'TextboxAndButton':
                            return {
                                question: this.question,
                                onValueChanged: this.setAnswer,
                                key: this.question.id,
                                placeholder: this.getTextForLanguage(displayParams.placeholderJson),
                                language: this.language.name
                            };
                        case 'ValueSelectLabel':
                            return {
                                question: this.question,
                                onValueChanged: this.setAnswer,
                                key: this.question.id,
                                placeholder: displayParams.placeholder,
                                language: this.language.name
                            };
                        default:
                            return {};
                    }
                default:
                    return {};
            }
        },
        // Adds events to component based on QuestionType and AnswerType
        componentEvents: function() {
            switch(this.question.questionType) {
                case 'Text':
                case 'Date':
                case 'Time':
                    return { change: (e) => { this.setAnswer(this.question.attribute.code, e.target.value) }};
                case 'SingleSelect':
                case 'MultiSelect':
                    switch(this.question.answerType) {
                        case 'Dropdown1':
                            return { change: (e) => { this.setAnswer(this.question.attribute.code, e.target.value) }};
                        default:
                            return {};
                    }
                default:
                    return {};
            }
        },
        // Sets the number of columns to sort answers into
        widthClass() {
            if(this.question.displayParameters == null ||
                this.question.displayParameters.columns == null ||
                this.question.displayParameters.columns <= 1) return "Columns1";
            else
                return 'Columns' + this.question.displayParameters.columns;
        },
        //whether or not this answer list has any grouping records
        answerListHasGroups() {
           return this.visibleAnswers.some(search => search.type === 'GROUPING'); 
        },
        visibleAnswers() {
            if(this.answers == null) return [];
            
            return this.answers.filter(search => search.visible);            
        },
        imageStyle() {
            if(!this.question.mediaPropertyInfo) return {};

            const configuration = this.question.mediaPropertyInfo.configuration;
            var style = {};
            if(configuration.height !== '') {
                if(configuration.height.indexOf("%") === -1 && configuration.height.indexOf("px") === -1)
                    style["height"] = configuration.height + "px";
                else 
                    style["height"] = configuration.height;
            }

            if(configuration.width !== '') {
                if(configuration.width.indexOf("%") === -1 && configuration.width.indexOf("px") === -1)
                    style["width"] = configuration.width + "px";
                else 
                    style["width"] = configuration.width;
            }

            return style;
        },
        hasTooltip() {
            return (this.question.mediaPropertyId && 
                this.question.mediaPropertyId > 0 &&
                this.question.mediaPropertyInfo != null && 
                this.question.mediaPropertyInfo.mediaType == 'ToolTip');
        },
        toolTipConfig() {
            var config = {};
            if(this.question.mediaPropertyInfo.mediaType === 'ToolTip') {
                var text = this.getTextForLanguage(this.question.questionTextJson);
                const toolTipStart = text.indexOf('<ToolTip');
                const middleTextStart = text.search(/(<ToolTip>)/) + 9;
                const middleTextEnd = text.indexOf('</ToolTip>');
                const toolTipEnd = text.indexOf('</ToolTip>') + 10;
                config.preText = text.substr(0, toolTipStart);
                config.postText = text.slice(toolTipEnd, text.length);
                config.middleText = text.slice(middleTextStart, middleTextEnd);
            }

            return config;
        },     
        questionRowClass() {
            return this.showButtonsWithQuestions ? "twoColumns" : "oneColumn";
        },
        videoConfig() {
            if(!this.question.mediaPropertyId || 
                this.question.mediaPropertyId <= 0 || 
                this.question.mediaPropertyInfo.mediaType !== 'Video') return {};

            let config = this.question.mediaPropertyInfo.configuration;

            if(config.poster)
                config.poster = this.storageBaseUrl + config.poster;

            config.sources.forEach(sc => {
                if(sc.src.indexOf('http') < 0)
                    sc.src = this.storageBaseUrl + sc.src;
            });
            
            config.tracks.forEach(tk => {
                if(tk.src.indexOf('http') < 0)
                    tk.src = this.storageBaseUrl + tk.src;

                if(!tk.default)
                    tk.default = (tk.srclang === this.language.code && tk.srclang !== 'en');
            });

            config.style = {
                height: config.height + 'px',
                width: config.width + 'px'
            }

            return config;
        }
    },
    created() {
        if(this.question.answers)
            this.answers = this.question.answers;
        this.checkAnswers();
        this.isReady = this.question != null;
    },
    methods: {
        //based on the parameters a subset of the answers are returned
        getVisibleAnswers(groupsOrAnswers, answerGroupID) {
            if(this.visibleAnswers.length === 0) return this.visibleAnswers;
            
            if(groupsOrAnswers && groupsOrAnswers !== '')
                return this.visibleAnswers.filter(search => search.type === groupsOrAnswers);            
            else if(answerGroupID) {
                var beginIndex = this.visibleAnswers.findIndex(search => search.id === answerGroupID);
                if(beginIndex === -1 || beginIndex === this.visibleAnswers.length - 1)
                    return [];

                beginIndex++;
                var elementCount = this.visibleAnswers.slice(beginIndex).findIndex(search => search.type === 'GROUPING');
               
                if(elementCount >= 0)
                    return this.visibleAnswers.slice(beginIndex, beginIndex + elementCount);
                else 
                    return this.visibleAnswers.slice(beginIndex);
            }
            else              
                return this.visibleAnswers.filter(search => search.visible);            
        },

        // Combination controls pass in values, otherwise use local values
        setAnswer(attributeCode = null, answerValue = null, labelValue = null) {
            this.onSetAnswer(attributeCode ?? this.question.attribute.code, answerValue ?? this.answerValue, labelValue);
            this.checkAnswers();
        },
        validateInput(event) {
            if(!event.target.validity.valid) {
                if(event.target.value < this.question.displayParameters.minValue)
                    event.target.value = Math.abs(event.target.value)
                if(event.target.value > this.question.displayParameters.maxValue)
                    event.target.value = this.question.displayParameters.maxValue
            }
        },
        setAutoCompleteAnswer(code, answer, answerValue) {
            this.answerValue = answer;
            this.setAnswer(code, answer);
            this.setAnswer(code + "_VALUE", answerValue);
        },
        selectAnswer: function(ans) {
            if(this.question.questionType === 'SingleSelect') {
                this.answerValue = ans.answerValue;
                this.answerText = this.getTextForLanguage(ans.answerTextJson);
            }
            else if(this.question.questionType === 'MultiSelect') {

                if(!this.answerValue) {
                    this.answerValue = [];
                    this.answerText = [];
                }
                if(this.multiselectAnswers.indexOf(ans.answerValue) < 0) {
                    this.answerValue.push(ans.answerValue);
                    this.answerText.push(this.getTextForLanguage(ans.answerTextJson));
                } else {
                    this.answerValue.splice(this.answerValue.indexOf(ans.answerValue), 1);
                    this.answerText.splice(this.answerValue.indexOf(ans.answerValue), 1);
                }
            }
            this.setAnswer(this.question.attribute.code, this.answerValue, this.question.attribute.useAsReplacementString ? this.answerText : null);
        },
        answerSelected(answer) {
            if(this.attributes[this.question.attribute.code]) {
                if(Array.isArray(this.attributes[this.question.attribute.code]) &&  this.attributes[this.question.attribute.code].indexOf(answer.answerValue) >= 0 )
                    return true;
                else if(!Array.isArray(this.attributes[this.question.attribute.code]) && this.attributes[this.question.attribute.code] == answer.answerValue) 
                    return true;
            }

            return false;
        },
        // Evaluates which answers should be shown based on current survey answers
        checkAnswers() {
            for(var i = 0; i < this.answers.length; i++) {
                if(this.attributes == null)
                    this.answers[i].visible = true;
                else if(this.answers[i].conditionalJson) {
                    var answerResult = jsonLogic.apply(JSON.parse(this.answers[i].conditionalJson), this.attributes);
                    if(answerResult != this.answers[i].visible) {
                        this.answers[i].visible = answerResult;
                    }
                } 
                else 
                    this.answers[i].visible = true;
            }
        },
        getTextForLanguage: function(jsonField) {
            return translation.getTextForLanguage(jsonField, this.language.name);
        },
        checkToDisplayContinue() {
            var show = false;
            switch(this.question.questionType) {
                case 'Text':
                case 'Date':
                case 'Time':
                case 'Combination':
                    show = true;
                    break;
                default: 
                    if(this.question.answerType == 'Dropdown')
                      show = true;
                    break;
            }

            return show && this.showInlineContinueButton;
        }
    }
}
</script>

<style lang="scss" scoped src="@/assets/css/shared/tooltip/tooltip_standard.scss"></style>