<template>
<iframe v-if="storageBaseUrl" style="position:absolute; width: 100vw; height: 100vh; top: 0; left: 0" :src="storageBaseUrl" width="980" height="658"></iframe>
</template>

<script>
import { data } from '@/shared';

export default {
  name: "BankingApp",
  data() {
    return {
      storageBaseUrl: null
    };
  },
  async mounted() { 
    this.storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl) + '/Demo/BankingApp/story.html';
  }
};
</script>