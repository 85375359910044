<template>
    <div>
        <div v-for="param in params" :key="param.name">
            <input type="number" v-model="param.value" :placeholder="'Enter your ' + param.name" />
        </div>
        <button v-on:click="submitParams()">Submit</button>
        <Accordion
            accordionCode="CS_MEDIA"
            :displayTitle="false"
            client="JV"
            :hasDisplayLogic="true"
            :logicParameters="apiParameters"
            :key="componentKey"
        ></Accordion>
        <PageVisitLogger client="JV" activityType="JV_VISIT_VIDEO"></PageVisitLogger>
    </div>
</template>
<script>
import Accordion from "@/components/shared/Accordion";
import PageVisitLogger from "./shared/PageVisitLogger"
import { data } from "../shared";

export default {
    components: { Accordion, PageVisitLogger },
    name: 'VideoEmbed',
    data() {
        return {
            params: [],
            componentKey: 0,
            apiParameters: null
        }
    },
    beforeMount: function() {
        let accessCode = (new URL(document.location)).searchParams.get("access_code");
        if(accessCode !== 'abc123') {
            window.location.href = '/CustomSolutionsTimeline';
        }
    },
    mounted: async function() {
        let parameterList = await data.getAccordionParameters("CS_MEDIA");
        if(parameterList)
            parameterList.forEach((val) => this.params.push({name: val, value: null}));
    },
    methods: {
        submitParams: function() {
            this.apiParameters = this.params.reduce((prev, cur) => (
                prev[cur.name] = cur.value,
                prev
            ), {});
            this.componentKey += 1;
        }
    }
}
</script>
