import ErrorPage from '@/components/ErrorPage';
import ComingSoon from '@/components/ComingSoon';
import ComingSoonVideo from '@/components/ComingSoonVideo';
import Timeline from '@/components/Timeline';

export const routes = [
    {
      path: '/:pathMatch(.*)*',
      component: ErrorPage
    },
    {
      path: '/ComingSoon',
      component: ComingSoon,
    },
    {
      path: '/ComingSoonVideo',
      component: ComingSoonVideo,
    },
    {
        path: '/ErrorPage',
        component: ErrorPage
    },
    {
      path: '/Timeline',
      component: Timeline,
      meta: {
        title: 'Welcome to Services & Solutions'
      }
    }
  ];
  