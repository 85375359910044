<template>
    <div style="display: flex; flex-direction: column; background: #313131;">
        <div style="background: #003168 0% 0% no-repeat padding-box; padding-left: 115px;" >
            <img src="images/RBC/RBC-WMC-logo-reverse.png" style="width: 196; height: 96px; display:flex;" />
        </div>
        <div v-if="courseUrl" style="margin: 30px 10% 0px 10%;">
            <iframe id="myiFrame" contenteditable=“true” class="iframe" :src="courseUrl" title="Dap Information"></iframe>
        </div>
        <div v-if="courseUrl === null" style="display: flex; flex-direction: column; background: #FFFFFF; margin: 0px 10%; padding: 10vh 15% 10vh 4%;">
            <div style="display: flex; flex-direction: column;">
                <div class="titleText">Hi There</div>
                <div class="instructions" style="padding-top: 20px;">
                    The interactive tool featuring “Anna, your Deferred Compensation Plan Concierge,” is back with a new and refreshed look 
                    this year! As always, she’s here to walk you through your plan options and give you the 
                    information you need to make your important annual enrollment decisions.
                    <br /><br />
                    Your email address is required in order to streamline your visit here. It will not be 
                    shared or collected in any way, except to keep track of where you left off if you have 
                    to step away for a moment.

                </div>
                <div style="display: flex; margin-top: 40px;">
                    <input type="text" class="textbox" id="email" v-model="emailAddress" placeholder="Please enter your email address" />
                </div>
                <div style="display: flex; margin-top: 36px;">
                    <button class="submitButton" @click="submit()">Visit Anna</button>
                </div>
                <div class="disclaimer">
                    This should not be considered as providing tax advice. You should consult your tax 
                    advisor to discuss your personal financial situation. This does not constitute a 
                    prospectus nor an offer or solicitation to invest in the RBC U.S. Deferred 
                    Compensation Plans. Before investing, please read the applicable plan prospectus 
                    and/or plan document available on RBC LaunchPad.
                </div>
            </div>
        </div> 
        <div class="footer_container noprint" >
            <div class="footer">
                <div>
                    © The Jellyvision Lab, Inc., 2002-{{year}}
                </div>
                <div>
                    <a href="https://www.jellyvision.com/privacy-policy/" target="_blank">Privacy Policy</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { data } from '../../shared';

export default {
  name: "rbcCommon",
  data() {
    return {
        emailAddress: '',
        reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/, 
        courseUrl: null
    };
  },
  props: {
    courseId: String,
    activityTypeCode: String,
    data: {}
  },
  computed: {
    year() { 
      return new Date().getFullYear();
    }
  },
  mounted() {
    this.logActivity('LANDING_PAGE');
  },
  methods: { 
    logActivity: async function(activityTypeCode) {
        let logData = this.data;
        logData.emailAddress = this.emailAddress + '';

        let activityObj = {
            logActivityTypeCode: activityTypeCode,
            data: JSON.stringify(logData)
        };
      
        await data.postActivityLog(activityObj);
    },
    submit: async function() {
        var returnData= {};

        if(this.emailAddress == null || this.emailAddress == '')// || !validateEmail(this.emailAddress))
            this.$toast.error("You must enter an email");
        else if(!this.reg.test(this.emailAddress))
            this.$toast.error("Invalid email address");
        else
        {
            this.logActivity(this.activityTypeCode);
            returnData = await data.getLmsUrl(this.emailAddress, this.courseId);

            if(returnData.errors === null)
                window.location = returnData.url;
        }
    }
  }
}
</script>
<style scoped>
    .iframe {
        width: 75vw;
        height: 75vh;   
    }

    .titleText {
        text-align: left;
        font: normal normal normal 70px/88px Caveat;
        letter-spacing: 0px;
        color: #003168;
        opacity: 1;
    }

    .instructions {
        text-align: left;
        font: normal normal normal 18px/25px Arial;
        letter-spacing: 0px;
        color: #2F2F52;
        opacity: 1;
    }

    .textbox {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 7px;
        opacity: 1;    
        text-align: left;
        font: normal normal normal 16px/26px Arial;
        letter-spacing: 0px;
        color: #2F2F52;
        padding: 16px 10px; 
        min-width: 250px;
        width: 50%;
    }

    .textbox:focus {
        background: #EBEBEB 0% 0% no-repeat padding-box !important;
        border: 2px solid #2F3050;
    }

    .textbox::placeholder {
        color: #2F2F52;
        text-align: left;
        font: normal normal normal 16px/26px Arial;
        letter-spacing: 0px;
        opacity: 1;
    }


    button {
        text-align: left;
        font: normal normal normal 18px/21px Arial;
        letter-spacing: 0px;
        color: #2F2F52;
        opacity: 1;
        background: #FFD831 0% 0% no-repeat padding-box;
        border-radius: 11px;
        opacity: 1;
        border: none;
        padding: 15px 60px;
        cursor: pointer; 
    }

    .footer_container {
        background: #6F6E6F 0% 0% no-repeat padding-box;
        opacity: 1;
        color: #FFFFFF;
        text-align: left;
        font: normal normal normal 14px/18px Arial;
        letter-spacing: 0px;
        opacity: 1;
        padding: 16px 0px;
    }

    .footer {
        display: flex; 
        flex-direction: row;
        justify-content: space-between;
        margin: 0px 10%;
    }

    .footer a {
        color: #FFFFFF;
        text-decoration: none;
    }

    .disclaimer {
        opacity: 1;
        color: #707070;
        text-align: left;
        font: normal normal normal 13px/18px Arial;
        letter-spacing: 0px;
        margin-top: 30px;

    }
</style>