<template>
    <div class="frontPage">
        <div class="mainContainer">
            <div class="textContainer">
                <div class="title" v-html="title"></div>
                <div class="subtitle" v-html="subtitle"></div>
                <div class="right_container mobile_only" >
                    <div class="arrow_down"></div>
                    <video-player v-if="activeVideoConfig" :options="activeVideoConfig" 
                        :mediaPropertyID="162" videoDescription="LOA Welcome Video"></video-player>
                </div>
                <div class="paragraph" v-html="mainText"></div>
                <button class="loa_button" @click="onClickStart" >Let's get started</button>
                <div v-if="hasManagerPath">
                    <hr class="separator" />
                    <div class="paragraph" v-html="managerText"></div>
                    <button class="loa_button" @click="onManagerClick">Manager Access</button>
                </div>
            </div>
            <div class="right_container desktop_only" >
                <div class="right_arrow"></div>
                <video-player v-if="activeVideoConfig" :options="activeVideoConfig" 
                    :mediaPropertyID="162" videoDescription="LOA Welcome Video"></video-player>
            </div>
        </div>
        <div class="finePrint">
            <div class="finePrintColumn" v-if="finePrintColumn1" v-html="finePrintColumn1"></div>
            <div class="finePrintColumn" v-if="finePrintColumn2" v-html="finePrintColumn2"></div>
            <div class="finePrintColumn" v-if="finePrintColumn3" v-html="finePrintColumn3"></div>
        </div>
    </div>
</template>
<script>
import VideoPlayer from '@/components/VideoPlayer.vue';
import { data } from '@/shared';
import { useLoaStore } from "@/stores/loa";

export default {
  name: "LoaFrontPage",
  components: { VideoPlayer },
  props: {
    clientCode: String,
    onClickStart : {
      type: Function
    },
    onManagerClick: {
        type: Function
    },
    finePrintColumn1: {
        type: String,
        default: null
    },
    finePrintColumn2: {
        type: String,
        default: null
    },
    finePrintColumn3: {
        type: String,
        default: null
    },
    title: {
        type: String,
        default: "Life can be unpredictable"
    },
    subtitle: {
        type: String,
        default: "If you need to take some time off work, you’re in the right place."
    },
    mainText: {
        type: String,
        default: "To find out which leave type might be best for you, click on the button below to get started. Check out the video, then answer a few questions about your situation. If you’re eligible, you’ll see personalized estimates of what to expect regarding time off and pay during your leave, along with details on how to apply (if you haven’t already)."
    },
    managerText: {
        type: String,
        default: "Managers, please click below to access resources available to support your team through Leaves of Absence."
    }
  },
  data() {
      return {
          hasManagerPath: false,
          videoConfig: {
              height: 460,
              width: 640,
              controls: true,
              autoPlay: false,
              client: this.clientCode,
              poster: "/LOA/LOA-Video-Thumbnail.png",
              sources: [
                  {
                      src: "/LOA/LOA Animated Video.mp4",
                      type: "video/mp4"
                  }
              ],
              tracks: [
                  {
                      order: 1, 
                      label: "English (US)", 
                      kind: "captions",
                      srclang: "en",
                      src: "/LOA/LOA Animated Video.vtt"
                  }
              ],
              loggingActivities: [
                  {
                      percentage: 0,
                      logActivityTypeCode: "VIDEO_STARTED"
                  },
                  {
                      percentage: 25,
                      logActivityTypeCode: "VIDEO_25%"
                  },
                  {
                      percentage: 50,
                      logActivityTypeCode: "VIDEO_50%"
                  },
                  {
                      percentage: 75,
                      logActivityTypeCode: "VIDEO_75%"
                  },
                  {
                      percentage: 100,
                      logActivityTypeCode: "VIDEO_FINISHED"
                  }
              ]
          },
          activeVideoConfig: null
      }
  },
  setup() {
    const piniaSurvey = useLoaStore();
    return {
        piniaSurvey
    };
  },
  async mounted() {
      const storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);
      if(this.videoConfig.poster)
          this.videoConfig.poster = storageBaseUrl + this.videoConfig.poster;

      this.videoConfig.sources.forEach(sc => {
          sc.src = storageBaseUrl + sc.src;
      });
      this.videoConfig.tracks.forEach(tk => {
          tk.src = storageBaseUrl + tk.src;
      });
      this.activeVideoConfig = this.videoConfig;
      this.$parent.piniaSurvey.setAttributes(null);
      const pageProperties = await data.getPageProperties();
      if(pageProperties.loaProperties && pageProperties.loaProperties.hasManagerPath) {
        this.hasManagerPath = true;
      }
  }
};
</script>
<style scoped>
    .separator {
        margin-top: 20px;
        border-right: none;
        border-left: none;
        border-bottom: none;
        border-top: solid 2px #f5f5f5;
        width: 90%;
    }
</style>