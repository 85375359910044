<template>
    <LeaveOfAbsence clientCode="DEMO"></LeaveOfAbsence>
</template>
<script>
import LeaveOfAbsence from "@/components/shared/LOA/LeaveOfAbsence";
require('@/assets/css/client/demo/demo_variables.scss');

export default {
  components: { LeaveOfAbsence },
  name: "DemoLeaveOfAbsence",
};
</script>
<style lang="scss" scoped src="@/assets/css/client/demo/demoloa.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/demo/checklist_styling.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/demo/sample_language.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/demo/bar_chart.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/demo/feedback_modal.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/demo/send_email_modal.scss"></style>