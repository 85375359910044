  module.exports = { 
    outputDir: 'build',

    // Disable source maps in production
    productionSourceMap: false,
  
    // Customize webpack configuration
    configureWebpack: {
      optimization: {
        minimize: true, // Ensure minimization is enabled
        splitChunks: {
          chunks: 'all', // Optimize chunk splitting
        },
      },
    },
  };
  
