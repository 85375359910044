<template>
  <div id="headerWrapper">
    <div class="header">
      <div>Jellyvision Custom Solutions</div>
      <img src="/images/jellyvision-logo.png" width="145" height="90" />
    </div>
  </div>
</template>

<script>
require("../../assets/css/shared/header/header_variables_standard.scss");

export default {
  name: "Header",
  setup() {},
};
</script>
<style lang="scss" scoped src="@/assets/css/shared/header/header.scss"></style>
