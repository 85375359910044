<template>
  <div>
    <div class="main_container">
        <div class="container">
            <div class="left_column">
                <div class="title">
                    Coming Soon!
                </div>
                <div class="subtitle">
                    We need a bit more time in the oven.<br /><br/>Swing by real soon to check out what we’ve been cooking up.
                </div>
                <div class="subtitle2">
                    You’re in the right place, and the content of this page is still being created. It will be up and running 
                    in the near future, so c’mon back to see it soon.
                </div>
            </div>
            <div class="right_column">
                <img src="/images/ComingSoon/comingsoon-cooking.gif" class="jelly_image" />
            </div>     
        </div>
        <div><Footer alignment="left" v-bind:show_background="false"></Footer></div>
    </div>
    <PageVisitLogger activityType="COMING_SOON"></PageVisitLogger>
  </div>
</template>
<script>
import Footer from "./shared/Footer";
import PageVisitLogger from "./shared/PageVisitLogger";

export default {
  components: { Footer, PageVisitLogger },
  name: "ComingSoon",
  data() {
    return {
    };
  },
  mounted() {

  }
};
</script>
<style lang="scss" scoped src="@/assets/css/scoped/coming_soon.scss"></style>
