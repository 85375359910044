<template>
<div>
  <div style="width:100%; padding: 37.5px 140px 12.5px; display:flex;">
    <img src="/images/jellyvisionlogo-1@1x.png" width="121" height="75" style="display:flex" />
  </div>
  <div style="width: 100%; background: #2F2F52 0% 0% no-repeat padding-box;" v-on:click="closePopup" >
    <div style="margin: 0px 90px;">
      <div class="container-lg" style="padding-top: 30px; padding-bottom:21px; background: #0981B0 0% 0% no-repeat padding-box; text-align: left; font: normal normal normal 38px/48px Museo Sans; letter-spacing: 0px; color: #F7F7F7; opacity: 1; font-weight: bold;">
        Video Generator
      </div>
      <div class="container-lg" style="text-align:left; width: 100%;">
        <Listing v-if="page === 'Listing'" :onLogActivity="logActivity" :onEdit="onEdit" ></Listing>
        <ConfigMaintenance v-if="page === 'Edit'" :onLogActivity="logActivity" :ID="ID" :onCancel="onCancel"></ConfigMaintenance>
      </div>
      <div class="container-lg footer_container noprint" style="padding-top: 30px;" >
          <div class="footer">
              <div>© 2002-{{year}}</div><div class="desktop_only"> | </div><div>The Jellyvision Lab, Inc.</div><div class="desktop_only"> | </div><div>All rights reserved.</div><div class="desktop_only"> | </div><div><a href="https://www.jellyvision.com/privacy-policy/" target="_blank">Privacy Policy</a></div>
          </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Listing  from './Listing';
import ConfigMaintenance from './ConfigMaintenance';
import { data } from '@/shared';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: "Configurator",
  components: { Listing, ConfigMaintenance },
  data() {
      return {
          user: null,
          ID: null,
          page: 'Listing'
      }
  },
  computed: {
    year() { 
        return new Date().getFullYear();
    }
  },
  mounted() {
    try {
      const auth0 = useAuth0();
      this.user = auth0.user;
    }
    catch{
      this.user = null;
    }

    this.logActivity('LANDING_PAGE', null);
  },
  methods: { 
    onEdit: async function (configID) {
      this.ID = configID;
      this.page = "Edit";
    },
    logActivity: async function(activityTypeCode, logData) {            
      let activityObj = {
        logActivityTypeCode: activityTypeCode,
        data: logData
      };
      
      await data.postActivityLog(activityObj);
    },
    onCancel() {
      this.page = 'Listing';
    }
  }
};
</script>
<style lang="scss" scoped src="@/assets/css/client/JV/style.scss"></style>
