import TestVideo from '@/components/TestVideo';
import VideoEmbed from '@/components/VideoEmbed';
import LOAFeatureTest from '@/components/LOAFeatureTest';
import TestSurvey from '@/components/TestSurvey';
import testPinia from '@/components/testPinia';
import TestTimeline from '@/components/TestTimeline';
import TestAccordion from '@/components/TestAccordion';
import SinglePageAccordion from '@/components/shared/SinglePageAccordion';
import TestSinglePageSurvey from '@/components/TestSinglePageSurvey';

export const routes = [
  {
    path: '/',
    redirect: "Timeline"
  },
  {
    path: '/VideoEmbed',
    component: VideoEmbed
  },
  {
    path: '/LOAFeatureTest',
    component: LOAFeatureTest
  },
  {
    path: '/TestSurvey',
    component: TestSurvey
  },
  {
      path: '/video',
      component: TestVideo
  },
  {
      path: '/testPinia',
      component: testPinia
  },
  {
      path: '/testTimeline',
      component: TestTimeline
  },
  {
      path: '/TestAccordion',
      component: TestAccordion
  },
  {
      path: '/TestAccordionSectionView',
      component: SinglePageAccordion
  },
  {
      path: '/TestSinglePageSurvey',
      component: TestSinglePageSurvey
  }
];

