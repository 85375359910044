<template v-if="audioSrc">
  <div v-if="isCCd && hasActiveCC" class="closed-captions-container">
    <div v-html="activeCCText" class="closed-captions" :style="activeCCStyle"></div>
  </div>
  <audio ref="audioPlayer" style="display:none" preload="auto" :autoplay="allowAutoPlay" @play="onPlay" @pause="onPause" @ended="onEnded" @timeupdate="onTimeUpdate" >
    <source v-for="audioTrack in audioTracks" :src="audioTrack.url" :type="audioTrack.type != null ? audioTrack.type : 'audio/mpeg'" :key="audioTrack.id">
    Your browser does not support the audio tag
  </audio>
  <div class="buttonContainer" v-if="audioLoaded">
      <div @click="restartAudio" class="toggleButton toggleCutton-audio" v-if="showRestart">
        <i class="fa-solid fa-arrow-rotate-left"></i>
      </div>
      <div @click="toggleAudio" class="toggleButton" :class="toggleButtonPlayPause">
        <i class="fa-solid" :class="playPauseIcon"></i>
      </div>
      <div @click="toggleSound" class="toggleButton" :class="toggleButtonAudio">
        <i class="fa-solid" :class="muteUnmuteIcon"></i>
      </div>
      <div @click="toggleCCs" class="toggleButton" :class="toggleButtonCC">
        <i class="fa-regular" :class="ccsIcon"></i>
      </div>
  </div>
</template>
<script> 
import { useCache } from "@/stores/cache";
import * as axios from 'axios';
const webvtt = require('node-webvtt');
/*usage   
<AudioPlayer :audioTracks="tracks" :playTrackId="playTrack"></AudioPlayer>
tracks: should be passed as an array of objects having as id, url(absolute or relative), waitTimeAfterVideo, and  type properties  
         (unqiueidentitifer can be anything id, guid, etc but must be a string)
         the type is not require as it will default to 'audio/mpeg' 
playTrackId: string value corresponding to an ID value
*/

export default {
  name: "AudioPlayer",
  data() {
    return {
      isPlaying: false,
      isPaused: false,
      isMuted: false, 
      // tracks whether CCs are on or off
      isCCd: true,
      // tracks whether a CC should currently be shown
      hasActiveCC: false,
      showRestart: false,
      audioSrc: '',
      currentTrackIndex: -1,
      pauseClicked: false,
      activeCCFile: null,
      activeCCText: '',
      activeCCStyle: '',
      audioLoaded: false,
      isDebug: false
    }
  },
  setup() {
      const piniaCache = useCache();

      return { piniaCache };
  },
  props: {
    audioTracks: {
        type: Array,
        required: true
    }, 
    playTrackId: {
      type: String,
      default: ''
    },
    preload: {
      type: String,
      default: "auto",
      validator(value) {
        // The value must match one of these strings
        return ['meta', 'auto', 'none'].includes(value)
      }
    },
    autoplay: {
      type: Boolean,
      default: false
    }
  },  
  mounted() {
    const cache = this.piniaCache.getCache;
    if(cache.isMuted)
      this.isMuted = true;
    this.isCCd = cache.isCCd;

    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    this.isDebug = (params.get("debug"))

  },
  watch: {
    playTrackId: async function(val) { 
      if(this.isDebug) {
        console.log("playTrackId-Watcher: ");
        console.log(val);
      }
      var audio = this.$refs.audioPlayer;
      if(val !== '' && this.audioTracks.length > 0) {
        if(val != "all") {
          const foundIndex = this.audioTracks.findIndex((search) =>  search.id === val);

          if(foundIndex == this.currentTrackIndex  && this.audioTracks[this.currentTrackIndex].url == audio.src)
            return;
          else if(foundIndex >= 0) {
            this.currentTrackIndex = foundIndex;
          }
          
          this.$refs.audioPlayer.src =  this.audioTracks[this.currentTrackIndex].url;
        }

        this.audioLoaded = true;
      }
    },
  },
  computed: {
    isAutoPlay() {
        return this.autoplay && !this.isPaused;
    },
    allowAutoPlay() {
      return this.autoplay && !this.isMuted;
    },

    toggleButtonPlayPause() {
      return this.isPlaying ? "toggleButton-pause" : "toggleButton-play";
    },

    toggleButtonAudio() {
      return this.isMuted ? "toggleButton-unmute" : "toggleButton-mute";
    },

    toggleButtonCC() {
      return this.isCCd ? "toggleButton-nocc" : "toggleButton-cc";
    },
    playPauseIcon() { 
      return this.isPlaying ? "fa-pause" : "fa-play toggleButtonPlay";
    },
    muteUnmuteIcon()  {
      return this.isMuted ?  "fa-volume-xmark" : "fa-volume-high";
    },
    ccsIcon() {
      return this.isCCd ? "fa-closed-captioning" : "fa-closed-captioning-slash";
    },
    activeCCLocation() {
      return this.audioTracks[this.currentTrackIndex].ccUrl;
    }
  },
  methods: {
    toggleAudio : function() {
      if(this.isDebug)
        console.log("toggleAudio");

        var audio = this.$refs.audioPlayer;
        if(audio.paused) {
          if(this.currentTrackIndex == -1) {
            this.playNextTrack();
          }
          audio.play();
          if(this.isMuted) {
            this.toggleSound();
          }
        }
        else {
          this.pauseClicked = true;
          audio.pause();
        }
    },
    toggleSound: function() {
      var audio = this.$refs.audioPlayer;
      this.isMuted = !this.isMuted;
      audio.muted = this.isMuted;
      var audioSettings = this.piniaCache.getCache;
      audioSettings["isMuted"] = this.isMuted;
      this.piniaCache.setCache(audioSettings);
      if(this.isMuted && !this.isCCd)
        this.toggleCCs();
    },
    toggleCCs: function() {
      if(!this.isCCd && this.activeCCFile)
        this.isCCd = true;
      else if(this.isCCd)
        this.isCCd = false;

      var audioSettings = this.piniaCache.getCache;
      audioSettings["isCCd"] = this.isCCd;
      this.piniaCache.setCache(audioSettings);
    },
    restartAudio: function() {
      var audio = this.$refs.audioPlayer;
      if(audio.duration > 0)
        audio.currentTime = 0;   
    },
    onPlay: async function() {
      if(this.isDebug)
        console.log("onPlay");

      if(this.audioTracks.length == 0) return;
      if(this.currentTrackIndex < 0) 
          this.currentTrackIndex = 0;

        await this.loadCCs();
        if(!this.audioLoaded)
          this.audioLoaded = true;

        this.isPlaying = true;
        this.isPaused = false;
    },
    onPause: function() {
        if(this.audioTracks.length == 0) return;

        if(this.pauseClicked === true) {
          this.isPaused = true;
          this.isPlaying = false;
          this.pauseClicked = false;
        }
    },
    onEnded: function() {
        //check to see if there is more to play
        if(this.currentTrackIndex >= 0 && this.currentTrackIndex < this.audioTracks.length - 1) {
          setTimeout(this.playNextTrack, this.audioTracks[this.currentTrackIndex].waitTimeAfterVideo);
        }
        else {
          this.isPlaying = false;
          this.isPaused = false;

          //reset values so if the user presses play again, it's at the beginning
          this.currentTrackIndex = -1;          
          this.hasActiveCC = false;

        }
    },
    playNextTrack: async function() {
      if(this.isDebug)
        console.log("playNextTrack: " + this.currentTrackIndex);

      var audio = this.$refs.audioPlayer;
      ++this.currentTrackIndex;
      if(this.currentTrackIndex < this.audioTracks.length && audio.src !==  this.audioTracks[this.currentTrackIndex].url) {
        audio.src =  this.audioTracks[this.currentTrackIndex].url;
        if(!(!audio.paused && audio.currentTime)) {
          if(this.isDebug)
            console.log("playNextTrack: " + this.currentTrackIndex);
          audio.play();
        }
      }
     },
    loadCCs: async function() {
      if(this.isDebug)
        console.log("loadCCs: " +  this.currentTrackIndex);

      if(this.audioTracks[this.currentTrackIndex] && this.audioTracks[this.currentTrackIndex].ccUrl) {
        this.activeCCFile = null;
        await axios.get(this.audioTracks[this.currentTrackIndex].ccUrl)
                    .then(response => {this.activeCCFile = webvtt.parse(response.data); })
      } else {
        this.activeCCFile = null;
        this.isCCd = false;
        this.hasActiveCC = false;
      }
    },
    onTimeUpdate: function() {
      if(this.activeCCFile && this.$refs.audioPlayer && this.$refs.audioPlayer.currentTime) {
        const seconds = this.$refs.audioPlayer.currentTime;
        const activeCC = this.activeCCFile.cues.find(cu => cu.start <= seconds && cu.end >= seconds);
        if(activeCC) {
          this.activeCCText = activeCC.text;
          this.activeCCStyle = activeCC.styles;
          this.hasActiveCC = true;
        } else {
          this.hasActiveCC = false;
        }
      }
    }
  }
}

</script>
<style scoped>
  .toggleButton {
    box-sizing: border-box;
    cursor: pointer;
    color: var(--audio-button-color, #74748C);
    font-size: 1rem;
    font-weight: 900;
    border-color: var(--audio-button-color, #74748C);
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0.5s;
  }

  .toggleButton:hover {
      color: var(--off-white-color);
      background-color: var(--audio-button-color, #74748C);
  }

  .toggleButtonPlay {
    margin-left: .1875em;
  }

  .buttonContainer {
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
</style>
