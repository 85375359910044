<template>
    <rbcCommon :courseId="courseId" :activityTypeCode="activityTypeCode" :data="data" />
</template>
<script>
import rbcCommon from "./rbcCommon";

export default {
  name: "Wap",
  components: { rbcCommon },
  data() {
    return {
        courseId: "RBC-WAP-Convo-FinalCut-aa50fa28-dc4c-4356-bf86-63d815d25959",
        activityTypeCode: "TAKE_COURSE",
        data: { "course": "WAP" }
    }
  },
}
</script>
