<template>
    <div class="introPage">
        <div class="topBar">
            <div class="sectionTitle">
                eligibility
            </div>
            <div class="backButton" @click="backFn()">
                <i class="fas fa-chevron-left"></i> Go Back
            </div>
        </div>
        <div class="contentContainer">
            <div class="textContainer">
                <div class="title" v-html="title"></div>
                <div class="paragraphs" v-html="paragraphs"></div>
                <button class="loa_button" @click="continueFn()" >Go to eligibility questions</button>
            </div>
            <div class="imageContainer desktop_only">
                <img src="images/LOA/loa-work-box-static.png" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "LoaSurveyIntro",
  props: {
      continueFn : {
          type: Function
      },
      backFn : {
          type: Function
      },
      title: {
          type: String,
          default: 'So, you’d like to know more about taking time away from work.'
      },
      paragraphs: {
          type: String,
          default: 'Let’s hear about your situation, and then you’ll receive estimates of what your options may be. Your answers to the following questions are not stored, and no personal information will be shared with your manager, so please answer as honestly as possible to get the most accurate information.<br /><br />Sound good? Okay, let’s begin when you’re ready.'
      }
  }  
};
</script>