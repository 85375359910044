<template>
    <div class="checklistContainer keepTogether" :style="{width: width + 'px', backgroundImage: 'url(' + images.top + ')'}">
        <div class="checklistHeader" v-if="header"><span>{{header}}</span></div>
        <div class="checklistMiddleRow">
            <div class="checklistRowContainer">
                <div v-for="item in items" :key="item.id" class="checklistRow" v-on:click="checkboxClick(item)" :style="{cursor: cursor}">
                    <div class="checklistCheckbox">
                        <img src="/images/Checklist/LOA-Checklist-Box.png" v-if="!item.checked" />
                        <img src="/images/Checklist/LOA-Checklist-Box_with_Check.png" v-if="item.checked" />
                    </div>    
                    <div class="checklistRowText">{{item.text}}</div>
                </div>
            </div>
            <div class="checklistFooter"><span>{{footer}}</span></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Checklist",
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        },
        client: {
            type: String,
            default() {
                return '';
            }
        }
    },
    data() {
        return {
            cursor: 'default',
            items: {},
            images: {
                    top: "/images/Checklist/LOA-Checklist-Paper-Top.png"
            },
            selectable: false,
            header: '',
            footer: '', 
            width: 350
        }
    },
    async mounted() {
        if(this.options.images)
            this.images = this.options.images;
        
        if(this.options.header)
            this.header = this.options.header;

        if(this.options.footer)
            this.footer = this.options.footer;

        if(this.options.items)
            this.items = this.options.items;

        if(this.options.width)
            this.width = this.options.width;

        if(this.selectable)
            this.selectable = this.options.selectable;
    },
    beforeMount: function() {    
        if(this.selectable) {
            this.cursor = 'pointer';
        }
    },
    methods: {
        checkboxClick: function(item) {
            if(!this.selectable)
                return;
            if(item.checked)
                item.checked = false;
            else
                item.checked = true;
        }
    }
}
</script>
<style lang="scss" scoped src="@/assets/css/shared/checklist/checklist_basic.scss"></style>