<template >
    <div class="sbm-container noprint" v-if="modalActive" >
        <div  class="sbm-backdrop"></div>
        <transition name="sbm-transition">
            <div class="sbm-modal-container" v-if="modalActive">
                <div class="sbm-content" v-html="displayText"></div>
                <div class="sbm-button" v-html="buttonText" @click="clickButton"> 
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "SingleButtonModal",
    props: {
        displayText: {
            type: String,
            required: true
        },
        buttonText: {
            type: String,
            required: true
        },
        onButtonClicked: {
            type: Function,
            required: true
        }
    },
    computed: {
        modalActive() {
            return this.displayText != null && this.displayText !== '';
        }
    },
    methods: {
        openModal: function() {
            this.modalActive = true;
        },
        clickButton: function() {
            this.onButtonClicked();
        }
    },
}
</script>
<style scoped>
.sbm-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;

    .sbm-backdrop {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        left: 0px;
        backdrop-filter: blur(4px);
    }

    .sbm-transition-leave-active, .sbm-transition-enter-active {
        transition: 0.1s;
    }
    .sbm-transition-enter {
        transform: translate(100%, 0);
    }
    
    .sbm-transition-leave-to {
        transform: translate(100%, 0);
    }

    .sbm-modal-container {
        position: absolute;
        width: 50%;
        background: var(--feedback-modal-background-color) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 20px #00000029;
        opacity: 1;
        backdrop-filter: blur(5px);
        transition: all ease 0.1s;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

     .sbm-button {
        cursor: pointer;
        display: flex;
        transition-duration: 0.5s;
    }
}
</style>
