<template>
    <div class="frontPage">
        <div class="leftColumn">
            <h1>Hej!</h1>
            <p><b><em>Välkommen</em> to your personal tour of compensation at IKEA US!</b></p>
            <p>{{ firstParagraphText }}</p>
            <p><i class="fa-solid fa-volume-high"></i> {{ secondParagraphText }}</p>
            <button class="inkButton" @click="onClickStart">I'm ready!</button>
            <p>Questions on your benefits? <a href="https://start.myalex.com/ikea" target="_blank">Go ask ALEX®.</a></p>
        </div>
        <div class="rightColumn">
            <img v-if="imageSrc" :src="imageSrc" style="width: 100%; margin-top: -5em" />
        </div>
    </div>
</template>

<script>
import { data } from '@/shared/data';

export default {
    name: 'FrontPage',
    props: {
        onClickStart: Function
    },
    data() {
        return {
            firstParagraphText: "Whether you’re new to the team or you’ve been building Billy bookshelves for years, you'll find everything needed to unpack the details. This site is here to provide clarity on your pay, incentives, career paths, and the exceptionally equitable pay policies at IKEA, all with just a little info from your side of the screen.",
            secondParagraphText: "Heads up, this experience works best with your volume on. If audio is tricky right now, you’ll still be able to follow along with screen visuals. Sound good?",
            imageSrc: null 
        }
    },
    mounted: async function() {
        var storageBaseUrl = await data.getEnvironmentVariable(data.enumEnvironmentVariableFields.fileStorageUrl);
        this.imageSrc = storageBaseUrl + '/Ikea/Images/Landingpg-graphic-noloop.gif';
    }
}
</script>