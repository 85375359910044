import POC  from '@/components/Huntington/POC';

export const routes = [
  {
    path: '/',
    redirect: '/poc'
  },
  {
    path: '/poc',
    component: POC,
    meta: {
      title: 'Huntington'
    }
  }

];
